export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Untyped attribute data. Eg configuration, settings, attributes. */
  Attributes: { input: any; output: any };
  /** A cursor for use in pagination. */
  Cursor: { input: any; output: any };
  /** Date in ISO 8601 format (yyyy-mm-dd) */
  Date: { input: any; output: any };
  /** An email address. */
  Email: { input: any; output: any };
  /**
   * An HTML string
   *
   * Content of this type is deemed safe by the server for raw output given the
   * origin and the context of its usage. The HTML can still contain scripts or style
   * tags where the creating user had permission to input these.
   */
  Html: { input: any; output: any };
  /** A field whose value conforms to the standard E.164 */
  PhoneNumber: { input: any; output: any };
  /** RFC 3339 compliant time string. */
  Time: { input: any; output: any };
  /** A field whose value exists in the standard IANA Time Zone Database. */
  TimeZone: { input: any; output: any };
  /** Type represents date and time as number of milliseconds from start of the UNIX epoch. */
  Timestamp: { input: any; output: any };
  /** A string that will have a value of format ±hh:mm */
  UtcOffset: { input: any; output: any };
};

export type Address = {
  __typename?: "Address";
  box?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  locality?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  postal_code?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
};

export type Application = {
  __typename?: "Application";
  job_application_key?: Maybe<Scalars["String"]["output"]>;
};

/** Get Available Languages */
export type AvailableLanguages = {
  __typename?: "AvailableLanguages";
  language?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type BlogCategoryOverviewFilterInput = {
  /** Doelgroep  */
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
  /** Vertalingstaal  */
  langcode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Result for view blog_category_overview display graphql_1. */
export type BlogCategoryOverviewResult = View & {
  __typename?: "BlogCategoryOverviewResult";
  /** The description of the view. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The results of the view. */
  results: Array<TermUnion>;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

export type BlogOverviewGraphqlFilterInput = {
  /** Category (field_blog_category_ref)  */
  field_blog_category_ref_target_id?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Doelgroep (field_target_audience_ref)  */
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
  /** Translation language  */
  langcode: Scalars["String"]["input"];
};

/** Result for view blog_overview display graphql_1. */
export type BlogOverviewGraphqlResult = View & {
  __typename?: "BlogOverviewGraphqlResult";
  /** De beschrijving van het overzicht. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The results of the view. */
  results: Array<NodeUnion>;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

export type Candidate = {
  __typename?: "Candidate";
  address?: Maybe<Address>;
  birth_date?: Maybe<Scalars["Date"]["output"]>;
  certificates?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  characteristics?: Maybe<Scalars["String"]["output"]>;
  contract_types?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  course?: Maybe<Scalars["String"]["output"]>;
  drivers_licence?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  education_level?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  facebook_id?: Maybe<Scalars["String"]["output"]>;
  first_name?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  language_skills?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  linkedin_id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  office_key?: Maybe<Scalars["String"]["output"]>;
  profile_complete?: Maybe<Scalars["Boolean"]["output"]>;
  remarks?: Maybe<Scalars["String"]["output"]>;
  telephone?: Maybe<Scalars["String"]["output"]>;
  transport_type?: Maybe<Scalars["String"]["output"]>;
  transport_types?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  user_key: Scalars["String"]["output"];
  work_experience?: Maybe<Array<Maybe<WorkExperience>>>;
  work_regimes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  workid_id?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateApplication = {
  __typename?: "CandidateApplication";
  employment_city?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateApplicationResponse = {
  __typename?: "CandidateApplicationResponse";
  job_application_key?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["Int"]["output"]>;
};

export type CandidateAppointment = {
  __typename?: "CandidateAppointment";
  address?: Maybe<Address>;
  company?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["String"]["output"]>;
  interview_reason?: Maybe<Scalars["String"]["output"]>;
  interviewer?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateAvailability = {
  __typename?: "CandidateAvailability";
  available?: Maybe<Scalars["Boolean"]["output"]>;
  available_date?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateContract = {
  __typename?: "CandidateContract";
  company?: Maybe<Scalars["String"]["output"]>;
  date_last_changed?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateDocument = {
  __typename?: "CandidateDocument";
  date_last_changed?: Maybe<Scalars["String"]["output"]>;
  document_type?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CandidateIntention = {
  __typename?: "CandidateIntention";
  email: Scalars["String"]["output"];
  first_name?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  user_key: Scalars["String"]["output"];
};

export type CandidateSearchPreference = {
  __typename?: "CandidateSearchPreference";
  jobstudent?: Maybe<Scalars["Boolean"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  latitude?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  longitude?: Maybe<Scalars["String"]["output"]>;
  opt_in_for_newsletter_preferences?: Maybe<Scalars["Boolean"]["output"]>;
  radius?: Maybe<Scalars["String"]["output"]>;
  sector_groups?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  specialties?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  terms?: Maybe<Scalars["String"]["output"]>;
  types?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  user_key?: Maybe<Scalars["String"]["output"]>;
  work_regimes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type CandidateStatus = {
  __typename?: "CandidateStatus";
  date_last_changed?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type Certificate = {
  __typename?: "Certificate";
  delivered_by?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type CertificateInput = {
  delivered_by?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A paginated set of results. */
export type Connection = {
  /** The edges of this connection. */
  edges: Array<Edge>;
  /** The nodes of the edges of this connection. */
  nodes?: Maybe<Array<EdgeNode>>;
  /** Information to aid in pagination. */
  pageInfo: ConnectionPageInfo;
};

/** Information about the page in a connection. */
export type ConnectionPageInfo = {
  __typename?: "ConnectionPageInfo";
  /** The cursor for the last element in this page. */
  endCursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** Whether there are more pages in this connection. */
  hasNextPage: Scalars["Boolean"]["output"];
  /** Whether there are previous pages in this connection. */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** The cursor for the first element in this page. */
  startCursor?: Maybe<Scalars["Cursor"]["output"]>;
};

/** Choose how your sorts will occur and on which field. */
export enum ConnectionSortKeys {
  /** Sort by creation date */
  CreatedAt = "CREATED_AT",
  /** Sort by promoted status. */
  Promoted = "PROMOTED",
  /** Sort by sticky status. */
  Sticky = "STICKY",
  /** Sort by entity title. */
  Title = "TITLE",
  /** Sort by updated date */
  UpdatedAt = "UPDATED_AT",
}

/** A DateTime object. */
export type DateTime = {
  __typename?: "DateTime";
  /** A string that will have a value of format ±hh:mm */
  offset: Scalars["UtcOffset"]["output"];
  /** RFC 3339 compliant time string. */
  time: Scalars["Time"]["output"];
  /** Type represents date and time as number of milliseconds from start of the UNIX epoch. */
  timestamp: Scalars["Timestamp"]["output"];
  /** A field whose value exists in the standard IANA Time Zone Database. */
  timezone: Scalars["TimeZone"]["output"];
};

export type Document = {
  __typename?: "Document";
  document: Scalars["String"]["output"];
  document_content_type?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

/**
 * An edge in a connection.
 * Provides the cursor to fetch data based on the position of the associated
 * node. Specific edge implementations may provide more information about the
 * relationship they represent.
 */
export type Edge = {
  cursor: Scalars["Cursor"]["output"];
  node: EdgeNode;
};

/** This entity is accessible over an Edge connection. */
export type EdgeNode = {
  id: Scalars["ID"]["output"];
};

export type Education = {
  __typename?: "Education";
  diploma?: Maybe<Scalars["String"]["output"]>;
  field?: Maybe<Scalars["String"]["output"]>;
  graduation_year?: Maybe<Scalars["Int"]["output"]>;
  school?: Maybe<Scalars["String"]["output"]>;
};

export type EducationInput = {
  diploma?: InputMaybe<Scalars["String"]["input"]>;
  field?: InputMaybe<Scalars["String"]["input"]>;
  graduation_year?: InputMaybe<Scalars["Int"]["input"]>;
  school?: InputMaybe<Scalars["String"]["input"]>;
};

/** The entity type behaviors for paragraphs */
export type EntityTypeBehaviors = {
  __typename?: "EntityTypeBehaviors";
  /** Add a background to the paragraph */
  background_color?: Maybe<Scalars["String"]["output"]>;
  /** Show background color width full width? */
  background_color_full_width?: Maybe<Scalars["String"]["output"]>;
  /** Show in a box display? */
  box_display?: Maybe<Scalars["String"]["output"]>;
  /** Change column count */
  column_count?: Maybe<Scalars["String"]["output"]>;
  /** Add bottom margin */
  margin?: Maybe<Scalars["String"]["output"]>;
  /** Select if the media should be shown right or left */
  media_position?: Maybe<Scalars["String"]["output"]>;
  /** Select the width of the text */
  text_width?: Maybe<Scalars["String"]["output"]>;
  /** Choose a view mode */
  view_mode?: Maybe<Scalars["String"]["output"]>;
};

/** This content has been arranged by a User using a layout builder. */
export type EntityTypeBehaviorsInterface = {
  entity_type_behaviors: EntityTypeBehaviors;
};

export type Error = {
  __typename?: "Error";
  status: Scalars["Int"]["output"];
};

export type Facet = {
  __typename?: "Facet";
  name: Scalars["String"]["output"];
  values?: Maybe<Array<Maybe<FacetValue>>>;
};

export type FacetValue = {
  __typename?: "FacetValue";
  count?: Maybe<Scalars["Int"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FaqCategoryFilterInput = {
  /** Taal  */
  langcode: Scalars["String"]["input"];
  /** FAQ Category » Inhoud » Doelgroep  */
  target_audience?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqCategoryResult = View & {
  __typename?: "FaqCategoryResult";
  /** De beschrijving van het overzicht. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The results of the view. */
  results: Array<NodeUnion>;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

export type FaqSearchFilterInput = {
  /** FAQ Category » Inhoud » UUID  */
  category_uuid?: InputMaybe<Scalars["String"]["input"]>;
  /** FAQ Category » Inhoud » Doelgroep  */
  field_target_audience_ref?: InputMaybe<Scalars["String"]["input"]>;
  /** Taal  */
  langcode: Scalars["String"]["input"];
  /** Search  */
  search?: InputMaybe<Scalars["String"]["input"]>;
};

/** Result for view faq_search display graphql_1. */
export type FaqSearchResult = View & {
  __typename?: "FaqSearchResult";
  /** De beschrijving van het overzicht. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The results of the view. */
  results: Array<NodeUnion>;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

/** A file object to represent an managed file. */
export type File = {
  __typename?: "File";
  /** The description of the file. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The mime type of the file. */
  mime?: Maybe<Scalars["String"]["output"]>;
  /** The name of the file. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The size of the file in bytes. */
  size: Scalars["Int"]["output"];
  /** The URL of the file. */
  url: Scalars["String"]["output"];
};

export type Hour = {
  __typename?: "Hour";
  closing_hour?: Maybe<Scalars["String"]["output"]>;
  opening_hour?: Maybe<Scalars["String"]["output"]>;
};

/** A image object to represent an managed file. */
export type Image = {
  __typename?: "Image";
  /** The alt text of the image. */
  alt: Scalars["String"]["output"];
  /** The title text of the image. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** Image variations control different sizes and formats for images. */
  variations?: Maybe<Array<ImageStyleDerivative>>;
};

/** Get the Image Banner Term. */
export type ImageBannerByName = {
  __typename?: "ImageBannerByName";
  imageMediaImage?: Maybe<Image>;
};

/** Entity type image_style. */
export type ImageStyle = ImageStyleInterface & {
  __typename?: "ImageStyle";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

/** List of image styles available to use. */
export enum ImageStyleAvailable {
  Avatar = "AVATAR",
  /** HERO */
  Hero = "HERO",
  /** Thumbnail (100×100) */
  Thumbnail = "THUMBNAIL",
  /** w950 - Teaser */
  W950Teaser = "W950_TEASER",
  /** w1440 - Teaser mobile */
  W1440TeaserMobile = "W1440_TEASER_MOBILE",
  /** w2000 */
  W2000 = "W2000",
  /** w3800 */
  W3800 = "W3800",
}

/** ImageStyle derivatives for an Image. */
export type ImageStyleDerivative = {
  __typename?: "ImageStyleDerivative";
  height: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
};

/** Entity type image_style. */
export type ImageStyleInterface = {
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

/** Entity type image_style. */
export type ImageStyleUnion = ImageStyle;

export type InvalidateCacheResponse = {
  __typename?: "InvalidateCacheResponse";
  status: Scalars["Int"]["output"];
};

/** List of possible cache actions to invalidate. */
export enum InvalidateCacheType {
  /** To invalidate job cache. Pass jobId in details. */
  Job = "JOB",
}

export type Job = {
  __typename?: "Job";
  city?: Maybe<Scalars["String"]["output"]>;
  closed?: Maybe<Scalars["Boolean"]["output"]>;
  company_key?: Maybe<Scalars["String"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  end_date?: Maybe<Scalars["String"]["output"]>;
  function?: Maybe<Scalars["String"]["output"]>;
  function_group?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  internal?: Maybe<Scalars["Boolean"]["output"]>;
  jobstudent?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  offer?: Maybe<Scalars["String"]["output"]>;
  office_id?: Maybe<Scalars["String"]["output"]>;
  postal_code?: Maybe<Scalars["String"]["output"]>;
  reference?: Maybe<Scalars["String"]["output"]>;
  regime?: Maybe<Scalars["String"]["output"]>;
  salary_max?: Maybe<Scalars["Float"]["output"]>;
  salary_min?: Maybe<Scalars["Float"]["output"]>;
  salary_period?: Maybe<Scalars["String"]["output"]>;
  sector_group?: Maybe<Scalars["String"]["output"]>;
  specialty?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  street_address?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updated_at?: Maybe<Scalars["String"]["output"]>;
  urgent?: Maybe<Scalars["Boolean"]["output"]>;
  website_url?: Maybe<Scalars["String"]["output"]>;
};

export type JobOption = {
  __typename?: "JobOption";
  key: Scalars["String"]["output"];
  translation: Scalars["String"]["output"];
};

export type JobOptionLists = {
  __typename?: "JobOptionLists";
  certificates?: Maybe<Array<JobOption>>;
  contract_types?: Maybe<Array<JobOption>>;
  document_types?: Maybe<Array<JobOption>>;
  drivers_licences?: Maybe<Array<JobOption>>;
  education_levels?: Maybe<Array<JobOption>>;
  function_groups?: Maybe<Array<JobOption>>;
  language_skills?: Maybe<Array<JobOption>>;
  sector_groups?: Maybe<Array<JobOption>>;
  specialties?: Maybe<Array<JobOption>>;
  transport_types?: Maybe<Array<JobOption>>;
  work_regimes?: Maybe<Array<JobOption>>;
};

export type JobSearch = {
  __typename?: "JobSearch";
  facets: Array<Facet>;
  from: Scalars["Int"]["output"];
  jobs: Array<Job>;
  to: Scalars["Int"]["output"];
  total_results: Scalars["Int"]["output"];
};

/** Generic untyped input for key-value pairs. */
export type KeyValueInput = {
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/** A language definition provided by the CMS. */
export type Language = {
  __typename?: "Language";
  /** The language direction. */
  direction?: Maybe<Scalars["String"]["output"]>;
  /** The language code. */
  id?: Maybe<Scalars["ID"]["output"]>;
  /** The language name. */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** A link. */
export type Link = {
  __typename?: "Link";
  /** Whether the link is internal to this website. */
  internal: Scalars["Boolean"]["output"];
  /** The title of the link. */
  title?: Maybe<Scalars["String"]["output"]>;
  /** The URL of the link. */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** An uploaded file or document, such as a PDF. */
export type MediaDocument = MediaInterface &
  MetaTagInterface & {
    __typename?: "MediaDocument";
    /** The time the media item was last edited. */
    changed: DateTime;
    /** The time the media item was created. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Language */
    langcode: Language;
    /** File */
    mediaFile: File;
    /** Media Tags */
    mediaTags?: Maybe<Array<TermUnion>>;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /** Name */
    name: Scalars["String"]["output"];
    /** URL alias */
    path: Scalars["String"]["output"];
    /** Published */
    status: Scalars["Boolean"]["output"];
  };

/** Add a media image. */
export type MediaImage = MediaInterface & {
  __typename?: "MediaImage";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Image */
  imageMediaImage?: Maybe<Image>;
  /** Name */
  name: Scalars["String"]["output"];
  /** Published */
  status: Scalars["Boolean"]["output"];
};

/** Entity type media. */
export type MediaInterface = {
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Published */
  status: Scalars["Boolean"]["output"];
};

/** Voeg een externe video toe aan de website (Youtube, Vimeo...) */
export type MediaRemoteVideo = MediaInterface & {
  __typename?: "MediaRemoteVideo";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Externe video URL */
  mediaOembedVideo: Scalars["String"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Voeg een placeholder image voor de video toe. */
  remoteVideoMediaImage?: Maybe<Image>;
  /** Published */
  status: Scalars["Boolean"]["output"];
};

/** Entity type media. */
export type MediaSvgImage = MediaInterface & {
  __typename?: "MediaSvgImage";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** Published */
  status: Scalars["Boolean"]["output"];
  /** Image */
  svgMediaImage: Image;
};

/** Entity type media. */
export type MediaUnion =
  | MediaDocument
  | MediaImage
  | MediaRemoteVideo
  | MediaSvgImage
  | MediaVideo;

/** A locally hosted video file. */
export type MediaVideo = MediaInterface &
  MetaTagInterface & {
    __typename?: "MediaVideo";
    /** The time the media item was last edited. */
    changed: DateTime;
    /** The time the media item was created. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Language */
    langcode: Language;
    /** Media Tags */
    mediaTags?: Maybe<Array<TermUnion>>;
    /**
     * The video file must be under 10MB.
     * Video files are never displayed with audio and will act as a looping video.
     */
    mediaVideoFile: File;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /** Name */
    name: Scalars["String"]["output"];
    /** Published */
    status: Scalars["Boolean"]["output"];
  };

export type MediaVideoUnion = MediaRemoteVideo | MediaVideo;

/** Entity type menu. */
export type Menu = MenuInterface & {
  __typename?: "Menu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** The menu items. */
  items?: Maybe<Array<MenuItem>>;
  /** The menu name. */
  name: Scalars["String"]["output"];
};

/** List of menus available to load. */
export enum MenuAvailable {
  /** Expertises menu */
  ExpertisesMenu = "EXPERTISES_MENU",
  /** Voet */
  Footer = "FOOTER",
  /** Voor werknemers menu */
  ForEmployeesMenu = "FOR_EMPLOYEES_MENU",
  /** Voor werkgevers menu */
  ForEmployersMenu = "FOR_EMPLOYERS_MENU",
  /** Jobs per regio menu */
  JobsPerRegionMenu = "JOBS_PER_REGION_MENU",
  /** Jobs per sector menu */
  JobsPerSectorMenu = "JOBS_PER_SECTOR_MENU",
  /** Legal menu */
  Legal = "LEGAL",
  /** Hoofdmenu - Werknemer */
  MainMenuEmployee = "MAIN_MENU_EMPLOYEE",
  /** Hoofdmenu - Werkgever */
  MainMenuEmployer = "MAIN_MENU_EMPLOYER",
  /** Meer over Vivaldis */
  MoreAboutVivaldis = "MORE_ABOUT_VIVALDIS",
  /** Secundair menu - Werknemer */
  SecondaryMenuEmployee = "SECONDARY_MENU_EMPLOYEE",
  /** Secundair menu - Werkgever */
  SecondaryMenuEmployer = "SECONDARY_MENU_EMPLOYER",
  /** Services menu */
  ServicesMenu = "SERVICES_MENU",
  /** Tools menu */
  ToolsMenu = "TOOLS_MENU",
}

/** Entity type menu. */
export type MenuInterface = {
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** The menu items. */
  items?: Maybe<Array<MenuItem>>;
  /** The menu name. */
  name: Scalars["String"]["output"];
};

/** A menu item defined in the CMS. */
export type MenuItem = {
  __typename?: "MenuItem";
  /** Child menu items of this menu item. */
  children?: Maybe<Array<MenuItem>>;
  /** The menu link content entity associated with this menu link. */
  extras?: Maybe<MenuLinkContentUnion>;
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Whether this menu item links to an internal route. */
  internal?: Maybe<Scalars["Boolean"]["output"]>;
  /** The title of the menu item. */
  title: Scalars["String"]["output"];
  /** The URL of the menu item. */
  url: Scalars["String"]["output"];
};

/** Menu item options set within the CMS. */
export type MenuItemAttributes = {
  __typename?: "MenuItemAttributes";
  class?: Maybe<Scalars["String"]["output"]>;
};

export type MenuLinkContentExpertisesMenu = {
  __typename?: "MenuLinkContentExpertisesMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentForEmployeesMenu = {
  __typename?: "MenuLinkContentForEmployeesMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentForEmployersMenu = {
  __typename?: "MenuLinkContentForEmployersMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

/** Footer - Jobs per regio (werknemers) */
export type MenuLinkContentJobsPerRegionMenu = {
  __typename?: "MenuLinkContentJobsPerRegionMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Suffix */
  suffix?: Maybe<Scalars["String"]["output"]>;
};

/** Footer - Jobs per sector (werknemers) */
export type MenuLinkContentJobsPerSectorMenu = {
  __typename?: "MenuLinkContentJobsPerSectorMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Suffix */
  suffix?: Maybe<Scalars["String"]["output"]>;
};

export type MenuLinkContentLegal = {
  __typename?: "MenuLinkContentLegal";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentMainMenuEmployee = {
  __typename?: "MenuLinkContentMainMenuEmployee";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentMainMenuEmployer = {
  __typename?: "MenuLinkContentMainMenuEmployer";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentMoreAboutVivaldis = {
  __typename?: "MenuLinkContentMoreAboutVivaldis";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

/** Entity type menu_link_content. */
export type MenuLinkContentSecondaryMenuEmployee = {
  __typename?: "MenuLinkContentSecondaryMenuEmployee";
  /** Icon */
  iconSelect?: Maybe<Scalars["String"]["output"]>;
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

/** Entity type menu_link_content. */
export type MenuLinkContentSecondaryMenuEmployer = {
  __typename?: "MenuLinkContentSecondaryMenuEmployer";
  /** Icon */
  iconSelect?: Maybe<Scalars["String"]["output"]>;
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentServicesMenu = {
  __typename?: "MenuLinkContentServicesMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

export type MenuLinkContentToolsMenu = {
  __typename?: "MenuLinkContentToolsMenu";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
};

/** Entity type menu_link_content. */
export type MenuLinkContentUnion =
  | MenuLinkContentExpertisesMenu
  | MenuLinkContentForEmployeesMenu
  | MenuLinkContentForEmployersMenu
  | MenuLinkContentJobsPerRegionMenu
  | MenuLinkContentJobsPerSectorMenu
  | MenuLinkContentLegal
  | MenuLinkContentMainMenuEmployee
  | MenuLinkContentMainMenuEmployer
  | MenuLinkContentMoreAboutVivaldis
  | MenuLinkContentSecondaryMenuEmployee
  | MenuLinkContentSecondaryMenuEmployer
  | MenuLinkContentServicesMenu
  | MenuLinkContentToolsMenu;

/** Entity type menu. */
export type MenuUnion = Menu;

/** A meta tag element. */
export type MetaTag = {
  /** The HTML tag for this meta element. */
  tag: Scalars["String"]["output"];
};

/** This entity has meta tags enabled. */
export type MetaTagInterface = {
  /** The computed meta tags for the entity. */
  metatag: Array<MetaTagUnion>;
};

/** A meta link element. */
export type MetaTagLink = MetaTag & {
  __typename?: "MetaTagLink";
  /** The meta tag element attributes. */
  attributes: MetaTagLinkAttributes;
  /** The HTML tag for this meta element. */
  tag: Scalars["String"]["output"];
};

/** A meta link element's attributes. */
export type MetaTagLinkAttributes = {
  __typename?: "MetaTagLinkAttributes";
  /** The href attribute of the link. */
  href?: Maybe<Scalars["String"]["output"]>;
  /** The rel attribute of the link. */
  rel?: Maybe<Scalars["String"]["output"]>;
};

/** A meta property element. */
export type MetaTagProperty = MetaTag & {
  __typename?: "MetaTagProperty";
  /** The meta tag element attributes. */
  attributes: MetaTagPropertyAttributes;
  /** The HTML tag for this meta element. */
  tag: Scalars["String"]["output"];
};

/** A meta property element's attributes. */
export type MetaTagPropertyAttributes = {
  __typename?: "MetaTagPropertyAttributes";
  /** The content attribute of the meta tag. */
  content?: Maybe<Scalars["String"]["output"]>;
  /** The property attribute of the meta tag. */
  property?: Maybe<Scalars["String"]["output"]>;
};

/** A meta tag element. */
export type MetaTagUnion = MetaTagLink | MetaTagProperty | MetaTagValue;

/** A meta content element. */
export type MetaTagValue = MetaTag & {
  __typename?: "MetaTagValue";
  /** The meta tag element attributes. */
  attributes: MetaTagValueAttributes;
  /** The HTML tag for this meta element. */
  tag: Scalars["String"]["output"];
};

/** A meta content element's attributes. */
export type MetaTagValueAttributes = {
  __typename?: "MetaTagValueAttributes";
  /** The content attribute of the meta tag. */
  content?: Maybe<Scalars["String"]["output"]>;
  /** The name attribute of the meta tag. */
  name?: Maybe<Scalars["String"]["output"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type Mutation = {
  __typename?: "Mutation";
  /** Placeholder field to enable schema mutation extension. */
  _: Scalars["Boolean"]["output"];
  activateUser?: Maybe<UserActivateResponse>;
  addDocument?: Maybe<Response>;
  candidateContractsByKey?: Maybe<Array<Maybe<CandidateContract>>>;
  createApplication?: Maybe<Application>;
  createNewsletterSubscription?: Maybe<NewsletterSubscriptionResponse>;
  createNewsletterSubscriptionByContactKey?: Maybe<Response>;
  createUser?: Maybe<UserResponse>;
  createUserApplication?: Maybe<CandidateApplicationResponse>;
  createUserToken?: Maybe<UserTokenResponse>;
  invalidateCache?: Maybe<InvalidateCacheResponse>;
  resetUserPassword?: Maybe<UserResetPassword>;
  updateApplication?: Maybe<Application>;
  updateCandidate?: Maybe<Response>;
  updateCandidateAvailability?: Maybe<Response>;
  updateCandidateSavedJobs?: Maybe<Response>;
  updateCandidateSearchPreferences?: Maybe<Response>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationActivateUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationAddDocumentArgs = {
  document?: InputMaybe<Scalars["String"]["input"]>;
  document_content_type?: InputMaybe<Scalars["String"]["input"]>;
  document_type_key?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  user_key?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCandidateContractsByKeyArgs = {
  contract_key: Scalars["String"]["input"];
  user_key: Scalars["String"]["input"];
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateApplicationArgs = {
  birth_date?: InputMaybe<Scalars["Date"]["input"]>;
  desired_function?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  job_id?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  office_key?: InputMaybe<Scalars["String"]["input"]>;
  postal_code?: InputMaybe<Scalars["String"]["input"]>;
  privacy_statement?: InputMaybe<Scalars["Boolean"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  telephone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateNewsletterSubscriptionArgs = {
  email: Scalars["String"]["input"];
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  journey_id?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  legal_basis?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  opt_in?: InputMaybe<Scalars["Boolean"]["input"]>;
  opt_in_date?: InputMaybe<Scalars["String"]["input"]>;
  opt_in_source?: InputMaybe<Scalars["String"]["input"]>;
  opt_out_date?: InputMaybe<Scalars["String"]["input"]>;
  opt_out_source?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateNewsletterSubscriptionByContactKeyArgs = {
  contact_key?: InputMaybe<Scalars["String"]["input"]>;
  first_name?: InputMaybe<Scalars["String"]["input"]>;
  journey_id?: InputMaybe<Scalars["String"]["input"]>;
  last_name?: InputMaybe<Scalars["String"]["input"]>;
  legal_basis?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  opt_in?: InputMaybe<Scalars["Boolean"]["input"]>;
  opt_in_date?: InputMaybe<Scalars["String"]["input"]>;
  opt_in_source?: InputMaybe<Scalars["String"]["input"]>;
  opt_out_date?: InputMaybe<Scalars["String"]["input"]>;
  opt_out_source?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateUserArgs = {
  confirmed: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  email_page_url: Scalars["String"]["input"];
  first_name: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  last_name: Scalars["String"]["input"];
  newsletter: Scalars["Boolean"]["input"];
  password: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateUserApplicationArgs = {
  job_id: Scalars["String"]["input"];
  user_key: Scalars["String"]["input"];
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationCreateUserTokenArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationInvalidateCacheArgs = {
  details?: InputMaybe<Scalars["String"]["input"]>;
  type: InvalidateCacheType;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationResetUserPasswordArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationUpdateApplicationArgs = {
  application_key: Scalars["String"]["input"];
  cv?: InputMaybe<Scalars["String"]["input"]>;
  cv_anonymous?: InputMaybe<Scalars["String"]["input"]>;
  cv_anonymous_content_type?: InputMaybe<Scalars["String"]["input"]>;
  cv_content_type?: InputMaybe<Scalars["String"]["input"]>;
  job_event?: InputMaybe<Scalars["String"]["input"]>;
  job_id?: InputMaybe<Scalars["String"]["input"]>;
  motivation?: InputMaybe<Scalars["String"]["input"]>;
  motivation_content_type?: InputMaybe<Scalars["String"]["input"]>;
  remarks?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationUpdateCandidateArgs = {
  birth_date?: InputMaybe<Scalars["Date"]["input"]>;
  bus?: InputMaybe<Scalars["String"]["input"]>;
  certificates?: InputMaybe<Array<InputMaybe<CertificateInput>>>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  contract_types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  drivers_licence?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  educational_level?: InputMaybe<Scalars["String"]["input"]>;
  educations?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  facebook_id?: InputMaybe<Scalars["String"]["input"]>;
  first_name: Scalars["String"]["input"];
  house_number?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_skills?: InputMaybe<Scalars["String"]["input"]>;
  last_name: Scalars["String"]["input"];
  linkedin_id?: InputMaybe<Scalars["String"]["input"]>;
  office_key?: InputMaybe<Scalars["String"]["input"]>;
  postal_code?: InputMaybe<Scalars["String"]["input"]>;
  profile_complete: Scalars["Boolean"]["input"];
  properties?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  remarks?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  telephone?: InputMaybe<Scalars["String"]["input"]>;
  transport_type?: InputMaybe<Scalars["String"]["input"]>;
  transport_types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  user_key: Scalars["String"]["input"];
  work_experience?: InputMaybe<Array<InputMaybe<WorkExperienceInput>>>;
  work_regimes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workid_id?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationUpdateCandidateAvailabilityArgs = {
  available?: InputMaybe<Scalars["Boolean"]["input"]>;
  available_date?: InputMaybe<Scalars["String"]["input"]>;
  extra_information?: InputMaybe<Scalars["String"]["input"]>;
  journey_id?: InputMaybe<Scalars["String"]["input"]>;
  telephone?: InputMaybe<Scalars["String"]["input"]>;
  user_key?: InputMaybe<Scalars["String"]["input"]>;
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationUpdateCandidateSavedJobsArgs = {
  saved_jobs: Scalars["String"]["input"];
  user_key: Scalars["String"]["input"];
};

/** A GraphQL mutation is a request that changes data on the server. */
export type MutationUpdateCandidateSearchPreferencesArgs = {
  jobstudent?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  latitude?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  longitude?: InputMaybe<Scalars["String"]["input"]>;
  opt_in_for_newsletter_preferences?: InputMaybe<Scalars["Boolean"]["input"]>;
  radius?: InputMaybe<Scalars["String"]["input"]>;
  sector_groups?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  specialties?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  terms?: InputMaybe<Scalars["String"]["input"]>;
  types?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  user_key?: InputMaybe<Scalars["String"]["input"]>;
  work_regimes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type NewsletterSubscriptionResponse = {
  __typename?: "NewsletterSubscriptionResponse";
  user_key?: Maybe<Scalars["String"]["output"]>;
};

/** Entity type node. */
export type NodeBlog = EdgeNode &
  EntityTypeBehaviorsInterface &
  MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeBlog";
    /** Auteur */
    author?: Maybe<Scalars["String"]["output"]>;
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Category */
    blogCategoryRef?: Maybe<Array<TermUnion>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** Content */
    content?: Maybe<Array<ParagraphUnion>>;
    /** Het tijdstip waarop de node is aangemaakt. */
    created: DateTime;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Intro */
    intro?: Maybe<Text>;
    /** Taal */
    langcode: Language;
    /** Select the main image off the page. */
    mainMedia?: Maybe<MediaImage>;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaImage>;
    /** URL-alias */
    path: Scalars["String"]["output"];
    /** UUID's of related blogs */
    relatedBlogs: Array<Maybe<NodeBlog>>;
    /** Doelgroep */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Teaser media */
    teaserMedia: MediaImage;
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** A paginated set of results for NodeBlog. */
export type NodeBlogConnection = Connection & {
  __typename?: "NodeBlogConnection";
  edges: Array<NodeBlogEdge>;
  nodes: Array<NodeBlog>;
  pageInfo: ConnectionPageInfo;
};

/** Edge for NodeBlog. */
export type NodeBlogEdge = Edge & {
  __typename?: "NodeBlogEdge";
  cursor: Scalars["Cursor"]["output"];
  node: NodeBlog;
};

/** Entity type node. */
export type NodeBlogOverview = MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeBlogOverview";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** Datum en tijdstip waarop de inhoud gemaakt is. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Taal */
    langcode: Language;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaUnion>;
    /** URL alias */
    path: Scalars["String"]["output"];
    /** Target audience */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** Entity type node. */
export type NodeFaq = EdgeNode &
  EntityTypeBehaviorsInterface &
  MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeFaq";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** Content */
    content?: Maybe<Array<ParagraphUnion>>;
    /** The date and time that the content was created. */
    created: DateTime;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** FAQ Category */
    faqCategory?: Maybe<NodeUnion>;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Taal */
    langcode: Language;
    /** Select the main image off the page. */
    mainMedia?: Maybe<MediaUnion>;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaUnion>;
    /** URL-alias */
    path: Scalars["String"]["output"];
    /** Aangeraden op de voorpagina */
    promote: Scalars["Boolean"]["output"];
    /** The related faqs */
    relatedFaqs: Array<Maybe<RelatedFaqs>>;
    /** SEO Description */
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    /** SEO Title */
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** Gepubliceerd */
    status: Scalars["Boolean"]["output"];
    /** Vastgeplakt bovenaan de lijst */
    sticky: Scalars["Boolean"]["output"];
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** Entity type node. */
export type NodeFaqCategory = EdgeNode &
  EntityTypeBehaviorsInterface &
  MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeFaqCategory";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** The date and time that the content was created. */
    created: DateTime;
    /** Intro */
    description?: Maybe<Text>;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** Icon */
    icon?: Maybe<Scalars["String"]["output"]>;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Taal */
    langcode: Language;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaUnion>;
    /** URL-alias */
    path: Scalars["String"]["output"];
    /** Aangeraden op de voorpagina */
    promote: Scalars["Boolean"]["output"];
    /** SEO Description */
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    /** SEO Title */
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** Gepubliceerd */
    status: Scalars["Boolean"]["output"];
    /** Vastgeplakt bovenaan de lijst */
    sticky: Scalars["Boolean"]["output"];
    /** Doelgroep */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** Entity type node. */
export type NodeFaqCategoryOverview = MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeFaqCategoryOverview";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** Datum en tijdstip waarop de inhoud gemaakt is. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Taal */
    langcode: Language;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaUnion>;
    /** URL-alias */
    path: Scalars["String"]["output"];
    /** Target audience */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** Entity type node. */
export type NodeFaqOverview = MetaTagInterface &
  NodeInterface & {
    __typename?: "NodeFaqOverview";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** Het tijdstip waarop de node voor het laatst werd bewerkt. */
    changed: DateTime;
    /** Datum en tijdstip waarop de inhoud gemaakt is. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Taal */
    langcode: Language;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaUnion>;
    /** URL-alias */
    path: Scalars["String"]["output"];
    /** Target audience */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Titel */
    title: Scalars["String"]["output"];
  };

/** Entity type node. */
export type NodeInterface = {
  /** The time that the node was last edited. */
  changed: DateTime;
  /** The time that the node was created. */
  created: DateTime;
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Language */
  langcode: Language;
  /** The computed meta tags for the entity. */
  metatag: Array<MetaTagUnion>;
  /** URL alias */
  path: Scalars["String"]["output"];
  /** Title */
  title: Scalars["String"]["output"];
};

/** Use the page to create a  rich page on your website containing text, images, forms... */
export type NodePage = EdgeNode &
  MetaTagInterface &
  NodeInterface & {
    __typename?: "NodePage";
    /** Available languages */
    availableLanguages?: Maybe<Array<Maybe<AvailableLanguages>>>;
    /** The time that the node was last edited. */
    changed: DateTime;
    /** Content */
    content?: Maybe<Array<ParagraphUnion>>;
    /** The time that the node was created. */
    created: DateTime;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Intro */
    intro?: Maybe<Text>;
    /** Language */
    langcode: Language;
    /** Select the main image off the page. */
    mainMedia?: Maybe<MediaImage>;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /**
     * Select a specific OG Image for this page. If not selected, there is a fallback
     * on the "main media". And at the last the default platform OG image.
     */
    ogImage?: Maybe<MediaImage>;
    /** URL alias */
    path: Scalars["String"]["output"];
    /** System tag */
    systemTag?: Maybe<UnsupportedType>;
    /** Doelgroep */
    targetAudienceRef?: Maybe<TermTargetAudience>;
    /** Teaser media */
    teaserMedia?: Maybe<MediaImage>;
    /** Title */
    title: Scalars["String"]["output"];
  };

/** A paginated set of results for NodePage. */
export type NodePageConnection = Connection & {
  __typename?: "NodePageConnection";
  edges: Array<NodePageEdge>;
  nodes?: Maybe<Array<NodePage>>;
  pageInfo: ConnectionPageInfo;
};

/** Edge for NodePage. */
export type NodePageEdge = Edge & {
  __typename?: "NodePageEdge";
  cursor: Scalars["Cursor"]["output"];
  node: NodePage;
};

/** Entity type node. */
export type NodeUnion =
  | NodeBlog
  | NodeBlogOverview
  | NodeFaq
  | NodeFaqCategory
  | NodeFaqCategoryOverview
  | NodeFaqOverview
  | NodePage;

export type Office = {
  __typename?: "Office";
  address?: Maybe<Address>;
  chat?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  facebook?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  instagram?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  linkedin?: Maybe<Scalars["String"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  opening_hours?: Maybe<Array<Maybe<OpeningHour>>>;
  photo?: Maybe<Scalars["String"]["output"]>;
  place_id?: Maybe<Scalars["String"]["output"]>;
  speciality?: Maybe<Scalars["String"]["output"]>;
  staff?: Maybe<Array<Maybe<Staff>>>;
  telephone?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  twitter?: Maybe<Scalars["String"]["output"]>;
};

export type OpeningHour = {
  __typename?: "OpeningHour";
  day?: Maybe<Scalars["Int"]["output"]>;
  hours?: Maybe<Array<Maybe<Hour>>>;
};

/** Gebruik een call to action om een link wat te highlighten */
export type ParagraphCallToAction = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphCallToAction";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Label */
    label?: Maybe<Scalars["String"]["output"]>;
    /** Link */
    paragraphCallToActionLink: Link;
    /** Link 2 */
    paragraphCallToActionLink2?: Maybe<Link>;
    /** Voeg een korte text toe aan de call to action */
    paragraphCallToActionText?: Maybe<Text>;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

export type ParagraphChatbotSuggestions = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphChatbotSuggestions";
    btnLinks: Array<Link>;
    chatBubbleText: Text;
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Add a custom teaser to the page */
export type ParagraphCustomTeaser = ParagraphInterface & {
  __typename?: "ParagraphCustomTeaser";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Link */
  paragraphCustomTeaserLink?: Maybe<Link>;
  /** Image */
  paragraphCustomTeaserMedia?: Maybe<MediaImage>;
  /** Text */
  paragraphCustomTeaserText?: Maybe<Text>;
  status: Scalars["Boolean"]["output"];
  /** Title */
  title?: Maybe<Scalars["String"]["output"]>;
};

/** Entity type paragraph. */
export type ParagraphFaqBlock = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphFaqBlock";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** FAQ items */
    faqItems: Array<ParagraphFaqItem>;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
  };

/** Entity type paragraph. */
export type ParagraphFaqItem = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphFaqItem";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
    /** Answer */
    text: Text;
    /** Question */
    title: Scalars["String"]["output"];
  };

export type ParagraphFaqOverview = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphFaqOverview";
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
  };

/** Entity type paragraph. */
export type ParagraphFindJobs = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphFindJobs";
    /** Tijdstip waarop de paragraaf is aangemaakt. */
    created: DateTime;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Entiteittaalcode van de paragraaf. */
    langcode: Language;
    /** Per functie */
    perFunction?: Maybe<Array<Link>>;
    /** Per sector */
    perSector?: Maybe<Array<Link>>;
    status: Scalars["Boolean"]["output"];
  };

/** Entity type paragraph. */
export type ParagraphFiveBlocks = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphFiveBlocks";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Image for block 2 */
    imageForBlock2?: Maybe<MediaImage>;
    /** Image for block 3 */
    imageForBlock3: MediaImage;
    /** Image for block 4 */
    imageForBlock4: MediaImage;
    /** Image for block 5 */
    imageForBlock5: MediaImage;
    status: Scalars["Boolean"]["output"];
    /** Text for block 1 */
    textForBlock1?: Maybe<Text>;
    /** Text for block 2 */
    textForBlock2?: Maybe<Text>;
    /** Text for block 3 */
    textForBlock3: Text;
    /** Text for block 4 */
    textForBlock4: Text;
    /** Text for block 5 */
    textForBlock5: Text;
    /** Title for block 1 */
    titleForBlock1: Scalars["String"]["output"];
    /** Title for block 3 */
    titleForBlock3: Scalars["String"]["output"];
    /** Title for block 4 */
    titleForBlock4: Scalars["String"]["output"];
    /** Title for block 5 */
    titleForBlock5: Scalars["String"]["output"];
  };

export type ParagraphHtml = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphHtml";
    code: Scalars["String"]["output"];
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
  };

/** Voeg een afbeelding toe aan een pagina */
export type ParagraphImage = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphImage";
    /** Voeg een korte beschrijving toe onder de afbeelding */
    caption?: Maybe<Text>;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Voeg een afbeelding toe uit de afbeelding bibliotheek */
    paragraphImageMedia?: Maybe<MediaImage>;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Entity type paragraph. */
export type ParagraphInterface = {
  /** The Universally Unique Identifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Gepubliceerd */
  status: Scalars["Boolean"]["output"];
};

export type ParagraphJobsList = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphJobsList";
    city?: Maybe<Scalars["String"]["output"]>;
    entity_type_behaviors: EntityTypeBehaviors;
    functionGroup?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    id: Scalars["ID"]["output"];
    language?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    limit?: Maybe<Scalars["Int"]["output"]>;
    link?: Maybe<Link>;
    query?: Maybe<Scalars["String"]["output"]>;
    regime?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    sectorGroup?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    speciality?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
    status: Scalars["Boolean"]["output"];
    studentJob?: Maybe<Scalars["Boolean"]["output"]>;
    title?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  };

/** Voeg text met daarnaast een media item toe (video of afbeelding) */
export type ParagraphMediaText = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphMediaText";
    /** Voeg een klein bijschrift onder de media entiteit toe */
    caption?: Maybe<Text>;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Label */
    label?: Maybe<Scalars["String"]["output"]>;
    /** Links */
    links?: Maybe<Array<Link>>;
    /** If you want to have an image as a link - use this field */
    paragraphMediaTextLinkForMedia?: Maybe<Link>;
    /** Media */
    paragraphMediaTextMedia?: Maybe<ParagraphMediaTextMediaUnion>;
    /** Text */
    paragraphMediaTextText: Text;
    /** Gepubliceerd */
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

export type ParagraphNewsletterForm = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphNewsletterForm";
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
  };

export type ParagraphQuote = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphQuote";
    author?: Maybe<Scalars["String"]["output"]>;
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
    text: Text;
  };

/** Entity type paragraph. */
export type ParagraphRecentBlogs = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphRecentBlogs";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Intro */
    intro?: Maybe<Text>;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Add a reference to an existing piece of content, which will be rendered in a teaser view mode */
export type ParagraphReferenceTeaser = ParagraphInterface & {
  __typename?: "ParagraphReferenceTeaser";
  /** Content */
  contentRef: NodeInterface;
  /** The Universally Unique Identifier (UUID). */
  id: Scalars["ID"]["output"];
  status: Scalars["Boolean"]["output"];
};

/** Entity type paragraph. */
export type ParagraphStatistic = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphStatistic";
    /** The time that the Paragraph was created. */
    created: DateTime;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** The paragraphs entity language code. */
    langcode: Language;
    /** Statistic items */
    statisticItems: Array<ParagraphStatisticItem>;
    /** Published */
    status: Scalars["Boolean"]["output"];
  };

/** Entity type paragraph. */
export type ParagraphStatisticItem = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphStatisticItem";
    /** The time that the Paragraph was created. */
    created: DateTime;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** The paragraphs entity language code. */
    langcode: Language;
    /** Amount */
    statisticItemAmount: Scalars["String"]["output"];
    /** Description */
    statisticItemDescription: Text;
    /** Entity */
    statisticItemEntity: Scalars["String"]["output"];
    /** Published */
    status: Scalars["Boolean"]["output"];
  };

/** Entity type teaser paragraph. */
export type ParagraphTeaserUnion =
  | ParagraphCustomTeaser
  | ParagraphReferenceTeaser;

/** Add a list of teasers to a page */
export type ParagraphTeasers = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphTeasers";
    /** Content */
    content?: Maybe<Array<ParagraphTeaserUnion>>;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Intro */
    intro?: Maybe<Text>;
    /** Link */
    paragraphTeasersLink?: Maybe<Link>;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

export type ParagraphTestimonial = {
  __typename?: "ParagraphTestimonial";
  author: Scalars["String"]["output"];
  authorImage?: Maybe<MediaImage>;
  functionTitle: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  status: Scalars["Boolean"]["output"];
  text: Text;
};

/** Entity type paragraph. */
export type ParagraphTestimonials = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphTestimonials";
    entity_type_behaviors: EntityTypeBehaviors;
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
    testimonials: Array<ParagraphTestimonial>;
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Voeg text toe aan een pagina */
export type ParagraphText = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphText";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    paragraphTextLink?: Maybe<Link>;
    /** Text */
    paragraphTextText: Text;
    status: Scalars["Boolean"]["output"];
  };

/** Entity type paragraph. */
export type ParagraphThreeColumnBlock = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphThreeColumnBlock";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Label */
    label?: Maybe<Scalars["String"]["output"]>;
    /** Label for block 1 */
    labelForBlock1?: Maybe<Scalars["String"]["output"]>;
    /** Label for block 2 */
    labelForBlock2?: Maybe<Scalars["String"]["output"]>;
    /** Label for block 3 */
    labelForBlock3?: Maybe<Scalars["String"]["output"]>;
    /** Links */
    links?: Maybe<Array<Link>>;
    /** Links for block 1 */
    linksForBlock1?: Maybe<Array<Link>>;
    /** Links for block 2 */
    linksForBlock2?: Maybe<Array<Link>>;
    /** Links for block 3 */
    linksForBlock3?: Maybe<Array<Link>>;
    /** Image for block 1 */
    paragraphThreeColumnBlockImageForBlock1?: Maybe<MediaImage>;
    /** Image for block 2 */
    paragraphThreeColumnBlockImageForBlock2?: Maybe<MediaImage>;
    /** Image for block 3 */
    paragraphThreeColumnBlockImageForBlock3?: Maybe<MediaImage>;
    /** Text for block 1 */
    paragraphThreeColumnBlockTextForBlock1: Text;
    /** Text for block 2 */
    paragraphThreeColumnBlockTextForBlock2: Text;
    /** Text for block 3 */
    paragraphThreeColumnBlockTextForBlock3: Text;
    /** Title for block 1 */
    paragraphThreeColumnBlockTitleForBlock1?: Maybe<
      Scalars["String"]["output"]
    >;
    /** Title for block 2 */
    paragraphThreeColumnBlockTitleForBlock2?: Maybe<
      Scalars["String"]["output"]
    >;
    /** Title for block 3 */
    paragraphThreeColumnBlockTitleForBlock3?: Maybe<
      Scalars["String"]["output"]
    >;
    /** Published */
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Entity type paragraph. */
export type ParagraphThreeImages = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphThreeImages";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Image for column 1 */
    image1ForThreeImages: MediaImage;
    /** Image for column 2 */
    image2ForThreeImages: MediaImage;
    /** Image for column 3 */
    image3ForThreeImages: MediaImage;
    /** Link for image in block 1 */
    linkForImageInBlock1?: Maybe<Link>;
    /** Link for image in block 2 */
    linkForImageInBlock2?: Maybe<Link>;
    /** Link for image in block 3 */
    linkForImageInBlock3?: Maybe<Link>;
    /** Links for column 1 */
    linksForBlock1?: Maybe<Array<Link>>;
    /** Links for column 2 */
    linksForBlock2?: Maybe<Array<Link>>;
    /** Links for column 3 */
    linksForBlock3?: Maybe<Array<Link>>;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Entity type paragraph. */
export type ParagraphTwoColumnBlock = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphTwoColumnBlock";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Label */
    label?: Maybe<Scalars["String"]["output"]>;
    /** Label for block 1 */
    labelForBlock1?: Maybe<Scalars["String"]["output"]>;
    /** Label for block 2 */
    labelForBlock2?: Maybe<Scalars["String"]["output"]>;
    /** Links for block 1 */
    linksForBlock1?: Maybe<Array<Link>>;
    /** Links for block 2 */
    linksForBlock2?: Maybe<Array<Link>>;
    /** Image for block 1 */
    paragraphTwoColumnBlockImageForBlock1: MediaImage;
    /** Image for block 2 */
    paragraphTwoColumnBlockImageForBlock2: MediaImage;
    /** Text for block 1 */
    paragraphTwoColumnBlockTextForBlock1: Text;
    /** Text for block 2 */
    paragraphTwoColumnBlockTextForBlock2: Text;
    /** Title for block 1 */
    paragraphTwoColumnBlockTitleForBlock1?: Maybe<Scalars["String"]["output"]>;
    /** Title for block 2 */
    paragraphTwoColumnBlockTitleForBlock2?: Maybe<Scalars["String"]["output"]>;
    /** Published */
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Entity type paragraph. */
export type ParagraphTwoTextColumns = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphTwoTextColumns";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Links */
    linksForColumn1?: Maybe<Array<Link>>;
    /** Links */
    linksForColumn2?: Maybe<Array<Link>>;
    status: Scalars["Boolean"]["output"];
    /** Text */
    textForColumn1: Text;
    /** Text */
    textForColumn2: Text;
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/** Entity type paragraph. */
export type ParagraphUnion =
  | ParagraphCallToAction
  | ParagraphChatbotSuggestions
  | ParagraphFaqBlock
  | ParagraphFaqItem
  | ParagraphFaqOverview
  | ParagraphFindJobs
  | ParagraphFiveBlocks
  | ParagraphHtml
  | ParagraphImage
  | ParagraphJobsList
  | ParagraphMediaText
  | ParagraphNewsletterForm
  | ParagraphQuote
  | ParagraphRecentBlogs
  | ParagraphStatistic
  | ParagraphTeasers
  | ParagraphTestimonials
  | ParagraphText
  | ParagraphThreeColumnBlock
  | ParagraphThreeImages
  | ParagraphTwoColumnBlock
  | ParagraphTwoTextColumns
  | ParagraphUspBlock
  | ParagraphVideo;

/** Entity type paragraph. */
export type ParagraphUspBlock = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphUspBlock";
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    status: Scalars["Boolean"]["output"];
    /** USP Items */
    uspItems?: Maybe<Array<ParagraphUnion>>;
  };

/** Voeg een video toe aan een pagina */
export type ParagraphVideo = EntityTypeBehaviorsInterface &
  ParagraphInterface & {
    __typename?: "ParagraphVideo";
    /** Caption */
    caption?: Maybe<Text>;
    /** Behaviors for this paragraph. */
    entity_type_behaviors: EntityTypeBehaviors;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** Selecteer een video uit de video bibliotheek */
    paragraphVideoMedia: MediaVideoUnion;
    status: Scalars["Boolean"]["output"];
    /** Title */
    title?: Maybe<Scalars["String"]["output"]>;
  };

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type Query = {
  __typename?: "Query";
  /** Get the image banner by name. */
  bannerImageByName?: Maybe<ImageBannerByName>;
  /** Query for view blog_category_overview display graphql_1. */
  blogCategoryOverview?: Maybe<BlogCategoryOverviewResult>;
  /** Query for view blog_overview display graphql_1. */
  blogOverviewGraphql?: Maybe<BlogOverviewGraphqlResult>;
  candidate?: Maybe<Candidate>;
  candidateApplications?: Maybe<Array<Maybe<CandidateApplication>>>;
  candidateAppointments?: Maybe<Array<Maybe<CandidateAppointment>>>;
  candidateAvailability?: Maybe<CandidateAvailability>;
  candidateContracts?: Maybe<Array<Maybe<CandidateContract>>>;
  candidateDocument?: Maybe<Document>;
  candidateDocuments?: Maybe<Array<Maybe<CandidateDocument>>>;
  /**  This query return candidate by user_key, including inactive and incomplete candidates  */
  candidateIntention?: Maybe<CandidateIntention>;
  candidateSavedJobs?: Maybe<Scalars["String"]["output"]>;
  candidateSearchPreferences?: Maybe<CandidateSearchPreference>;
  candidateStatus?: Maybe<CandidateStatus>;
  faqCategory?: Maybe<FaqCategoryResult>;
  /** Query for view faq_search display graphql_1. */
  faqSearch?: Maybe<FaqSearchResult>;
  /** Schema information. */
  info: SchemaInformation;
  job?: Maybe<Job>;
  jobOptionLists?: Maybe<JobOptionLists>;
  jobsSearch?: Maybe<JobSearch>;
  /** Load a Route by path. */
  menu?: Maybe<Menu>;
  /** Load a NodeBlog entity by id */
  nodeBlog?: Maybe<NodeBlog>;
  /** Load a NodeBlogOverview entity by id */
  nodeBlogOverview?: Maybe<NodeBlogOverview>;
  /** List of all NodeBlog on the platform. */
  nodeBlogs: NodeBlogConnection;
  /** Fetch data for a specific NodePage */
  nodePage?: Maybe<NodePage>;
  /** List of all NodePage on the platform. */
  nodePages?: Maybe<NodePageConnection>;
  office?: Maybe<Office>;
  offices?: Maybe<Array<Maybe<Office>>>;
  /** Query for view recent_blogs display graphql_1. */
  recentBlogsOverview?: Maybe<RecentBlogsOverviewResult>;
  /** Load a Route by path. */
  route?: Maybe<RouteUnion>;
  userByEmail?: Maybe<User>;
  userBySocials?: Maybe<User>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryBannerImageByNameArgs = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryBlogCategoryOverviewArgs = {
  filter?: InputMaybe<BlogCategoryOverviewFilterInput>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryBlogOverviewGraphqlArgs = {
  filter?: InputMaybe<BlogOverviewGraphqlFilterInput>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateApplicationsArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateAppointmentsArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateAvailabilityArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateContractsArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateDocumentArgs = {
  document_type: Scalars["String"]["input"];
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateDocumentsArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateIntentionArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateSavedJobsArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateSearchPreferencesArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryCandidateStatusArgs = {
  user_key: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryFaqCategoryArgs = {
  filter?: InputMaybe<FaqCategoryFilterInput>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryFaqSearchArgs = {
  filter?: InputMaybe<FaqSearchFilterInput>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryJobArgs = {
  id: Scalars["String"]["input"];
  langcode: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryJobOptionListsArgs = {
  langcode: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryJobsSearchArgs = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  contractTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  functionGroups?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  keyword?: InputMaybe<Scalars["String"]["input"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lat?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  lng?: InputMaybe<Scalars["String"]["input"]>;
  max_distance?: InputMaybe<Scalars["String"]["input"]>;
  niches?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  officeId?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  regimes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  relatedJobId?: InputMaybe<Scalars["String"]["input"]>;
  sectors?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  studentJob?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryMenuArgs = {
  langcode?: InputMaybe<Scalars["String"]["input"]>;
  name: MenuAvailable;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryNodeBlogArgs = {
  id: Scalars["ID"]["input"];
  langcode?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryNodeBlogOverviewArgs = {
  id: Scalars["ID"]["input"];
  langcode?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryNodeBlogsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  langcode?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryNodePageArgs = {
  id: Scalars["ID"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryNodePagesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  langcode?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryOfficeArgs = {
  id: Scalars["String"]["input"];
  langcode: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryOfficesArgs = {
  langcode: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryRecentBlogsOverviewArgs = {
  filter?: InputMaybe<RecentBlogsOverviewFilterInput>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryRouteArgs = {
  langcode?: InputMaybe<Scalars["String"]["input"]>;
  path: Scalars["String"]["input"];
  secret?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

/**
 * The schema's entry-point for queries.
 *
 * This acts as the public, top-level API from which all queries must start.
 */
export type QueryUserBySocialsArgs = {
  salesforce_id?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecentBlogsOverviewFilterInput = {
  /** Doelgroep (field_target_audience_ref)  */
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
  /** Translation language  */
  langcode: Scalars["String"]["input"];
};

/** Result for view recent_blogs display graphql_1. */
export type RecentBlogsOverviewResult = View & {
  __typename?: "RecentBlogsOverviewResult";
  /** De beschrijving van het overzicht. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The results of the view. */
  results: Array<NodeUnion>;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

/** Get related Faqs */
export type RelatedFaqs = {
  __typename?: "RelatedFaqs";
  language?: Maybe<Scalars["String"]["output"]>;
  path?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Response = {
  __typename?: "Response";
  status?: Maybe<Scalars["Int"]["output"]>;
};

/** Routes represent incoming requests that resolve to content. */
export type Route = {
  /** Whether this route is internal or external. */
  internal: Scalars["Boolean"]["output"];
  /** URL of this route. */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** A list of possible entities that can be returned by URL. */
export type RouteEntityUnion =
  | NodeBlog
  | NodeBlogOverview
  | NodeFaq
  | NodeFaqCategory
  | NodeFaqCategoryOverview
  | NodeFaqOverview
  | NodePage;

/** Route outside of this website. */
export type RouteExternal = Route & {
  __typename?: "RouteExternal";
  /** Whether this route is internal or external. */
  internal: Scalars["Boolean"]["output"];
  /** URL of this route. */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** Route within this website. */
export type RouteInternal = Route & {
  __typename?: "RouteInternal";
  /** Breadcrumb links for this route. */
  breadcrumbs?: Maybe<Array<Link>>;
  /** Content assigned to this route. */
  entity?: Maybe<RouteEntityUnion>;
  /** Whether this route is internal or external. */
  internal: Scalars["Boolean"]["output"];
  /** URL of this route. */
  url?: Maybe<Scalars["String"]["output"]>;
};

/** Redirect to another URL with status. */
export type RouteRedirect = Route & {
  __typename?: "RouteRedirect";
  /** Whether this route is internal or external. */
  internal: Scalars["Boolean"]["output"];
  /** Utility prop. Always true for redirects. */
  redirect: Scalars["Boolean"]["output"];
  /** Suggested status for redirect. Eg 301. */
  status: Scalars["Int"]["output"];
  /** URL of this route. */
  url: Scalars["String"]["output"];
};

/** Route types that can exist in the system. */
export type RouteUnion = Error | RouteExternal | RouteInternal | RouteRedirect;

/** Schema information provided by the system. */
export type SchemaInformation = {
  __typename?: "SchemaInformation";
  /** The schema description. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The internal path to the front page. */
  home?: Maybe<Scalars["String"]["output"]>;
  /** The schema version. */
  version?: Maybe<Scalars["String"]["output"]>;
};

/** Sort direction. */
export enum SortDirection {
  /** Ascending */
  Asc = "ASC",
  /** Descending */
  Desc = "DESC",
}

export type Staff = {
  __typename?: "Staff";
  first_name: Scalars["String"]["output"];
  photo_last_changed?: Maybe<Scalars["String"]["output"]>;
  photo_url?: Maybe<Scalars["String"]["output"]>;
  staff_key: Scalars["String"]["output"];
};

/** Entity type taxonomy_term. */
export type TermBlogCategories = TermInterface & {
  __typename?: "TermBlogCategories";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Naam */
  name: Scalars["String"]["output"];
  /** The term ID. */
  tid: Scalars["Int"]["output"];
};

/** Entity type taxonomy_term. */
export type TermInterface = {
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
};

/** Entity type taxonomy_term. */
export type TermMediaTags = MetaTagInterface &
  TermInterface & {
    __typename?: "TermMediaTags";
    /** The time that the term was last edited. */
    changed: DateTime;
    /** Description */
    description: Text;
    /** The Universally Unique IDentifier (UUID). */
    id: Scalars["ID"]["output"];
    /** The term language code. */
    langcode: Language;
    /** The computed meta tags for the entity. */
    metatag: Array<MetaTagUnion>;
    /** Name */
    name: Scalars["String"]["output"];
    /** The parents of this term. */
    parent?: Maybe<TermUnion>;
    /** URL alias */
    path: Scalars["String"]["output"];
    /** Published */
    status: Scalars["Boolean"]["output"];
    /** The weight of this term in relation to other terms. */
    weight: Scalars["Int"]["output"];
  };

/** Entity type taxonomy_term. */
export type TermTargetAudience = TermInterface & {
  __typename?: "TermTargetAudience";
  /** The Universally Unique IDentifier (UUID). */
  id: Scalars["ID"]["output"];
  /** Name */
  name: Scalars["String"]["output"];
  /** The term ID. */
  tid: Scalars["Int"]["output"];
};

/** Entity type taxonomy_term. */
export type TermUnion = TermBlogCategories | TermMediaTags | TermTargetAudience;

/** A processed text format defined by the CMS. */
export type Text = {
  __typename?: "Text";
  /** The processed text value. */
  processed?: Maybe<Scalars["Html"]["output"]>;
};

/** A processed text format with summary defined by the CMS. */
export type TextSummary = {
  __typename?: "TextSummary";
  /** The text format used to process the text value. */
  format?: Maybe<Scalars["String"]["output"]>;
  /** The processed text value. */
  processed?: Maybe<Scalars["Html"]["output"]>;
  /** The processed text summary. */
  summary?: Maybe<Scalars["Html"]["output"]>;
  /** The raw text value. */
  value?: Maybe<Scalars["String"]["output"]>;
};

export type Translation = {
  __typename?: "Translation";
  context: Scalars["String"]["output"];
  language?: Maybe<Scalars["String"]["output"]>;
  source: Scalars["String"]["output"];
  target: Scalars["String"]["output"];
};

export type TranslationConnection = {
  __typename?: "TranslationConnection";
  items?: Maybe<Array<Translation>>;
  total: Scalars["Int"]["output"];
};

/**
 * Unsupported entity or field type in the schema.
 * This entity may not have been enabled in the schema yet and is being referenced via entity reference.
 */
export type UnsupportedType = {
  __typename?: "UnsupportedType";
  /** Unsupported type, always TRUE. */
  unsupported?: Maybe<Scalars["Boolean"]["output"]>;
};

export type User = {
  __typename?: "User";
  approved: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  first_name: Scalars["String"]["output"];
  language: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  password?: Maybe<Scalars["String"]["output"]>;
  user_key: Scalars["String"]["output"];
};

export type UserActivateResponse = {
  __typename?: "UserActivateResponse";
  description: Scalars["String"]["output"];
  status: Scalars["Int"]["output"];
};

export type UserResetPassword = {
  __typename?: "UserResetPassword";
  description: Scalars["String"]["output"];
  status: Scalars["Int"]["output"];
};

export type UserResponse = {
  __typename?: "UserResponse";
  status: Scalars["Int"]["output"];
  user_key?: Maybe<Scalars["String"]["output"]>;
};

export type UserTokenResponse = {
  __typename?: "UserTokenResponse";
  description?: Maybe<Scalars["String"]["output"]>;
};

/** Views represent collections of curated data from the site. */
export type View = {
  /** De beschrijving van het overzicht. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The machine name of the display. */
  display: Scalars["String"]["output"];
  /** The ID of the view. */
  id: Scalars["ID"]["output"];
  /** The human friendly label of the view. */
  label?: Maybe<Scalars["String"]["output"]>;
  /** The language code of the view. */
  langcode?: Maybe<Scalars["String"]["output"]>;
  /** Information about the page in the view. */
  pageInfo: ViewPageInfo;
  /** The machine name of the view. */
  view: Scalars["String"]["output"];
};

/** Information about the page in a view. */
export type ViewPageInfo = {
  __typename?: "ViewPageInfo";
  /** Any result offset being used. */
  offset: Scalars["Int"]["output"];
  /** The current page being returned. */
  page: Scalars["Int"]["output"];
  /** How many results per page. */
  pageSize: Scalars["Int"]["output"];
  /** How many results total. */
  total: Scalars["Int"]["output"];
};

/** A reference to an embedded view */
export type ViewReference = {
  __typename?: "ViewReference";
  contextualFilter?: Maybe<Array<Scalars["String"]["output"]>>;
  display: Scalars["String"]["output"];
  pageSize?: Maybe<Scalars["Int"]["output"]>;
  /** The name of the query used to fetch the data, if the view is a GraphQL display. */
  query?: Maybe<Scalars["String"]["output"]>;
  view: Scalars["String"]["output"];
};

/** All available view result types. */
export type ViewResultUnion = BlogOverviewGraphqlResult;

export type WorkExperience = {
  __typename?: "WorkExperience";
  company?: Maybe<Scalars["String"]["output"]>;
  end_date?: Maybe<Scalars["String"]["output"]>;
  job_type?: Maybe<Scalars["String"]["output"]>;
  start_date?: Maybe<Scalars["String"]["output"]>;
};

export type WorkExperienceInput = {
  company?: InputMaybe<Scalars["String"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  job_type?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParagraphMediaTextMediaUnion =
  | MediaImage
  | MediaRemoteVideo
  | MediaVideo;

export type BlogPageFieldsFragment = {
  __typename: "NodeBlog";
  id: string;
  title: string;
  author?: string | null | undefined;
  path: string;
  blogCategoryRef?:
    | Array<
        | {
            __typename?: "TermBlogCategories";
            id: string;
            name: string;
            tid: number;
          }
        | { __typename?: "TermMediaTags" }
        | { __typename?: "TermTargetAudience" }
      >
    | null
    | undefined;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
  mainMedia?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  teaserMedia: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
  intro?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  relatedBlogs: Array<
    | {
        __typename?: "NodeBlog";
        id: string;
        title: string;
        author?: string | null | undefined;
        path: string;
        blogCategoryRef?:
          | Array<
              | {
                  __typename?: "TermBlogCategories";
                  id: string;
                  name: string;
                  tid: number;
                }
              | { __typename?: "TermMediaTags" }
              | { __typename?: "TermTargetAudience" }
            >
          | null
          | undefined;
        intro?:
          | { __typename?: "Text"; processed?: any | null | undefined }
          | null
          | undefined;
        teaserMedia: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
        changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
        created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
      }
    | null
    | undefined
  >;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  content?:
    | Array<
        | ({
            __typename?: "ParagraphCallToAction";
          } & CallToActionParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphChatbotSuggestions";
          } & ChatbotSuggestionsParagraphFieldsFragment)
        | { __typename?: "ParagraphFaqBlock" }
        | { __typename?: "ParagraphFaqItem" }
        | { __typename?: "ParagraphFaqOverview" }
        | { __typename?: "ParagraphFindJobs" }
        | { __typename?: "ParagraphFiveBlocks" }
        | { __typename?: "ParagraphHtml" }
        | ({ __typename?: "ParagraphImage" } & ImageParagraphFieldsFragment)
        | { __typename?: "ParagraphJobsList" }
        | ({
            __typename?: "ParagraphMediaText";
          } & MediaTextParagraphFieldsFragment)
        | { __typename?: "ParagraphNewsletterForm" }
        | ({ __typename?: "ParagraphQuote" } & QuoteParagraphFieldsFragment)
        | { __typename?: "ParagraphRecentBlogs" }
        | { __typename?: "ParagraphStatistic" }
        | { __typename?: "ParagraphTeasers" }
        | { __typename?: "ParagraphTestimonials" }
        | ({ __typename?: "ParagraphText" } & TextParagraphFieldsFragment)
        | { __typename?: "ParagraphThreeColumnBlock" }
        | { __typename?: "ParagraphThreeImages" }
        | { __typename?: "ParagraphTwoColumnBlock" }
        | { __typename?: "ParagraphTwoTextColumns" }
        | { __typename?: "ParagraphUspBlock" }
        | ({ __typename?: "ParagraphVideo" } & VideoParagraphFieldsFragment)
      >
    | null
    | undefined;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
};

export type BlogOverviewPageFieldsFragment = {
  __typename: "NodeBlogOverview";
  id: string;
  title: string;
  path: string;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
};

export type FaqPageFieldsFragment = {
  __typename: "NodeFaq";
  id: string;
  title: string;
  faqCategory?:
    | { __typename?: "NodeBlog" }
    | { __typename?: "NodeBlogOverview" }
    | { __typename?: "NodeFaq" }
    | ({ __typename?: "NodeFaqCategory" } & FaqCategoryMinimalFieldsFragment)
    | { __typename?: "NodeFaqCategoryOverview" }
    | { __typename?: "NodeFaqOverview" }
    | { __typename?: "NodePage" }
    | null
    | undefined;
  mainMedia?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  relatedFaqs: Array<
    | {
        __typename?: "RelatedFaqs";
        title?: string | null | undefined;
        path?: string | null | undefined;
        language?: string | null | undefined;
      }
    | null
    | undefined
  >;
  content?:
    | Array<
        | ({
            __typename?: "ParagraphCallToAction";
          } & CallToActionParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphChatbotSuggestions";
          } & ChatbotSuggestionsParagraphFieldsFragment)
        | { __typename?: "ParagraphFaqBlock" }
        | { __typename?: "ParagraphFaqItem" }
        | { __typename?: "ParagraphFaqOverview" }
        | { __typename?: "ParagraphFindJobs" }
        | { __typename?: "ParagraphFiveBlocks" }
        | { __typename?: "ParagraphHtml" }
        | ({ __typename?: "ParagraphImage" } & ImageParagraphFieldsFragment)
        | { __typename?: "ParagraphJobsList" }
        | ({
            __typename?: "ParagraphMediaText";
          } & MediaTextParagraphFieldsFragment)
        | { __typename?: "ParagraphNewsletterForm" }
        | ({ __typename?: "ParagraphQuote" } & QuoteParagraphFieldsFragment)
        | { __typename?: "ParagraphRecentBlogs" }
        | { __typename?: "ParagraphStatistic" }
        | { __typename?: "ParagraphTeasers" }
        | { __typename?: "ParagraphTestimonials" }
        | ({ __typename?: "ParagraphText" } & TextParagraphFieldsFragment)
        | { __typename?: "ParagraphThreeColumnBlock" }
        | { __typename?: "ParagraphThreeImages" }
        | { __typename?: "ParagraphTwoColumnBlock" }
        | { __typename?: "ParagraphTwoTextColumns" }
        | { __typename?: "ParagraphUspBlock" }
        | ({ __typename?: "ParagraphVideo" } & VideoParagraphFieldsFragment)
      >
    | null
    | undefined;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
};

export type FaqCategoryPageFieldsFragment = {
  __typename: "NodeFaqCategory";
  id: string;
  title: string;
  icon?: string | null | undefined;
  description?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        tid: number;
        name: string;
        id: string;
      }
    | null
    | undefined;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
};

export type FaqCategoryMinimalFieldsFragment = {
  __typename: "NodeFaqCategory";
  id: string;
  title: string;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
};

export type FaqOverviewPageFieldsFragment = {
  __typename: "NodeFaqOverview";
  id: string;
  title: string;
  path: string;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
};

export type FaqCategoryOverviewPageFieldsFragment = {
  __typename: "NodeFaqCategoryOverview";
  id: string;
  title: string;
  path: string;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
  changed: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  created: { __typename?: "DateTime" } & DateTimeFieldsFragment;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | { __typename?: "MediaDocument" }
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | { __typename?: "MediaRemoteVideo" }
    | { __typename?: "MediaSvgImage" }
    | { __typename?: "MediaVideo" }
    | null
    | undefined;
};

export type MediaDocumentFieldsFragment = {
  __typename?: "MediaDocument";
  id: string;
  name: string;
  path: string;
  status: boolean;
  mediaFile: {
    __typename?: "File";
    name?: string | null | undefined;
    url: string;
    size: number;
    mime?: string | null | undefined;
    description?: string | null | undefined;
  };
  mediaTags?:
    | Array<
        | { __typename?: "TermBlogCategories" }
        | {
            __typename?: "TermMediaTags";
            id: string;
            name: string;
            path: string;
            status: boolean;
            weight: number;
            description: {
              __typename?: "Text";
              processed?: any | null | undefined;
            };
          }
        | { __typename?: "TermTargetAudience" }
      >
    | null
    | undefined;
};

export type ImageFieldsFragment = {
  __typename?: "Image";
  alt: string;
  title?: string | null | undefined;
  variations?:
    | Array<{
        __typename?: "ImageStyleDerivative";
        name: string;
        url: string;
        width: number;
        height: number;
      }>
    | null
    | undefined;
};

export type MediaImageFieldsFragment = {
  __typename?: "MediaImage";
  imageMediaImage?:
    | ({ __typename?: "Image" } & ImageFieldsFragment)
    | null
    | undefined;
};

export type MediaRemoteVideoFieldsFragment = {
  __typename?: "MediaRemoteVideo";
  id: string;
  mediaOembedVideo: string;
  remoteVideoMediaImage?:
    | ({ __typename?: "Image" } & ImageFieldsFragment)
    | null
    | undefined;
};

export type MediaSvgImageFieldsFragment = {
  __typename?: "MediaSvgImage";
  id: string;
  svgMediaImage: { __typename?: "Image" } & ImageFieldsFragment;
};

export type MediaVideoFieldsFragment = {
  __typename?: "MediaVideo";
  id: string;
  mediaVideoFile: {
    __typename?: "File";
    name?: string | null | undefined;
    url: string;
    size: number;
    mime?: string | null | undefined;
    description?: string | null | undefined;
  };
};

export type MenuFieldsFragment = {
  __typename?: "Menu";
  id: string;
  name: string;
  items?:
    | Array<{
        __typename?: "MenuItem";
        title: string;
        url: string;
        internal?: boolean | null | undefined;
        id: string;
        extras?:
          | { __typename: "MenuLinkContentExpertisesMenu" }
          | { __typename: "MenuLinkContentForEmployeesMenu" }
          | { __typename: "MenuLinkContentForEmployersMenu" }
          | {
              __typename: "MenuLinkContentJobsPerRegionMenu";
              suffix?: string | null | undefined;
            }
          | {
              __typename: "MenuLinkContentJobsPerSectorMenu";
              suffix?: string | null | undefined;
            }
          | { __typename: "MenuLinkContentLegal" }
          | { __typename: "MenuLinkContentMainMenuEmployee" }
          | { __typename: "MenuLinkContentMainMenuEmployer" }
          | { __typename: "MenuLinkContentMoreAboutVivaldis" }
          | {
              __typename: "MenuLinkContentSecondaryMenuEmployee";
              iconSelect?: string | null | undefined;
            }
          | {
              __typename: "MenuLinkContentSecondaryMenuEmployer";
              iconSelect?: string | null | undefined;
            }
          | { __typename: "MenuLinkContentServicesMenu" }
          | { __typename: "MenuLinkContentToolsMenu" }
          | null
          | undefined;
        children?:
          | Array<{
              __typename?: "MenuItem";
              id: string;
              title: string;
              url: string;
              internal?: boolean | null | undefined;
              extras?:
                | { __typename: "MenuLinkContentExpertisesMenu" }
                | { __typename: "MenuLinkContentForEmployeesMenu" }
                | { __typename: "MenuLinkContentForEmployersMenu" }
                | {
                    __typename: "MenuLinkContentJobsPerRegionMenu";
                    suffix?: string | null | undefined;
                  }
                | {
                    __typename: "MenuLinkContentJobsPerSectorMenu";
                    suffix?: string | null | undefined;
                  }
                | { __typename: "MenuLinkContentLegal" }
                | { __typename: "MenuLinkContentMainMenuEmployee" }
                | { __typename: "MenuLinkContentMainMenuEmployer" }
                | { __typename: "MenuLinkContentMoreAboutVivaldis" }
                | {
                    __typename: "MenuLinkContentSecondaryMenuEmployee";
                    iconSelect?: string | null | undefined;
                  }
                | {
                    __typename: "MenuLinkContentSecondaryMenuEmployer";
                    iconSelect?: string | null | undefined;
                  }
                | { __typename: "MenuLinkContentServicesMenu" }
                | { __typename: "MenuLinkContentToolsMenu" }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type LinkFieldsFragment = {
  __typename?: "Link";
  title?: string | null | undefined;
  url?: string | null | undefined;
  internal: boolean;
};

export type DateTimeFieldsFragment = {
  __typename?: "DateTime";
  timestamp: any;
  timezone: any;
  offset: any;
  time: any;
};

export type JobOptionFragment = {
  __typename?: "JobOption";
  key: string;
  translation: string;
};

export type StandardPageFieldsFragment = {
  __typename: "NodePage";
  id: string;
  title: string;
  mainMedia?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  intro?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  availableLanguages?:
    | Array<
        | {
            __typename?: "AvailableLanguages";
            language?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  content?:
    | Array<
        | ({
            __typename?: "ParagraphCallToAction";
          } & CallToActionParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphChatbotSuggestions";
          } & ChatbotSuggestionsParagraphFieldsFragment)
        | { __typename?: "ParagraphFaqBlock" }
        | { __typename?: "ParagraphFaqItem" }
        | ({
            __typename?: "ParagraphFaqOverview";
          } & FaqOverviewParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphFindJobs";
          } & FindJobsParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphFiveBlocks";
          } & FiveBlocksParagraphFieldsFragment)
        | ({ __typename?: "ParagraphHtml" } & HtmlParagraphFieldsFragment)
        | ({ __typename?: "ParagraphImage" } & ImageParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphJobsList";
          } & JobsListParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphMediaText";
          } & MediaTextParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphNewsletterForm";
          } & NewsletterFormParagraphFieldsFragment)
        | ({ __typename?: "ParagraphQuote" } & QuoteParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphRecentBlogs";
          } & RecentBlogsParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphStatistic";
          } & StatisticParagraphFieldsFragment)
        | ({ __typename?: "ParagraphTeasers" } & TeaserParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphTestimonials";
          } & TestimonialsParagraphFieldsFragment)
        | ({ __typename?: "ParagraphText" } & TextParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphThreeColumnBlock";
          } & ThreeColumnBlockParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphThreeImages";
          } & ThreeImagesParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphTwoColumnBlock";
          } & TwoColumnBlockParagraphFieldsFragment)
        | ({
            __typename?: "ParagraphTwoTextColumns";
          } & TwoTextColumnsParagraphFieldsFragment)
        | { __typename?: "ParagraphUspBlock" }
        | ({ __typename?: "ParagraphVideo" } & VideoParagraphFieldsFragment)
      >
    | null
    | undefined;
  metatag: Array<
    | {
        __typename?: "MetaTagLink";
        tag: string;
        attributes: {
          __typename?: "MetaTagLinkAttributes";
          rel?: string | null | undefined;
          href?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagProperty";
        tag: string;
        attributes: {
          __typename?: "MetaTagPropertyAttributes";
          property?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
    | {
        __typename?: "MetaTagValue";
        tag: string;
        attributes: {
          __typename?: "MetaTagValueAttributes";
          name?: string | null | undefined;
          content?: string | null | undefined;
        };
      }
  >;
  ogImage?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  targetAudienceRef?:
    | {
        __typename?: "TermTargetAudience";
        id: string;
        name: string;
        tid: number;
      }
    | null
    | undefined;
};

type ParagraphFields_ParagraphCallToAction_Fragment = {
  __typename: "ParagraphCallToAction";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphChatbotSuggestions_Fragment = {
  __typename: "ParagraphChatbotSuggestions";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphCustomTeaser_Fragment = {
  __typename: "ParagraphCustomTeaser";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphFaqBlock_Fragment = {
  __typename: "ParagraphFaqBlock";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphFaqItem_Fragment = {
  __typename: "ParagraphFaqItem";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphFaqOverview_Fragment = {
  __typename: "ParagraphFaqOverview";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphFindJobs_Fragment = {
  __typename: "ParagraphFindJobs";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphFiveBlocks_Fragment = {
  __typename: "ParagraphFiveBlocks";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphHtml_Fragment = {
  __typename: "ParagraphHtml";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphImage_Fragment = {
  __typename: "ParagraphImage";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphJobsList_Fragment = {
  __typename: "ParagraphJobsList";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphMediaText_Fragment = {
  __typename: "ParagraphMediaText";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphNewsletterForm_Fragment = {
  __typename: "ParagraphNewsletterForm";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphQuote_Fragment = {
  __typename: "ParagraphQuote";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphRecentBlogs_Fragment = {
  __typename: "ParagraphRecentBlogs";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphReferenceTeaser_Fragment = {
  __typename: "ParagraphReferenceTeaser";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphStatistic_Fragment = {
  __typename: "ParagraphStatistic";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphStatisticItem_Fragment = {
  __typename: "ParagraphStatisticItem";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphTeasers_Fragment = {
  __typename: "ParagraphTeasers";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphTestimonials_Fragment = {
  __typename: "ParagraphTestimonials";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphText_Fragment = {
  __typename: "ParagraphText";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphThreeColumnBlock_Fragment = {
  __typename: "ParagraphThreeColumnBlock";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphThreeImages_Fragment = {
  __typename: "ParagraphThreeImages";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphTwoColumnBlock_Fragment = {
  __typename: "ParagraphTwoColumnBlock";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphTwoTextColumns_Fragment = {
  __typename: "ParagraphTwoTextColumns";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphUspBlock_Fragment = {
  __typename: "ParagraphUspBlock";
  id: string;
  status: boolean;
};

type ParagraphFields_ParagraphVideo_Fragment = {
  __typename: "ParagraphVideo";
  id: string;
  status: boolean;
};

export type ParagraphFieldsFragment =
  | ParagraphFields_ParagraphCallToAction_Fragment
  | ParagraphFields_ParagraphChatbotSuggestions_Fragment
  | ParagraphFields_ParagraphCustomTeaser_Fragment
  | ParagraphFields_ParagraphFaqBlock_Fragment
  | ParagraphFields_ParagraphFaqItem_Fragment
  | ParagraphFields_ParagraphFaqOverview_Fragment
  | ParagraphFields_ParagraphFindJobs_Fragment
  | ParagraphFields_ParagraphFiveBlocks_Fragment
  | ParagraphFields_ParagraphHtml_Fragment
  | ParagraphFields_ParagraphImage_Fragment
  | ParagraphFields_ParagraphJobsList_Fragment
  | ParagraphFields_ParagraphMediaText_Fragment
  | ParagraphFields_ParagraphNewsletterForm_Fragment
  | ParagraphFields_ParagraphQuote_Fragment
  | ParagraphFields_ParagraphRecentBlogs_Fragment
  | ParagraphFields_ParagraphReferenceTeaser_Fragment
  | ParagraphFields_ParagraphStatistic_Fragment
  | ParagraphFields_ParagraphStatisticItem_Fragment
  | ParagraphFields_ParagraphTeasers_Fragment
  | ParagraphFields_ParagraphTestimonials_Fragment
  | ParagraphFields_ParagraphText_Fragment
  | ParagraphFields_ParagraphThreeColumnBlock_Fragment
  | ParagraphFields_ParagraphThreeImages_Fragment
  | ParagraphFields_ParagraphTwoColumnBlock_Fragment
  | ParagraphFields_ParagraphTwoTextColumns_Fragment
  | ParagraphFields_ParagraphUspBlock_Fragment
  | ParagraphFields_ParagraphVideo_Fragment;

type SubParagraphFields_ParagraphCallToAction_Fragment = {
  __typename: "ParagraphCallToAction";
  id: string;
};

type SubParagraphFields_ParagraphChatbotSuggestions_Fragment = {
  __typename: "ParagraphChatbotSuggestions";
  id: string;
};

type SubParagraphFields_ParagraphCustomTeaser_Fragment = {
  __typename: "ParagraphCustomTeaser";
  id: string;
};

type SubParagraphFields_ParagraphFaqBlock_Fragment = {
  __typename: "ParagraphFaqBlock";
  id: string;
};

type SubParagraphFields_ParagraphFaqItem_Fragment = {
  __typename: "ParagraphFaqItem";
  id: string;
};

type SubParagraphFields_ParagraphFaqOverview_Fragment = {
  __typename: "ParagraphFaqOverview";
  id: string;
};

type SubParagraphFields_ParagraphFindJobs_Fragment = {
  __typename: "ParagraphFindJobs";
  id: string;
};

type SubParagraphFields_ParagraphFiveBlocks_Fragment = {
  __typename: "ParagraphFiveBlocks";
  id: string;
};

type SubParagraphFields_ParagraphHtml_Fragment = {
  __typename: "ParagraphHtml";
  id: string;
};

type SubParagraphFields_ParagraphImage_Fragment = {
  __typename: "ParagraphImage";
  id: string;
};

type SubParagraphFields_ParagraphJobsList_Fragment = {
  __typename: "ParagraphJobsList";
  id: string;
};

type SubParagraphFields_ParagraphMediaText_Fragment = {
  __typename: "ParagraphMediaText";
  id: string;
};

type SubParagraphFields_ParagraphNewsletterForm_Fragment = {
  __typename: "ParagraphNewsletterForm";
  id: string;
};

type SubParagraphFields_ParagraphQuote_Fragment = {
  __typename: "ParagraphQuote";
  id: string;
};

type SubParagraphFields_ParagraphRecentBlogs_Fragment = {
  __typename: "ParagraphRecentBlogs";
  id: string;
};

type SubParagraphFields_ParagraphReferenceTeaser_Fragment = {
  __typename: "ParagraphReferenceTeaser";
  id: string;
};

type SubParagraphFields_ParagraphStatistic_Fragment = {
  __typename: "ParagraphStatistic";
  id: string;
};

type SubParagraphFields_ParagraphStatisticItem_Fragment = {
  __typename: "ParagraphStatisticItem";
  id: string;
};

type SubParagraphFields_ParagraphTeasers_Fragment = {
  __typename: "ParagraphTeasers";
  id: string;
};

type SubParagraphFields_ParagraphTestimonials_Fragment = {
  __typename: "ParagraphTestimonials";
  id: string;
};

type SubParagraphFields_ParagraphText_Fragment = {
  __typename: "ParagraphText";
  id: string;
};

type SubParagraphFields_ParagraphThreeColumnBlock_Fragment = {
  __typename: "ParagraphThreeColumnBlock";
  id: string;
};

type SubParagraphFields_ParagraphThreeImages_Fragment = {
  __typename: "ParagraphThreeImages";
  id: string;
};

type SubParagraphFields_ParagraphTwoColumnBlock_Fragment = {
  __typename: "ParagraphTwoColumnBlock";
  id: string;
};

type SubParagraphFields_ParagraphTwoTextColumns_Fragment = {
  __typename: "ParagraphTwoTextColumns";
  id: string;
};

type SubParagraphFields_ParagraphUspBlock_Fragment = {
  __typename: "ParagraphUspBlock";
  id: string;
};

type SubParagraphFields_ParagraphVideo_Fragment = {
  __typename: "ParagraphVideo";
  id: string;
};

export type SubParagraphFieldsFragment =
  | SubParagraphFields_ParagraphCallToAction_Fragment
  | SubParagraphFields_ParagraphChatbotSuggestions_Fragment
  | SubParagraphFields_ParagraphCustomTeaser_Fragment
  | SubParagraphFields_ParagraphFaqBlock_Fragment
  | SubParagraphFields_ParagraphFaqItem_Fragment
  | SubParagraphFields_ParagraphFaqOverview_Fragment
  | SubParagraphFields_ParagraphFindJobs_Fragment
  | SubParagraphFields_ParagraphFiveBlocks_Fragment
  | SubParagraphFields_ParagraphHtml_Fragment
  | SubParagraphFields_ParagraphImage_Fragment
  | SubParagraphFields_ParagraphJobsList_Fragment
  | SubParagraphFields_ParagraphMediaText_Fragment
  | SubParagraphFields_ParagraphNewsletterForm_Fragment
  | SubParagraphFields_ParagraphQuote_Fragment
  | SubParagraphFields_ParagraphRecentBlogs_Fragment
  | SubParagraphFields_ParagraphReferenceTeaser_Fragment
  | SubParagraphFields_ParagraphStatistic_Fragment
  | SubParagraphFields_ParagraphStatisticItem_Fragment
  | SubParagraphFields_ParagraphTeasers_Fragment
  | SubParagraphFields_ParagraphTestimonials_Fragment
  | SubParagraphFields_ParagraphText_Fragment
  | SubParagraphFields_ParagraphThreeColumnBlock_Fragment
  | SubParagraphFields_ParagraphThreeImages_Fragment
  | SubParagraphFields_ParagraphTwoColumnBlock_Fragment
  | SubParagraphFields_ParagraphTwoTextColumns_Fragment
  | SubParagraphFields_ParagraphUspBlock_Fragment
  | SubParagraphFields_ParagraphVideo_Fragment;

export type CallToActionParagraphFieldsFragment = {
  __typename?: "ParagraphCallToAction";
  label?: string | null | undefined;
  title?: string | null | undefined;
  paragraphCallToActionText?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  paragraphCallToActionLink: { __typename?: "Link" } & LinkFieldsFragment;
  paragraphCallToActionLink2?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
    background_color?: string | null | undefined;
    box_display?: string | null | undefined;
  };
} & ParagraphFields_ParagraphCallToAction_Fragment;

export type TextParagraphFieldsFragment = {
  __typename?: "ParagraphText";
  paragraphTextText: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  paragraphTextLink?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
    background_color_full_width?: string | null | undefined;
    background_color?: string | null | undefined;
    box_display?: string | null | undefined;
  };
} & ParagraphFields_ParagraphText_Fragment;

export type ThreeColumnBlockParagraphFieldsFragment = {
  __typename?: "ParagraphThreeColumnBlock";
  label?: string | null | undefined;
  labelForBlock1?: string | null | undefined;
  labelForBlock2?: string | null | undefined;
  labelForBlock3?: string | null | undefined;
  paragraphThreeColumnBlockTitleForBlock1?: string | null | undefined;
  paragraphThreeColumnBlockTitleForBlock2?: string | null | undefined;
  paragraphThreeColumnBlockTitleForBlock3?: string | null | undefined;
  title?: string | null | undefined;
  links?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock1?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock2?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock3?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  paragraphThreeColumnBlockImageForBlock1?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  paragraphThreeColumnBlockImageForBlock2?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  paragraphThreeColumnBlockImageForBlock3?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  paragraphThreeColumnBlockTextForBlock1: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  paragraphThreeColumnBlockTextForBlock2: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  paragraphThreeColumnBlockTextForBlock3: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphThreeColumnBlock_Fragment;

export type ThreeImagesParagraphFieldsFragment = {
  __typename?: "ParagraphThreeImages";
  title?: string | null | undefined;
  image1ForThreeImages: {
    __typename?: "MediaImage";
  } & MediaImageFieldsFragment;
  image2ForThreeImages: {
    __typename?: "MediaImage";
  } & MediaImageFieldsFragment;
  image3ForThreeImages: {
    __typename?: "MediaImage";
  } & MediaImageFieldsFragment;
  linkForImageInBlock1?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  linkForImageInBlock2?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  linkForImageInBlock3?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  linksForBlock1?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock2?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock3?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphThreeImages_Fragment;

export type TwoColumnBlockParagraphFieldsFragment = {
  __typename?: "ParagraphTwoColumnBlock";
  label?: string | null | undefined;
  labelForBlock1?: string | null | undefined;
  labelForBlock2?: string | null | undefined;
  title?: string | null | undefined;
  paragraphTwoColumnBlockTitleForBlock1?: string | null | undefined;
  paragraphTwoColumnBlockTitleForBlock2?: string | null | undefined;
  paragraphTwoColumnBlockImageForBlock1: {
    __typename?: "MediaImage";
  } & MediaImageFieldsFragment;
  paragraphTwoColumnBlockImageForBlock2: {
    __typename?: "MediaImage";
  } & MediaImageFieldsFragment;
  linksForBlock1?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForBlock2?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  paragraphTwoColumnBlockTextForBlock1: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  paragraphTwoColumnBlockTextForBlock2: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphTwoColumnBlock_Fragment;

export type TwoTextColumnsParagraphFieldsFragment = {
  __typename?: "ParagraphTwoTextColumns";
  title?: string | null | undefined;
  linksForColumn1?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  linksForColumn2?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  textForColumn1: { __typename?: "Text"; processed?: any | null | undefined };
  textForColumn2: { __typename?: "Text"; processed?: any | null | undefined };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphTwoTextColumns_Fragment;

export type NewsletterFormParagraphFieldsFragment = {
  __typename?: "ParagraphNewsletterForm";
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphNewsletterForm_Fragment;

export type MediaTextParagraphFieldsFragment = {
  __typename?: "ParagraphMediaText";
  title?: string | null | undefined;
  label?: string | null | undefined;
  paragraphMediaTextLinkForMedia?:
    | ({ __typename?: "Link" } & LinkFieldsFragment)
    | null
    | undefined;
  paragraphMediaTextMedia?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | ({ __typename?: "MediaRemoteVideo" } & MediaRemoteVideoFieldsFragment)
    | ({ __typename?: "MediaVideo" } & MediaVideoFieldsFragment)
    | null
    | undefined;
  paragraphMediaTextText: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    media_position?: string | null | undefined;
    margin?: string | null | undefined;
    box_display?: string | null | undefined;
    text_width?: string | null | undefined;
  };
  links?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
} & ParagraphFields_ParagraphMediaText_Fragment;

export type ImageParagraphFieldsFragment = {
  __typename?: "ParagraphImage";
  title?: string | null | undefined;
  paragraphImageMedia?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  caption?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
    box_display?: string | null | undefined;
  };
} & ParagraphFields_ParagraphImage_Fragment;

export type VideoParagraphFieldsFragment = {
  __typename?: "ParagraphVideo";
  title?: string | null | undefined;
  paragraphVideoMedia:
    | ({ __typename?: "MediaRemoteVideo" } & MediaRemoteVideoFieldsFragment)
    | ({ __typename?: "MediaVideo" } & MediaVideoFieldsFragment);
  caption?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
    box_display?: string | null | undefined;
  };
} & ParagraphFields_ParagraphVideo_Fragment;

export type TeaserParagraphFieldsFragment = {
  __typename?: "ParagraphTeasers";
  title?: string | null | undefined;
  intro?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  paragraphTeasersLink?:
    | {
        __typename?: "Link";
        title?: string | null | undefined;
        url?: string | null | undefined;
        internal: boolean;
      }
    | null
    | undefined;
  content?:
    | Array<
        | ({
            __typename?: "ParagraphCustomTeaser";
            title?: string | null | undefined;
            paragraphCustomTeaserLink?:
              | {
                  __typename?: "Link";
                  title?: string | null | undefined;
                  url?: string | null | undefined;
                  internal: boolean;
                }
              | null
              | undefined;
            paragraphCustomTeaserMedia?:
              | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
              | null
              | undefined;
            paragraphCustomTeaserText?:
              | { __typename?: "Text"; processed?: any | null | undefined }
              | null
              | undefined;
          } & ParagraphFields_ParagraphCustomTeaser_Fragment)
        | { __typename?: "ParagraphReferenceTeaser" }
      >
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphTeasers_Fragment;

export type QuoteParagraphFieldsFragment = {
  __typename?: "ParagraphQuote";
  author?: string | null | undefined;
  text: { __typename?: "Text"; processed?: any | null | undefined };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphQuote_Fragment;

export type HtmlParagraphFieldsFragment = {
  __typename?: "ParagraphHtml";
  code: string;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphHtml_Fragment;

export type TestimonialsParagraphFieldsFragment = {
  __typename?: "ParagraphTestimonials";
  title?: string | null | undefined;
  testimonials: Array<{
    __typename?: "ParagraphTestimonial";
    id: string;
    author: string;
    functionTitle: string;
    authorImage?:
      | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
      | null
      | undefined;
    text: { __typename?: "Text"; processed?: any | null | undefined };
  }>;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
    background_color?: string | null | undefined;
  };
} & ParagraphFields_ParagraphTestimonials_Fragment;

export type JobsListParagraphFieldsFragment = {
  __typename?: "ParagraphJobsList";
  title?: string | null | undefined;
  city?: string | null | undefined;
  language?: Array<string | null | undefined> | null | undefined;
  query?: string | null | undefined;
  regime?: Array<string | null | undefined> | null | undefined;
  sectorGroup?: Array<string | null | undefined> | null | undefined;
  functionGroup?: Array<string | null | undefined> | null | undefined;
  speciality?: Array<string | null | undefined> | null | undefined;
  type?: Array<string | null | undefined> | null | undefined;
  studentJob?: boolean | null | undefined;
  limit?: number | null | undefined;
  link?: ({ __typename?: "Link" } & LinkFieldsFragment) | null | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphJobsList_Fragment;

export type FaqOverviewParagraphFieldsFragment = {
  __typename?: "ParagraphFaqOverview";
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphFaqOverview_Fragment;

export type ChatbotSuggestionsParagraphFieldsFragment = {
  __typename?: "ParagraphChatbotSuggestions";
  title?: string | null | undefined;
  chatBubbleText: { __typename?: "Text"; processed?: any | null | undefined };
  btnLinks: Array<{ __typename?: "Link" } & LinkFieldsFragment>;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphChatbotSuggestions_Fragment;

export type FindJobsParagraphFieldsFragment = {
  __typename?: "ParagraphFindJobs";
  perFunction?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  perSector?:
    | Array<{ __typename?: "Link" } & LinkFieldsFragment>
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphFindJobs_Fragment;

export type FiveBlocksParagraphFieldsFragment = {
  __typename?: "ParagraphFiveBlocks";
  titleForBlock1: string;
  titleForBlock3: string;
  titleForBlock4: string;
  titleForBlock5: string;
  imageForBlock2?:
    | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
    | null
    | undefined;
  imageForBlock3: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
  imageForBlock4: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
  imageForBlock5: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
  textForBlock1?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  textForBlock2?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  textForBlock3: { __typename?: "Text"; processed?: any | null | undefined };
  textForBlock4: { __typename?: "Text"; processed?: any | null | undefined };
  textForBlock5: { __typename?: "Text"; processed?: any | null | undefined };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphFiveBlocks_Fragment;

export type RecentBlogsParagraphFieldsFragment = {
  __typename?: "ParagraphRecentBlogs";
  title?: string | null | undefined;
  intro?:
    | { __typename?: "Text"; processed?: any | null | undefined }
    | null
    | undefined;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphRecentBlogs_Fragment;

export type StatisticParagraphFieldsFragment = {
  __typename?: "ParagraphStatistic";
  statisticItems: Array<
    {
      __typename?: "ParagraphStatisticItem";
    } & StatisticItemParagraphFieldsFragment
  >;
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphStatistic_Fragment;

export type StatisticItemParagraphFieldsFragment = {
  __typename?: "ParagraphStatisticItem";
  statisticItemAmount: string;
  statisticItemEntity: string;
  statisticItemDescription: {
    __typename?: "Text";
    processed?: any | null | undefined;
  };
  entity_type_behaviors: {
    __typename?: "EntityTypeBehaviors";
    margin?: string | null | undefined;
  };
} & ParagraphFields_ParagraphStatisticItem_Fragment;

export type CreateApplicationMutationVariables = Exact<{
  birthDate?: InputMaybe<Scalars["Date"]["input"]>;
  desiredFunction?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  newsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  officeKey?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  privacyStatement?: InputMaybe<Scalars["Boolean"]["input"]>;
  jobId?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  telephone?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateApplicationMutation = {
  __typename?: "Mutation";
  createApplication?:
    | {
        __typename?: "Application";
        job_application_key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateApplicationMutationVariables = Exact<{
  jobId?: InputMaybe<Scalars["String"]["input"]>;
  applicationKey: Scalars["String"]["input"];
  cv?: InputMaybe<Scalars["String"]["input"]>;
  cvContentType?: InputMaybe<Scalars["String"]["input"]>;
  jobEvent?: InputMaybe<Scalars["String"]["input"]>;
  cvAnonymous?: InputMaybe<Scalars["String"]["input"]>;
  cvAnonymousContentType?: InputMaybe<Scalars["String"]["input"]>;
  motivation?: InputMaybe<Scalars["String"]["input"]>;
  motivationContentType?: InputMaybe<Scalars["String"]["input"]>;
  remarks?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateApplicationMutation = {
  __typename?: "Mutation";
  updateApplication?:
    | {
        __typename?: "Application";
        job_application_key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CreateUserApplicationMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  userKey: Scalars["String"]["input"];
}>;

export type CreateUserApplicationMutation = {
  __typename?: "Mutation";
  createUserApplication?:
    | {
        __typename?: "CandidateApplicationResponse";
        job_application_key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type InvalidateCacheMutationVariables = Exact<{
  type: InvalidateCacheType;
  details?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvalidateCacheMutation = {
  __typename?: "Mutation";
  invalidateCache?:
    | { __typename?: "InvalidateCacheResponse"; status: number }
    | null
    | undefined;
};

export type UpdateCandidateAvailabilityMutationVariables = Exact<{
  userKey: Scalars["String"]["input"];
  available: Scalars["Boolean"]["input"];
  availableDate: Scalars["String"]["input"];
}>;

export type UpdateCandidateAvailabilityMutation = {
  __typename?: "Mutation";
  updateCandidateAvailability?:
    | { __typename?: "Response"; status?: number | null | undefined }
    | null
    | undefined;
};

export type UpdateCandidatePreferencesMutationVariables = Exact<{
  userKey: Scalars["String"]["input"];
  optInForNewsletterPreferences?: InputMaybe<Scalars["Boolean"]["input"]>;
  terms?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  latitude?: InputMaybe<Scalars["String"]["input"]>;
  longitude?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<Scalars["String"]["input"]>;
  radius?: InputMaybe<Scalars["String"]["input"]>;
  workRegimes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  types?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  specialties?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sectorGroups?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  jobstudent?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateCandidatePreferencesMutation = {
  __typename?: "Mutation";
  updateCandidateSearchPreferences?:
    | { __typename?: "Response"; status?: number | null | undefined }
    | null
    | undefined;
};

export type UpdateCandidateMutationVariables = Exact<{
  userKey: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  birthDate: Scalars["Date"]["input"];
  telephone: Scalars["String"]["input"];
  street?: InputMaybe<Scalars["String"]["input"]>;
  houseNumber?: InputMaybe<Scalars["String"]["input"]>;
  bus?: InputMaybe<Scalars["String"]["input"]>;
  postalCode: Scalars["String"]["input"];
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  profileComplete: Scalars["Boolean"]["input"];
  facebookId?: InputMaybe<Scalars["String"]["input"]>;
  linkedinId?: InputMaybe<Scalars["String"]["input"]>;
  workidId?: InputMaybe<Scalars["String"]["input"]>;
  languageSkills?: InputMaybe<Scalars["String"]["input"]>;
  officeKey?: InputMaybe<Scalars["String"]["input"]>;
  educationalLevel?: InputMaybe<Scalars["String"]["input"]>;
  transportTypes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  workRegimes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  contractTypes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  transportType?: InputMaybe<Scalars["String"]["input"]>;
  driversLicence?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  educations?: InputMaybe<
    Array<InputMaybe<EducationInput>> | InputMaybe<EducationInput>
  >;
  workExperience?: InputMaybe<
    Array<InputMaybe<WorkExperienceInput>> | InputMaybe<WorkExperienceInput>
  >;
  certificates?: InputMaybe<
    Array<InputMaybe<CertificateInput>> | InputMaybe<CertificateInput>
  >;
  properties?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  remarks?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateCandidateMutation = {
  __typename?: "Mutation";
  updateCandidate?:
    | { __typename?: "Response"; status?: number | null | undefined }
    | null
    | undefined;
};

export type AddDocumentMutationVariables = Exact<{
  userKey?: InputMaybe<Scalars["String"]["input"]>;
  documentTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  document?: InputMaybe<Scalars["String"]["input"]>;
  documentContentType?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AddDocumentMutation = {
  __typename?: "Mutation";
  addDocument?:
    | { __typename?: "Response"; status?: number | null | undefined }
    | null
    | undefined;
};

export type CreateNewsletterSubscriptionMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  legalBasis?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  optIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  optInDate?: InputMaybe<Scalars["String"]["input"]>;
  optInSource?: InputMaybe<Scalars["String"]["input"]>;
  optOutDate?: InputMaybe<Scalars["String"]["input"]>;
  optOutSource?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateNewsletterSubscriptionMutation = {
  __typename?: "Mutation";
  createNewsletterSubscription?:
    | {
        __typename?: "NewsletterSubscriptionResponse";
        user_key?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCandidateSavedJobsMutationVariables = Exact<{
  userKey: Scalars["String"]["input"];
  savedJobs: Scalars["String"]["input"];
}>;

export type UpdateCandidateSavedJobsMutation = {
  __typename?: "Mutation";
  updateCandidateSavedJobs?:
    | { __typename?: "Response"; status?: number | null | undefined }
    | null
    | undefined;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  newsletter: Scalars["Boolean"]["input"];
  confirmed: Scalars["Boolean"]["input"];
  language: Scalars["String"]["input"];
  emailPageUrl: Scalars["String"]["input"];
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?:
    | {
        __typename?: "UserResponse";
        user_key?: string | null | undefined;
        status: number;
      }
    | null
    | undefined;
};

export type CreateUserTokenMutationVariables = Exact<{
  email?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateUserTokenMutation = {
  __typename?: "Mutation";
  createUserToken?:
    | {
        __typename?: "UserTokenResponse";
        description?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ResetUserPasswordMutationVariables = Exact<{
  email?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ResetUserPasswordMutation = {
  __typename?: "Mutation";
  resetUserPassword?:
    | { __typename?: "UserResetPassword"; description: string; status: number }
    | null
    | undefined;
};

export type ActivateUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ActivateUserMutation = {
  __typename?: "Mutation";
  activateUser?:
    | {
        __typename?: "UserActivateResponse";
        description: string;
        status: number;
      }
    | null
    | undefined;
};

export type GetBannerImageQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type GetBannerImageQuery = {
  __typename?: "Query";
  bannerImageByName?:
    | {
        __typename?: "ImageBannerByName";
        imageMediaImage?:
          | ({ __typename?: "Image" } & ImageFieldsFragment)
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetBlogPageQueryVariables = Exact<{
  nodeBlogId: Scalars["ID"]["input"];
}>;

export type GetBlogPageQuery = {
  __typename?: "Query";
  nodeBlog?:
    | ({ __typename?: "NodeBlog" } & BlogPageFieldsFragment)
    | null
    | undefined;
};

export type GetBlogOverviewPageQueryVariables = Exact<{
  nodeBlogOverviewId: Scalars["ID"]["input"];
}>;

export type GetBlogOverviewPageQuery = {
  __typename?: "Query";
  nodeBlogOverview?:
    | ({ __typename?: "NodeBlogOverview" } & BlogOverviewPageFieldsFragment)
    | null
    | undefined;
};

export type GetBlogPageTeaserQueryVariables = Exact<{
  nodeBlogId: Scalars["ID"]["input"];
}>;

export type GetBlogPageTeaserQuery = {
  __typename?: "Query";
  nodeBlog?:
    | {
        __typename?: "NodeBlog";
        title: string;
        path: string;
        teaserMedia: { __typename?: "MediaImage" } & MediaImageFieldsFragment;
        intro?:
          | { __typename?: "Text"; processed?: any | null | undefined }
          | null
          | undefined;
        langcode: { __typename?: "Language"; id?: string | null | undefined };
      }
    | null
    | undefined;
};

export type GetBlogOverviewQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  langcode: Scalars["String"]["input"];
  field_blog_category_ref_target_id?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetBlogOverviewQuery = {
  __typename?: "Query";
  blogOverviewGraphql?:
    | {
        __typename?: "BlogOverviewGraphqlResult";
        description?: string | null | undefined;
        display: string;
        id: string;
        label?: string | null | undefined;
        langcode?: string | null | undefined;
        view: string;
        results: Array<
          | {
              __typename?: "NodeBlog";
              title: string;
              id: string;
              path: string;
              created: { __typename?: "DateTime"; timestamp: any };
              blogCategoryRef?:
                | Array<
                    | {
                        __typename?: "TermBlogCategories";
                        name: string;
                        id: string;
                      }
                    | { __typename?: "TermMediaTags" }
                    | { __typename?: "TermTargetAudience" }
                  >
                | null
                | undefined;
              teaserMedia: {
                __typename?: "MediaImage";
              } & MediaImageFieldsFragment;
              intro?:
                | { __typename?: "Text"; processed?: any | null | undefined }
                | null
                | undefined;
              langcode: {
                __typename?: "Language";
                id?: string | null | undefined;
              };
            }
          | { __typename?: "NodeBlogOverview" }
          | { __typename?: "NodeFaq" }
          | { __typename?: "NodeFaqCategory" }
          | { __typename?: "NodeFaqCategoryOverview" }
          | { __typename?: "NodeFaqOverview" }
          | { __typename?: "NodePage" }
        >;
        pageInfo: {
          __typename?: "ViewPageInfo";
          page: number;
          pageSize: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type GetBlogCategoriesOverviewQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetBlogCategoriesOverviewQuery = {
  __typename?: "Query";
  blogCategoryOverview?:
    | {
        __typename?: "BlogCategoryOverviewResult";
        description?: string | null | undefined;
        display: string;
        id: string;
        label?: string | null | undefined;
        langcode?: string | null | undefined;
        view: string;
        results: Array<
          | {
              __typename: "TermBlogCategories";
              id: string;
              tid: number;
              name: string;
            }
          | { __typename: "TermMediaTags" }
          | { __typename: "TermTargetAudience" }
        >;
        pageInfo: {
          __typename?: "ViewPageInfo";
          page: number;
          pageSize: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type GetRecentBlogsOverviewQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
  field_target_audience_ref_target_id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetRecentBlogsOverviewQuery = {
  __typename?: "Query";
  recentBlogsOverview?:
    | {
        __typename?: "RecentBlogsOverviewResult";
        description?: string | null | undefined;
        display: string;
        id: string;
        label?: string | null | undefined;
        langcode?: string | null | undefined;
        view: string;
        results: Array<
          | ({ __typename: "NodeBlog" } & BlogPageFieldsFragment)
          | { __typename: "NodeBlogOverview" }
          | { __typename: "NodeFaq" }
          | { __typename: "NodeFaqCategory" }
          | { __typename: "NodeFaqCategoryOverview" }
          | { __typename: "NodeFaqOverview" }
          | { __typename: "NodePage" }
        >;
        pageInfo: {
          __typename?: "ViewPageInfo";
          page: number;
          pageSize: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type GetBlogPageByUrlQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
}>;

export type GetBlogPageByUrlQuery = {
  __typename?: "Query";
  route?:
    | { __typename?: "Error" }
    | { __typename?: "RouteExternal" }
    | {
        __typename?: "RouteInternal";
        entity?:
          | ({ __typename?: "NodeBlog" } & BlogPageFieldsFragment)
          | { __typename?: "NodeBlogOverview" }
          | { __typename?: "NodeFaq" }
          | { __typename?: "NodeFaqCategory" }
          | { __typename?: "NodeFaqCategoryOverview" }
          | { __typename?: "NodeFaqOverview" }
          | { __typename?: "NodePage" }
          | null
          | undefined;
      }
    | { __typename?: "RouteRedirect" }
    | null
    | undefined;
};

export type GetBlogPagesForUrlsQueryVariables = Exact<{
  langcode?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetBlogPagesForUrlsQuery = {
  __typename?: "Query";
  nodeBlogs: {
    __typename?: "NodeBlogConnection";
    nodes: Array<{
      __typename?: "NodeBlog";
      id: string;
      title: string;
      path: string;
    }>;
    pageInfo: {
      __typename?: "ConnectionPageInfo";
      endCursor?: any | null | undefined;
      hasNextPage: boolean;
    };
  };
};

export type GetCandidateQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateQuery = {
  __typename?: "Query";
  candidate?:
    | {
        __typename?: "Candidate";
        user_key: string;
        email: string;
        first_name?: string | null | undefined;
        name?: string | null | undefined;
        language?: string | null | undefined;
        birth_date?: any | null | undefined;
        telephone?: string | null | undefined;
        office_key?: string | null | undefined;
        education_level?: string | null | undefined;
        transport_type?: string | null | undefined;
        transport_types?: Array<string | null | undefined> | null | undefined;
        contract_types?: Array<string | null | undefined> | null | undefined;
        profile_complete?: boolean | null | undefined;
        work_regimes?: Array<string | null | undefined> | null | undefined;
        drivers_licence?: Array<string | null | undefined> | null | undefined;
        address?:
          | {
              __typename?: "Address";
              street?: string | null | undefined;
              number?: string | null | undefined;
              box?: string | null | undefined;
              locality?: string | null | undefined;
              postal_code?: string | null | undefined;
              country?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCandidateDocumentsQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateDocumentsQuery = {
  __typename?: "Query";
  candidateDocuments?:
    | Array<
        | {
            __typename?: "CandidateDocument";
            date_last_changed?: string | null | undefined;
            document_type?: string | null | undefined;
            name?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCandidateDocumentQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
  documentType: Scalars["String"]["input"];
}>;

export type GetCandidateDocumentQuery = {
  __typename?: "Query";
  candidateDocument?:
    | {
        __typename?: "Document";
        document: string;
        document_content_type?: string | null | undefined;
        name: string;
      }
    | null
    | undefined;
};

export type GetCandidateSearchPreferencesQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateSearchPreferencesQuery = {
  __typename?: "Query";
  candidateSearchPreferences?:
    | {
        __typename?: "CandidateSearchPreference";
        user_key?: string | null | undefined;
        opt_in_for_newsletter_preferences?: boolean | null | undefined;
        terms?: string | null | undefined;
        language?: string | null | undefined;
        latitude?: string | null | undefined;
        longitude?: string | null | undefined;
        location?: string | null | undefined;
        radius?: string | null | undefined;
        work_regimes?: Array<string | null | undefined> | null | undefined;
        types?: Array<string | null | undefined> | null | undefined;
        specialties?: Array<string | null | undefined> | null | undefined;
        sector_groups?: Array<string | null | undefined> | null | undefined;
        jobstudent?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type GetCandidateAppointmentsQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateAppointmentsQuery = {
  __typename?: "Query";
  candidateAppointments?:
    | Array<
        | {
            __typename?: "CandidateAppointment";
            date?: string | null | undefined;
            name?: string | null | undefined;
            interview_reason?: string | null | undefined;
            interviewer?: string | null | undefined;
            company?: string | null | undefined;
            address?:
              | {
                  __typename?: "Address";
                  street?: string | null | undefined;
                  number?: string | null | undefined;
                  box?: string | null | undefined;
                  locality?: string | null | undefined;
                  postal_code?: string | null | undefined;
                  country?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCandidateAvailabilityQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateAvailabilityQuery = {
  __typename?: "Query";
  candidateAvailability?:
    | {
        __typename?: "CandidateAvailability";
        available?: boolean | null | undefined;
        available_date?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetCandidateApplicationsQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateApplicationsQuery = {
  __typename?: "Query";
  candidateApplications?:
    | Array<
        | {
            __typename?: "CandidateApplication";
            key?: string | null | undefined;
            name?: string | null | undefined;
            employment_city?: string | null | undefined;
            status?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCandidateIntentionQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateIntentionQuery = {
  __typename?: "Query";
  candidateIntention?:
    | {
        __typename?: "CandidateIntention";
        user_key: string;
        email: string;
        first_name?: string | null | undefined;
        name?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetCandidateProfileScoreDataQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateProfileScoreDataQuery = {
  __typename?: "Query";
  candidate?:
    | {
        __typename?: "Candidate";
        user_key: string;
        email: string;
        first_name?: string | null | undefined;
        name?: string | null | undefined;
        language?: string | null | undefined;
        birth_date?: any | null | undefined;
        telephone?: string | null | undefined;
        office_key?: string | null | undefined;
        education_level?: string | null | undefined;
        transport_type?: string | null | undefined;
        transport_types?: Array<string | null | undefined> | null | undefined;
        contract_types?: Array<string | null | undefined> | null | undefined;
        profile_complete?: boolean | null | undefined;
        work_regimes?: Array<string | null | undefined> | null | undefined;
        drivers_licence?: Array<string | null | undefined> | null | undefined;
        language_skills?: Array<string | null | undefined> | null | undefined;
        address?:
          | {
              __typename?: "Address";
              street?: string | null | undefined;
              number?: string | null | undefined;
              box?: string | null | undefined;
              locality?: string | null | undefined;
              postal_code?: string | null | undefined;
              country?: string | null | undefined;
            }
          | null
          | undefined;
        work_experience?:
          | Array<
              | {
                  __typename?: "WorkExperience";
                  job_type?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  candidateDocuments?:
    | Array<
        | {
            __typename?: "CandidateDocument";
            document_type?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  candidateSearchPreferences?:
    | {
        __typename?: "CandidateSearchPreference";
        user_key?: string | null | undefined;
        opt_in_for_newsletter_preferences?: boolean | null | undefined;
        terms?: string | null | undefined;
        language?: string | null | undefined;
        location?: string | null | undefined;
        radius?: string | null | undefined;
        work_regimes?: Array<string | null | undefined> | null | undefined;
        types?: Array<string | null | undefined> | null | undefined;
        specialties?: Array<string | null | undefined> | null | undefined;
        sector_groups?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
};

export type GetCandidateSavedJobsQueryVariables = Exact<{
  userKey: Scalars["String"]["input"];
}>;

export type GetCandidateSavedJobsQuery = {
  __typename?: "Query";
  candidateSavedJobs?: string | null | undefined;
};

export type GetFagSearchOverviewQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  langcode: Scalars["String"]["input"];
  search?: InputMaybe<Scalars["String"]["input"]>;
  category_uuid?: InputMaybe<Scalars["String"]["input"]>;
  field_target_audience_ref?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetFagSearchOverviewQuery = {
  __typename?: "Query";
  faqSearch?:
    | {
        __typename?: "FaqSearchResult";
        description?: string | null | undefined;
        display: string;
        id: string;
        label?: string | null | undefined;
        langcode?: string | null | undefined;
        view: string;
        results: Array<
          | { __typename?: "NodeBlog" }
          | { __typename?: "NodeBlogOverview" }
          | {
              __typename?: "NodeFaq";
              title: string;
              path: string;
              langcode: {
                __typename?: "Language";
                id?: string | null | undefined;
              };
              faqCategory?:
                | { __typename?: "NodeBlog" }
                | { __typename?: "NodeBlogOverview" }
                | { __typename?: "NodeFaq" }
                | ({
                    __typename?: "NodeFaqCategory";
                  } & FaqCategoryMinimalFieldsFragment)
                | { __typename?: "NodeFaqCategoryOverview" }
                | { __typename?: "NodeFaqOverview" }
                | { __typename?: "NodePage" }
                | null
                | undefined;
            }
          | { __typename?: "NodeFaqCategory" }
          | { __typename?: "NodeFaqCategoryOverview" }
          | { __typename?: "NodeFaqOverview" }
          | { __typename?: "NodePage" }
        >;
        pageInfo: {
          __typename?: "ViewPageInfo";
          page: number;
          pageSize: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type GetFaqCategoriesOverviewQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
  target_audience?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetFaqCategoriesOverviewQuery = {
  __typename?: "Query";
  faqCategory?:
    | {
        __typename?: "FaqCategoryResult";
        description?: string | null | undefined;
        display: string;
        id: string;
        label?: string | null | undefined;
        langcode?: string | null | undefined;
        view: string;
        results: Array<
          | { __typename?: "NodeBlog" }
          | { __typename?: "NodeBlogOverview" }
          | { __typename?: "NodeFaq" }
          | {
              __typename?: "NodeFaqCategory";
              title: string;
              icon?: string | null | undefined;
              path: string;
              description?:
                | { __typename?: "Text"; processed?: any | null | undefined }
                | null
                | undefined;
              langcode: {
                __typename?: "Language";
                id?: string | null | undefined;
              };
            }
          | { __typename?: "NodeFaqCategoryOverview" }
          | { __typename?: "NodeFaqOverview" }
          | { __typename?: "NodePage" }
        >;
        pageInfo: {
          __typename?: "ViewPageInfo";
          page: number;
          pageSize: number;
          total: number;
        };
      }
    | null
    | undefined;
};

export type SearchJobsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  maxDistance?: InputMaybe<Scalars["String"]["input"]>;
  lat?: InputMaybe<Scalars["String"]["input"]>;
  lng?: InputMaybe<Scalars["String"]["input"]>;
  contractTypes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  regimes?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  niches?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  functionGroups?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  sectors?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  officeId?: InputMaybe<Scalars["String"]["input"]>;
  languages?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  studentJob?: InputMaybe<Scalars["Boolean"]["input"]>;
  relatedId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SearchJobsQuery = {
  __typename?: "Query";
  jobsSearch?:
    | {
        __typename?: "JobSearch";
        from: number;
        to: number;
        total_results: number;
        jobs: Array<{
          __typename?: "Job";
          id?: string | null | undefined;
          city?: string | null | undefined;
          created_at?: string | null | undefined;
          description?: string | null | undefined;
          label?: string | null | undefined;
          internal?: boolean | null | undefined;
          regime?: string | null | undefined;
          salary_min?: number | null | undefined;
          salary_max?: number | null | undefined;
          salary_period?: string | null | undefined;
          sector_group?: string | null | undefined;
          specialty?: string | null | undefined;
          title?: string | null | undefined;
          type?: string | null | undefined;
          urgent?: boolean | null | undefined;
        }>;
        facets: Array<{
          __typename?: "Facet";
          name: string;
          values?:
            | Array<
                | {
                    __typename?: "FacetValue";
                    count?: number | null | undefined;
                    value?: string | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type SearchRelatedJobsQueryVariables = Exact<{
  relatedJobId: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchRelatedJobsQuery = {
  __typename?: "Query";
  jobsSearch?:
    | {
        __typename?: "JobSearch";
        from: number;
        to: number;
        total_results: number;
        jobs: Array<{
          __typename?: "Job";
          id?: string | null | undefined;
          city?: string | null | undefined;
          created_at?: string | null | undefined;
          description?: string | null | undefined;
          label?: string | null | undefined;
          internal?: boolean | null | undefined;
          regime?: string | null | undefined;
          salary_min?: number | null | undefined;
          salary_max?: number | null | undefined;
          salary_period?: string | null | undefined;
          sector_group?: string | null | undefined;
          specialty?: string | null | undefined;
          title?: string | null | undefined;
          type?: string | null | undefined;
          urgent?: boolean | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetJobQueryVariables = Exact<{
  jobId: Scalars["String"]["input"];
  langcode: Scalars["String"]["input"];
}>;

export type GetJobQuery = {
  __typename?: "Query";
  job?:
    | {
        __typename?: "Job";
        id?: string | null | undefined;
        city?: string | null | undefined;
        created_at?: string | null | undefined;
        description?: string | null | undefined;
        end_date?: string | null | undefined;
        function?: string | null | undefined;
        function_group?: string | null | undefined;
        internal?: boolean | null | undefined;
        jobstudent?: boolean | null | undefined;
        label?: string | null | undefined;
        language?: string | null | undefined;
        offer?: string | null | undefined;
        office_id?: string | null | undefined;
        postal_code?: string | null | undefined;
        reference?: string | null | undefined;
        regime?: string | null | undefined;
        salary_max?: number | null | undefined;
        salary_min?: number | null | undefined;
        salary_period?: string | null | undefined;
        sector_group?: string | null | undefined;
        specialty?: string | null | undefined;
        status?: string | null | undefined;
        street_address?: string | null | undefined;
        title?: string | null | undefined;
        type?: string | null | undefined;
        urgent?: boolean | null | undefined;
        closed?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type GetJobsForUrlsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  languages?: InputMaybe<
    | Array<InputMaybe<Scalars["String"]["input"]>>
    | InputMaybe<Scalars["String"]["input"]>
  >;
}>;

export type GetJobsForUrlsQuery = {
  __typename?: "Query";
  jobsSearch?:
    | {
        __typename?: "JobSearch";
        from: number;
        to: number;
        total_results: number;
        jobs: Array<{
          __typename?: "Job";
          id?: string | null | undefined;
          title?: string | null | undefined;
          reference?: string | null | undefined;
          updated_at?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetJobOptionsQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
}>;

export type GetJobOptionsQuery = {
  __typename?: "Query";
  jobOptionLists?:
    | {
        __typename?: "JobOptionLists";
        work_regimes?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        contract_types?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        specialties?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        sector_groups?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        education_levels?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        transport_types?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        function_groups?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        document_types?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        language_skills?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        drivers_licences?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
        certificates?:
          | Array<{ __typename?: "JobOption" } & JobOptionFragment>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMenusQueryVariables = Exact<{
  langcode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetMenusQuery = {
  __typename?: "Query";
  mainMenuEmployee?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  secondaryMenuEmployee?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  mainMenuEmployer?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  secondaryMenuEmployer?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuEmployee?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuEmployer?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuAbout?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuJobsPerRegion?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuJobsPerSector?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuExpertises?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  footerMenuServices?:
    | ({ __typename?: "Menu" } & MenuFieldsFragment)
    | null
    | undefined;
  legalMenu?: ({ __typename?: "Menu" } & MenuFieldsFragment) | null | undefined;
};

export type GetOfficesQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
}>;

export type GetOfficesQuery = {
  __typename?: "Query";
  offices?:
    | Array<
        | {
            __typename?: "Office";
            id: string;
            title: string;
            speciality?: string | null | undefined;
            longitude?: number | null | undefined;
            latitude?: number | null | undefined;
            telephone?: string | null | undefined;
            email?: string | null | undefined;
            photo?: string | null | undefined;
            opening_hours?:
              | Array<
                  | {
                      __typename?: "OpeningHour";
                      day?: number | null | undefined;
                      hours?:
                        | Array<
                            | {
                                __typename?: "Hour";
                                opening_hour?: string | null | undefined;
                                closing_hour?: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            address?:
              | {
                  __typename?: "Address";
                  street?: string | null | undefined;
                  number?: string | null | undefined;
                  box?: string | null | undefined;
                  locality?: string | null | undefined;
                  postal_code?: string | null | undefined;
                  country?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetOfficesForUrlsQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
}>;

export type GetOfficesForUrlsQuery = {
  __typename?: "Query";
  offices?:
    | Array<
        { __typename?: "Office"; id: string; title: string } | null | undefined
      >
    | null
    | undefined;
};

export type GetOfficePageQueryVariables = Exact<{
  officeId: Scalars["String"]["input"];
  langcode: Scalars["String"]["input"];
}>;

export type GetOfficePageQuery = {
  __typename?: "Query";
  office?:
    | {
        __typename?: "Office";
        id: string;
        title: string;
        description?: string | null | undefined;
        latitude?: number | null | undefined;
        longitude?: number | null | undefined;
        speciality?: string | null | undefined;
        place_id?: string | null | undefined;
        language?: string | null | undefined;
        telephone?: string | null | undefined;
        chat?: boolean | null | undefined;
        linkedin?: string | null | undefined;
        instagram?: string | null | undefined;
        facebook?: string | null | undefined;
        twitter?: string | null | undefined;
        email?: string | null | undefined;
        photo?: string | null | undefined;
        address?:
          | {
              __typename?: "Address";
              street?: string | null | undefined;
              number?: string | null | undefined;
              box?: string | null | undefined;
              locality?: string | null | undefined;
              postal_code?: string | null | undefined;
              country?: string | null | undefined;
            }
          | null
          | undefined;
        staff?:
          | Array<
              | {
                  __typename?: "Staff";
                  staff_key: string;
                  first_name: string;
                  photo_url?: string | null | undefined;
                  photo_last_changed?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        opening_hours?:
          | Array<
              | {
                  __typename?: "OpeningHour";
                  day?: number | null | undefined;
                  hours?:
                    | Array<
                        | {
                            __typename?: "Hour";
                            opening_hour?: string | null | undefined;
                            closing_hour?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetOfficeSummaryQueryVariables = Exact<{
  officeId: Scalars["String"]["input"];
  langcode: Scalars["String"]["input"];
}>;

export type GetOfficeSummaryQuery = {
  __typename?: "Query";
  office?:
    | {
        __typename?: "Office";
        id: string;
        title: string;
        speciality?: string | null | undefined;
        place_id?: string | null | undefined;
        language?: string | null | undefined;
        telephone?: string | null | undefined;
        chat?: boolean | null | undefined;
        linkedin?: string | null | undefined;
        instagram?: string | null | undefined;
        facebook?: string | null | undefined;
        twitter?: string | null | undefined;
        staff?:
          | Array<
              | {
                  __typename?: "Staff";
                  staff_key: string;
                  first_name: string;
                  photo_url?: string | null | undefined;
                  photo_last_changed?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetStandardPageQueryVariables = Exact<{
  nodePageId: Scalars["ID"]["input"];
}>;

export type GetStandardPageQuery = {
  __typename?: "Query";
  nodePage?:
    | ({ __typename?: "NodePage" } & StandardPageFieldsFragment)
    | null
    | undefined;
};

export type GetStandardPageTeaserQueryVariables = Exact<{
  nodePageId: Scalars["ID"]["input"];
}>;

export type GetStandardPageTeaserQuery = {
  __typename?: "Query";
  nodePage?:
    | {
        __typename?: "NodePage";
        title: string;
        path: string;
        teaserMedia?:
          | ({ __typename?: "MediaImage" } & MediaImageFieldsFragment)
          | null
          | undefined;
        intro?:
          | { __typename?: "Text"; processed?: any | null | undefined }
          | null
          | undefined;
        langcode: { __typename?: "Language"; id?: string | null | undefined };
      }
    | null
    | undefined;
};

export type GetStandardPageByUrlQueryVariables = Exact<{
  langcode: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
  secret?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetStandardPageByUrlQuery = {
  __typename?: "Query";
  route?:
    | { __typename: "Error"; status: number }
    | { __typename?: "RouteExternal" }
    | {
        __typename?: "RouteInternal";
        entity?:
          | ({ __typename?: "NodeBlog" } & BlogPageFieldsFragment)
          | ({
              __typename?: "NodeBlogOverview";
            } & BlogOverviewPageFieldsFragment)
          | ({ __typename?: "NodeFaq" } & FaqPageFieldsFragment)
          | ({ __typename?: "NodeFaqCategory" } & FaqCategoryPageFieldsFragment)
          | ({
              __typename?: "NodeFaqCategoryOverview";
            } & FaqCategoryOverviewPageFieldsFragment)
          | ({ __typename?: "NodeFaqOverview" } & FaqOverviewPageFieldsFragment)
          | ({ __typename?: "NodePage" } & StandardPageFieldsFragment)
          | null
          | undefined;
      }
    | {
        __typename: "RouteRedirect";
        internal: boolean;
        redirect: boolean;
        status: number;
        url: string;
      }
    | null
    | undefined;
};

export type GetStandardPagesForUrlsQueryVariables = Exact<{
  langcode?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
}>;

export type GetStandardPagesForUrlsQuery = {
  __typename?: "Query";
  nodePages?:
    | {
        __typename?: "NodePageConnection";
        nodes?:
          | Array<{
              __typename?: "NodePage";
              id: string;
              title: string;
              path: string;
            }>
          | null
          | undefined;
        pageInfo: {
          __typename?: "ConnectionPageInfo";
          endCursor?: any | null | undefined;
          hasNextPage: boolean;
        };
      }
    | null
    | undefined;
};

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetUserByEmailQuery = {
  __typename?: "Query";
  userByEmail?:
    | {
        __typename?: "User";
        approved: string;
        email: string;
        first_name: string;
        language: string;
        name: string;
        user_key: string;
        password?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetUserBySocialsQueryVariables = Exact<{
  salesforce_id: Scalars["String"]["input"];
}>;

export type GetUserBySocialsQuery = {
  __typename?: "Query";
  userBySocials?:
    | {
        __typename?: "User";
        approved: string;
        email: string;
        first_name: string;
        language: string;
        name: string;
        user_key: string;
      }
    | null
    | undefined;
};

export const ImageFieldsFragmentDoc = `
    fragment ImageFields on Image {
  alt
  title
  variations {
    name
    url
    width
    height
  }
}
    `;
export const MediaImageFieldsFragmentDoc = `
    fragment MediaImageFields on MediaImage {
  imageMediaImage {
    ...ImageFields
  }
}
    `;
export const DateTimeFieldsFragmentDoc = `
    fragment DateTimeFields on DateTime {
  timestamp
  timezone
  offset
  time
}
    `;
export const ParagraphFieldsFragmentDoc = `
    fragment ParagraphFields on ParagraphInterface {
  __typename
  id
  status
}
    `;
export const LinkFieldsFragmentDoc = `
    fragment LinkFields on Link {
  title
  url
  internal
}
    `;
export const CallToActionParagraphFieldsFragmentDoc = `
    fragment CallToActionParagraphFields on ParagraphCallToAction {
  ...ParagraphFields
  label
  title
  paragraphCallToActionText {
    processed
  }
  paragraphCallToActionLink {
    ...LinkFields
  }
  paragraphCallToActionLink2 {
    ...LinkFields
  }
  entity_type_behaviors {
    margin
    background_color
    box_display
  }
}
    `;
export const TextParagraphFieldsFragmentDoc = `
    fragment TextParagraphFields on ParagraphText {
  ...ParagraphFields
  paragraphTextText {
    processed
  }
  paragraphTextLink {
    ...LinkFields
  }
  entity_type_behaviors {
    margin
    background_color_full_width
    background_color
    box_display
  }
}
    `;
export const ImageParagraphFieldsFragmentDoc = `
    fragment ImageParagraphFields on ParagraphImage {
  ...ParagraphFields
  paragraphImageMedia {
    ...MediaImageFields
  }
  caption {
    processed
  }
  title
  entity_type_behaviors {
    margin
    box_display
  }
}
    `;
export const MediaRemoteVideoFieldsFragmentDoc = `
    fragment MediaRemoteVideoFields on MediaRemoteVideo {
  id
  mediaOembedVideo
  remoteVideoMediaImage {
    ...ImageFields
  }
}
    `;
export const MediaVideoFieldsFragmentDoc = `
    fragment MediaVideoFields on MediaVideo {
  id
  mediaVideoFile {
    name
    url
    size
    mime
    description
  }
}
    `;
export const MediaTextParagraphFieldsFragmentDoc = `
    fragment MediaTextParagraphFields on ParagraphMediaText {
  ...ParagraphFields
  title
  label
  paragraphMediaTextLinkForMedia {
    ...LinkFields
  }
  paragraphMediaTextMedia {
    ...MediaImageFields
    ...MediaRemoteVideoFields
    ...MediaVideoFields
  }
  paragraphMediaTextText {
    processed
  }
  entity_type_behaviors {
    media_position
    margin
    box_display
    text_width
  }
  links {
    ...LinkFields
  }
}
    `;
export const VideoParagraphFieldsFragmentDoc = `
    fragment VideoParagraphFields on ParagraphVideo {
  ...ParagraphFields
  paragraphVideoMedia {
    ...MediaVideoFields
    ...MediaRemoteVideoFields
  }
  caption {
    processed
  }
  title
  entity_type_behaviors {
    margin
    box_display
  }
}
    `;
export const QuoteParagraphFieldsFragmentDoc = `
    fragment QuoteParagraphFields on ParagraphQuote {
  ... on ParagraphQuote {
    ...ParagraphFields
    author
    text {
      processed
    }
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const ChatbotSuggestionsParagraphFieldsFragmentDoc = `
    fragment ChatbotSuggestionsParagraphFields on ParagraphChatbotSuggestions {
  ... on ParagraphChatbotSuggestions {
    ...ParagraphFields
    title
    chatBubbleText {
      processed
    }
    btnLinks {
      ...LinkFields
    }
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const BlogPageFieldsFragmentDoc = `
    fragment BlogPageFields on NodeBlog {
  __typename
  id
  title
  author
  blogCategoryRef {
    ... on TermBlogCategories {
      id
      name
      tid
    }
  }
  targetAudienceRef {
    id
    name
    tid
  }
  mainMedia {
    ...MediaImageFields
  }
  teaserMedia {
    ...MediaImageFields
  }
  path
  intro {
    processed
  }
  availableLanguages {
    language
    url
  }
  relatedBlogs {
    id
    title
    author
    path
    blogCategoryRef {
      ... on TermBlogCategories {
        id
        name
        tid
      }
    }
    intro {
      processed
    }
    teaserMedia {
      ...MediaImageFields
    }
    changed {
      ...DateTimeFields
    }
    created {
      ...DateTimeFields
    }
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  content {
    ...CallToActionParagraphFields
    ...TextParagraphFields
    ...ImageParagraphFields
    ...MediaTextParagraphFields
    ...VideoParagraphFields
    ...QuoteParagraphFields
    ...ChatbotSuggestionsParagraphFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const BlogOverviewPageFieldsFragmentDoc = `
    fragment BlogOverviewPageFields on NodeBlogOverview {
  __typename
  id
  title
  path
  availableLanguages {
    language
    url
  }
  targetAudienceRef {
    id
    name
    tid
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const FaqCategoryMinimalFieldsFragmentDoc = `
    fragment FaqCategoryMinimalFields on NodeFaqCategory {
  __typename
  id
  title
  targetAudienceRef {
    id
    name
    tid
  }
}
    `;
export const FaqPageFieldsFragmentDoc = `
    fragment FaqPageFields on NodeFaq {
  __typename
  id
  title
  faqCategory {
    ... on NodeFaqCategory {
      ...FaqCategoryMinimalFields
    }
  }
  mainMedia {
    ...MediaImageFields
  }
  availableLanguages {
    language
    url
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  relatedFaqs {
    title
    path
    language
  }
  content {
    ...CallToActionParagraphFields
    ...TextParagraphFields
    ...ImageParagraphFields
    ...MediaTextParagraphFields
    ...VideoParagraphFields
    ...QuoteParagraphFields
    ...ChatbotSuggestionsParagraphFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const FaqCategoryPageFieldsFragmentDoc = `
    fragment FaqCategoryPageFields on NodeFaqCategory {
  __typename
  id
  title
  description {
    processed
  }
  icon
  availableLanguages {
    language
    url
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  targetAudienceRef {
    tid
    name
    id
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const FaqOverviewPageFieldsFragmentDoc = `
    fragment FaqOverviewPageFields on NodeFaqOverview {
  __typename
  id
  title
  path
  availableLanguages {
    language
    url
  }
  targetAudienceRef {
    id
    name
    tid
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const FaqCategoryOverviewPageFieldsFragmentDoc = `
    fragment FaqCategoryOverviewPageFields on NodeFaqCategoryOverview {
  __typename
  id
  title
  path
  availableLanguages {
    language
    url
  }
  targetAudienceRef {
    id
    name
    tid
  }
  changed {
    ...DateTimeFields
  }
  created {
    ...DateTimeFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
}
    `;
export const MediaDocumentFieldsFragmentDoc = `
    fragment MediaDocumentFields on MediaDocument {
  id
  name
  path
  status
  mediaFile {
    name
    url
    size
    mime
    description
  }
  mediaTags {
    ... on TermMediaTags {
      id
      description {
        processed
      }
      name
      path
      status
      weight
    }
  }
}
    `;
export const MediaSvgImageFieldsFragmentDoc = `
    fragment MediaSvgImageFields on MediaSvgImage {
  id
  svgMediaImage {
    ...ImageFields
  }
}
    `;
export const MenuFieldsFragmentDoc = `
    fragment MenuFields on Menu {
  id
  name
  items {
    title
    url
    internal
    id
    extras {
      __typename
      ... on MenuLinkContentJobsPerRegionMenu {
        suffix
      }
      ... on MenuLinkContentSecondaryMenuEmployee {
        iconSelect
      }
      ... on MenuLinkContentSecondaryMenuEmployer {
        iconSelect
      }
      ... on MenuLinkContentJobsPerSectorMenu {
        suffix
      }
    }
    children {
      id
      title
      url
      internal
      extras {
        __typename
        ... on MenuLinkContentJobsPerRegionMenu {
          suffix
        }
        ... on MenuLinkContentSecondaryMenuEmployee {
          iconSelect
        }
        ... on MenuLinkContentSecondaryMenuEmployer {
          iconSelect
        }
        ... on MenuLinkContentJobsPerSectorMenu {
          suffix
        }
      }
    }
  }
}
    `;
export const JobOptionFragmentDoc = `
    fragment JobOption on JobOption {
  key
  translation
}
    `;
export const FaqOverviewParagraphFieldsFragmentDoc = `
    fragment FaqOverviewParagraphFields on ParagraphFaqOverview {
  ... on ParagraphFaqOverview {
    ...ParagraphFields
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const HtmlParagraphFieldsFragmentDoc = `
    fragment HtmlParagraphFields on ParagraphHtml {
  ... on ParagraphHtml {
    ...ParagraphFields
    code
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const JobsListParagraphFieldsFragmentDoc = `
    fragment JobsListParagraphFields on ParagraphJobsList {
  ... on ParagraphJobsList {
    ...ParagraphFields
    title
    city
    language
    query
    regime
    sectorGroup
    functionGroup
    speciality
    type
    link {
      ...LinkFields
    }
    studentJob
    limit
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const NewsletterFormParagraphFieldsFragmentDoc = `
    fragment NewsletterFormParagraphFields on ParagraphNewsletterForm {
  ...ParagraphFields
  entity_type_behaviors {
    margin
  }
}
    `;
export const TeaserParagraphFieldsFragmentDoc = `
    fragment TeaserParagraphFields on ParagraphTeasers {
  ...ParagraphFields
  intro {
    processed
  }
  paragraphTeasersLink {
    title
    url
    internal
  }
  title
  content {
    ... on ParagraphCustomTeaser {
      ...ParagraphFields
      paragraphCustomTeaserLink {
        title
        url
        internal
      }
      paragraphCustomTeaserMedia {
        ...MediaImageFields
      }
      paragraphCustomTeaserText {
        processed
      }
      title
    }
  }
  entity_type_behaviors {
    margin
  }
}
    `;
export const TestimonialsParagraphFieldsFragmentDoc = `
    fragment TestimonialsParagraphFields on ParagraphTestimonials {
  ... on ParagraphTestimonials {
    ...ParagraphFields
    testimonials {
      id
      author
      authorImage {
        ...MediaImageFields
      }
      functionTitle
      text {
        processed
      }
    }
    title
    entity_type_behaviors {
      margin
      background_color
    }
  }
}
    `;
export const ThreeColumnBlockParagraphFieldsFragmentDoc = `
    fragment ThreeColumnBlockParagraphFields on ParagraphThreeColumnBlock {
  ...ParagraphFields
  label
  labelForBlock1
  labelForBlock2
  labelForBlock3
  links {
    ...LinkFields
  }
  linksForBlock1 {
    ...LinkFields
  }
  linksForBlock2 {
    ...LinkFields
  }
  linksForBlock3 {
    ...LinkFields
  }
  paragraphThreeColumnBlockImageForBlock1 {
    ...MediaImageFields
  }
  paragraphThreeColumnBlockImageForBlock2 {
    ...MediaImageFields
  }
  paragraphThreeColumnBlockImageForBlock3 {
    ...MediaImageFields
  }
  paragraphThreeColumnBlockTextForBlock1 {
    processed
  }
  paragraphThreeColumnBlockTextForBlock2 {
    processed
  }
  paragraphThreeColumnBlockTextForBlock3 {
    processed
  }
  paragraphThreeColumnBlockTitleForBlock1
  paragraphThreeColumnBlockTitleForBlock2
  paragraphThreeColumnBlockTitleForBlock3
  title
  entity_type_behaviors {
    margin
  }
}
    `;
export const ThreeImagesParagraphFieldsFragmentDoc = `
    fragment ThreeImagesParagraphFields on ParagraphThreeImages {
  ...ParagraphFields
  title
  image1ForThreeImages {
    ...MediaImageFields
  }
  image2ForThreeImages {
    ...MediaImageFields
  }
  image3ForThreeImages {
    ...MediaImageFields
  }
  linkForImageInBlock1 {
    ...LinkFields
  }
  linkForImageInBlock2 {
    ...LinkFields
  }
  linkForImageInBlock3 {
    ...LinkFields
  }
  linksForBlock1 {
    ...LinkFields
  }
  linksForBlock2 {
    ...LinkFields
  }
  linksForBlock3 {
    ...LinkFields
  }
  entity_type_behaviors {
    margin
  }
}
    `;
export const TwoColumnBlockParagraphFieldsFragmentDoc = `
    fragment TwoColumnBlockParagraphFields on ParagraphTwoColumnBlock {
  ...ParagraphFields
  paragraphTwoColumnBlockImageForBlock1 {
    ...MediaImageFields
  }
  paragraphTwoColumnBlockImageForBlock2 {
    ...MediaImageFields
  }
  label
  labelForBlock1
  labelForBlock2
  linksForBlock1 {
    ...LinkFields
  }
  linksForBlock2 {
    ...LinkFields
  }
  paragraphTwoColumnBlockTextForBlock1 {
    processed
  }
  paragraphTwoColumnBlockTextForBlock2 {
    processed
  }
  title
  paragraphTwoColumnBlockTitleForBlock1
  paragraphTwoColumnBlockTitleForBlock2
  entity_type_behaviors {
    margin
  }
}
    `;
export const TwoTextColumnsParagraphFieldsFragmentDoc = `
    fragment TwoTextColumnsParagraphFields on ParagraphTwoTextColumns {
  ...ParagraphFields
  title
  linksForColumn1 {
    ...LinkFields
  }
  linksForColumn2 {
    ...LinkFields
  }
  textForColumn1 {
    processed
  }
  textForColumn2 {
    processed
  }
  entity_type_behaviors {
    margin
  }
}
    `;
export const FindJobsParagraphFieldsFragmentDoc = `
    fragment FindJobsParagraphFields on ParagraphFindJobs {
  ... on ParagraphFindJobs {
    ...ParagraphFields
    perFunction {
      ...LinkFields
    }
    perSector {
      ...LinkFields
    }
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const FiveBlocksParagraphFieldsFragmentDoc = `
    fragment FiveBlocksParagraphFields on ParagraphFiveBlocks {
  ... on ParagraphFiveBlocks {
    ...ParagraphFields
    imageForBlock2 {
      ...MediaImageFields
    }
    imageForBlock3 {
      ...MediaImageFields
    }
    imageForBlock4 {
      ...MediaImageFields
    }
    imageForBlock5 {
      ...MediaImageFields
    }
    textForBlock1 {
      processed
    }
    textForBlock2 {
      processed
    }
    textForBlock3 {
      processed
    }
    textForBlock4 {
      processed
    }
    textForBlock5 {
      processed
    }
    titleForBlock1
    titleForBlock3
    titleForBlock4
    titleForBlock5
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const RecentBlogsParagraphFieldsFragmentDoc = `
    fragment RecentBlogsParagraphFields on ParagraphRecentBlogs {
  ... on ParagraphRecentBlogs {
    ...ParagraphFields
    title
    intro {
      processed
    }
    entity_type_behaviors {
      margin
    }
  }
}
    `;
export const StatisticItemParagraphFieldsFragmentDoc = `
    fragment StatisticItemParagraphFields on ParagraphStatisticItem {
  ...ParagraphFields
  statisticItemAmount
  statisticItemDescription {
    processed
  }
  statisticItemEntity
  entity_type_behaviors {
    margin
  }
}
    `;
export const StatisticParagraphFieldsFragmentDoc = `
    fragment StatisticParagraphFields on ParagraphStatistic {
  ...ParagraphFields
  statisticItems {
    ...StatisticItemParagraphFields
  }
  entity_type_behaviors {
    margin
  }
}
    `;
export const StandardPageFieldsFragmentDoc = `
    fragment StandardPageFields on NodePage {
  __typename
  id
  title
  mainMedia {
    ...MediaImageFields
  }
  intro {
    processed
  }
  availableLanguages {
    language
    url
  }
  content {
    ...CallToActionParagraphFields
    ...FaqOverviewParagraphFields
    ...HtmlParagraphFields
    ...ImageParagraphFields
    ...JobsListParagraphFields
    ...MediaTextParagraphFields
    ...NewsletterFormParagraphFields
    ...QuoteParagraphFields
    ...TeaserParagraphFields
    ...TestimonialsParagraphFields
    ...TextParagraphFields
    ...ThreeColumnBlockParagraphFields
    ...ThreeImagesParagraphFields
    ...TwoColumnBlockParagraphFields
    ...TwoTextColumnsParagraphFields
    ...VideoParagraphFields
    ...ChatbotSuggestionsParagraphFields
    ...FindJobsParagraphFields
    ...FiveBlocksParagraphFields
    ...RecentBlogsParagraphFields
    ...StatisticParagraphFields
  }
  metatag {
    ... on MetaTagLink {
      tag
      attributes {
        rel
        href
      }
    }
    ... on MetaTagValue {
      tag
      attributes {
        name
        content
      }
    }
    ... on MetaTagProperty {
      tag
      attributes {
        property
        content
      }
    }
  }
  ogImage {
    ...MediaImageFields
  }
  targetAudienceRef {
    id
    name
    tid
  }
}
    `;
export const SubParagraphFieldsFragmentDoc = `
    fragment SubParagraphFields on ParagraphInterface {
  __typename
  id
}
    `;
export const CreateApplicationDocument = `
    mutation createApplication($birthDate: Date, $desiredFunction: String, $email: String, $firstName: String, $language: String, $lastName: String, $newsletter: Boolean, $officeKey: String, $postalCode: String, $privacyStatement: Boolean, $jobId: String, $source: String, $telephone: String, $type: String) {
  createApplication(
    office_key: $officeKey
    source: $source
    job_id: $jobId
    email: $email
    first_name: $firstName
    last_name: $lastName
    desired_function: $desiredFunction
    postal_code: $postalCode
    telephone: $telephone
    newsletter: $newsletter
    language: $language
    privacy_statement: $privacyStatement
    birth_date: $birthDate
    type: $type
  ) {
    job_application_key
  }
}
    `;
export const UpdateApplicationDocument = `
    mutation updateApplication($jobId: String, $applicationKey: String!, $cv: String, $cvContentType: String, $jobEvent: String, $cvAnonymous: String, $cvAnonymousContentType: String, $motivation: String, $motivationContentType: String, $remarks: String) {
  updateApplication(
    job_id: $jobId
    application_key: $applicationKey
    cv: $cv
    cv_content_type: $cvContentType
    job_event: $jobEvent
    cv_anonymous: $cvAnonymous
    cv_anonymous_content_type: $cvAnonymousContentType
    motivation: $motivation
    motivation_content_type: $motivationContentType
    remarks: $remarks
  ) {
    job_application_key
  }
}
    `;
export const CreateUserApplicationDocument = `
    mutation createUserApplication($jobId: String!, $userKey: String!) {
  createUserApplication(job_id: $jobId, user_key: $userKey) {
    job_application_key
  }
}
    `;
export const InvalidateCacheDocument = `
    mutation invalidateCache($type: InvalidateCacheType!, $details: String) {
  invalidateCache(type: $type, details: $details) {
    status
  }
}
    `;
export const UpdateCandidateAvailabilityDocument = `
    mutation updateCandidateAvailability($userKey: String!, $available: Boolean!, $availableDate: String!) {
  updateCandidateAvailability(
    user_key: $userKey
    available: $available
    available_date: $availableDate
  ) {
    status
  }
}
    `;
export const UpdateCandidatePreferencesDocument = `
    mutation updateCandidatePreferences($userKey: String!, $optInForNewsletterPreferences: Boolean, $terms: String, $language: String, $latitude: String, $longitude: String, $location: String, $radius: String, $workRegimes: [String], $types: [String], $specialties: [String], $sectorGroups: [String], $jobstudent: Boolean) {
  updateCandidateSearchPreferences(
    user_key: $userKey
    opt_in_for_newsletter_preferences: $optInForNewsletterPreferences
    terms: $terms
    language: $language
    latitude: $latitude
    longitude: $longitude
    location: $location
    radius: $radius
    work_regimes: $workRegimes
    types: $types
    specialties: $specialties
    sector_groups: $sectorGroups
    jobstudent: $jobstudent
  ) {
    status
  }
}
    `;
export const UpdateCandidateDocument = `
    mutation updateCandidate($userKey: String!, $email: String!, $firstName: String!, $lastName: String!, $language: String!, $birthDate: Date!, $telephone: String!, $street: String, $houseNumber: String, $bus: String, $postalCode: String!, $city: String, $country: String, $profileComplete: Boolean!, $facebookId: String, $linkedinId: String, $workidId: String, $languageSkills: String, $officeKey: String, $educationalLevel: String, $transportTypes: [String], $workRegimes: [String], $contractTypes: [String], $transportType: String, $driversLicence: [String], $educations: [EducationInput], $workExperience: [WorkExperienceInput], $certificates: [CertificateInput], $properties: [String], $remarks: String) {
  updateCandidate(
    user_key: $userKey
    email: $email
    first_name: $firstName
    last_name: $lastName
    language: $language
    birth_date: $birthDate
    telephone: $telephone
    street: $street
    house_number: $houseNumber
    bus: $bus
    postal_code: $postalCode
    city: $city
    country: $country
    profile_complete: $profileComplete
    facebook_id: $facebookId
    linkedin_id: $linkedinId
    workid_id: $workidId
    language_skills: $languageSkills
    office_key: $officeKey
    educational_level: $educationalLevel
    transport_types: $transportTypes
    work_regimes: $workRegimes
    contract_types: $contractTypes
    transport_type: $transportType
    drivers_licence: $driversLicence
    educations: $educations
    work_experience: $workExperience
    certificates: $certificates
    properties: $properties
    remarks: $remarks
  ) {
    status
  }
}
    `;
export const AddDocumentDocument = `
    mutation addDocument($userKey: String, $documentTypeKey: String, $name: String, $document: String, $documentContentType: String) {
  addDocument(
    user_key: $userKey
    document_type_key: $documentTypeKey
    name: $name
    document: $document
    document_content_type: $documentContentType
  ) {
    status
  }
}
    `;
export const CreateNewsletterSubscriptionDocument = `
    mutation createNewsletterSubscription($email: String!, $firstName: String, $journeyId: String, $language: String, $lastName: String, $legalBasis: String, $name: String, $optIn: Boolean, $optInDate: String, $optInSource: String, $optOutDate: String, $optOutSource: String) {
  createNewsletterSubscription(
    email: $email
    first_name: $firstName
    journey_id: $journeyId
    language: $language
    last_name: $lastName
    legal_basis: $legalBasis
    name: $name
    opt_in: $optIn
    opt_in_date: $optInDate
    opt_in_source: $optInSource
    opt_out_date: $optOutDate
    opt_out_source: $optOutSource
  ) {
    user_key
  }
}
    `;
export const UpdateCandidateSavedJobsDocument = `
    mutation updateCandidateSavedJobs($userKey: String!, $savedJobs: String!) {
  updateCandidateSavedJobs(user_key: $userKey, saved_jobs: $savedJobs) {
    status
  }
}
    `;
export const CreateUserDocument = `
    mutation createUser($email: String!, $token: String!, $password: String!, $firstName: String!, $lastName: String!, $newsletter: Boolean!, $confirmed: Boolean!, $language: String!, $emailPageUrl: String!) {
  createUser(
    email: $email
    token: $token
    password: $password
    first_name: $firstName
    last_name: $lastName
    newsletter: $newsletter
    confirmed: $confirmed
    language: $language
    email_page_url: $emailPageUrl
  ) {
    user_key
    status
  }
}
    `;
export const CreateUserTokenDocument = `
    mutation createUserToken($email: String, $token: String, $url: String) {
  createUserToken(email: $email, token: $token, url: $url) {
    description
  }
}
    `;
export const ResetUserPasswordDocument = `
    mutation resetUserPassword($email: String, $token: String, $password: String) {
  resetUserPassword(email: $email, token: $token, password: $password) {
    description
    status
  }
}
    `;
export const ActivateUserDocument = `
    mutation activateUser($email: String, $token: String) {
  activateUser(email: $email, token: $token) {
    description
    status
  }
}
    `;
export const GetBannerImageDocument = `
    query getBannerImage($name: String!) {
  bannerImageByName(name: $name) {
    imageMediaImage {
      ...ImageFields
    }
  }
}
    ${ImageFieldsFragmentDoc}`;
export const GetBlogPageDocument = `
    query getBlogPage($nodeBlogId: ID!) {
  nodeBlog(id: $nodeBlogId) {
    ...BlogPageFields
  }
}
    ${BlogPageFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${DateTimeFieldsFragmentDoc}
${CallToActionParagraphFieldsFragmentDoc}
${ParagraphFieldsFragmentDoc}
${LinkFieldsFragmentDoc}
${TextParagraphFieldsFragmentDoc}
${ImageParagraphFieldsFragmentDoc}
${MediaTextParagraphFieldsFragmentDoc}
${MediaRemoteVideoFieldsFragmentDoc}
${MediaVideoFieldsFragmentDoc}
${VideoParagraphFieldsFragmentDoc}
${QuoteParagraphFieldsFragmentDoc}
${ChatbotSuggestionsParagraphFieldsFragmentDoc}`;
export const GetBlogOverviewPageDocument = `
    query getBlogOverviewPage($nodeBlogOverviewId: ID!) {
  nodeBlogOverview(id: $nodeBlogOverviewId) {
    ...BlogOverviewPageFields
  }
}
    ${BlogOverviewPageFieldsFragmentDoc}
${DateTimeFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const GetBlogPageTeaserDocument = `
    query getBlogPageTeaser($nodeBlogId: ID!) {
  nodeBlog(id: $nodeBlogId) {
    title
    path
    teaserMedia {
      ...MediaImageFields
    }
    intro {
      processed
    }
    langcode {
      id
    }
  }
}
    ${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const GetBlogOverviewDocument = `
    query getBlogOverview($page: Int, $langcode: String!, $field_blog_category_ref_target_id: [String], $field_target_audience_ref_target_id: String) {
  blogOverviewGraphql(
    page: $page
    filter: {langcode: $langcode, field_blog_category_ref_target_id: $field_blog_category_ref_target_id, field_target_audience_ref_target_id: $field_target_audience_ref_target_id}
  ) {
    description
    display
    id
    label
    langcode
    view
    results {
      ... on NodeBlog {
        title
        id
        created {
          timestamp
        }
        blogCategoryRef {
          ... on TermBlogCategories {
            name
            id
          }
        }
        path
        teaserMedia {
          ...MediaImageFields
        }
        intro {
          processed
        }
        langcode {
          id
        }
      }
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
    ${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const GetBlogCategoriesOverviewDocument = `
    query getBlogCategoriesOverview($langcode: String!, $field_target_audience_ref_target_id: String) {
  blogCategoryOverview(
    filter: {langcode: $langcode, field_target_audience_ref_target_id: $field_target_audience_ref_target_id}
  ) {
    description
    display
    id
    label
    langcode
    view
    results {
      __typename
      ... on TermBlogCategories {
        id
        tid
        name
      }
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
    `;
export const GetRecentBlogsOverviewDocument = `
    query getRecentBlogsOverview($langcode: String!, $field_target_audience_ref_target_id: String) {
  recentBlogsOverview(
    filter: {langcode: $langcode, field_target_audience_ref_target_id: $field_target_audience_ref_target_id}
  ) {
    description
    display
    id
    label
    langcode
    view
    results {
      __typename
      ... on NodeBlog {
        ...BlogPageFields
      }
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
    ${BlogPageFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${DateTimeFieldsFragmentDoc}
${CallToActionParagraphFieldsFragmentDoc}
${ParagraphFieldsFragmentDoc}
${LinkFieldsFragmentDoc}
${TextParagraphFieldsFragmentDoc}
${ImageParagraphFieldsFragmentDoc}
${MediaTextParagraphFieldsFragmentDoc}
${MediaRemoteVideoFieldsFragmentDoc}
${MediaVideoFieldsFragmentDoc}
${VideoParagraphFieldsFragmentDoc}
${QuoteParagraphFieldsFragmentDoc}
${ChatbotSuggestionsParagraphFieldsFragmentDoc}`;
export const GetBlogPageByUrlDocument = `
    query getBlogPageByUrl($langcode: String!, $path: String!) {
  route(langcode: $langcode, path: $path) {
    ... on RouteInternal {
      entity {
        ... on NodeBlog {
          ...BlogPageFields
        }
      }
    }
  }
}
    ${BlogPageFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${DateTimeFieldsFragmentDoc}
${CallToActionParagraphFieldsFragmentDoc}
${ParagraphFieldsFragmentDoc}
${LinkFieldsFragmentDoc}
${TextParagraphFieldsFragmentDoc}
${ImageParagraphFieldsFragmentDoc}
${MediaTextParagraphFieldsFragmentDoc}
${MediaRemoteVideoFieldsFragmentDoc}
${MediaVideoFieldsFragmentDoc}
${VideoParagraphFieldsFragmentDoc}
${QuoteParagraphFieldsFragmentDoc}
${ChatbotSuggestionsParagraphFieldsFragmentDoc}`;
export const GetBlogPagesForUrlsDocument = `
    query getBlogPagesForUrls($langcode: String, $first: Int, $after: Cursor) {
  nodeBlogs(langcode: $langcode, first: $first, after: $after) {
    nodes {
      id
      title
      path
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export const GetCandidateDocument = `
    query getCandidate($userKey: String!) {
  candidate(user_key: $userKey) {
    user_key
    email
    first_name
    name
    language
    birth_date
    telephone
    address {
      street
      number
      box
      locality
      postal_code
      country
    }
    office_key
    education_level
    transport_type
    transport_types
    contract_types
    profile_complete
    work_regimes
    drivers_licence
  }
}
    `;
export const GetCandidateDocumentsDocument = `
    query getCandidateDocuments($userKey: String!) {
  candidateDocuments(user_key: $userKey) {
    date_last_changed
    document_type
    name
  }
}
    `;
export const GetCandidateDocumentDocument = `
    query getCandidateDocument($userKey: String!, $documentType: String!) {
  candidateDocument(user_key: $userKey, document_type: $documentType) {
    document
    document_content_type
    name
  }
}
    `;
export const GetCandidateSearchPreferencesDocument = `
    query getCandidateSearchPreferences($userKey: String!) {
  candidateSearchPreferences(user_key: $userKey) {
    user_key
    opt_in_for_newsletter_preferences
    terms
    language
    latitude
    longitude
    location
    radius
    work_regimes
    types
    specialties
    sector_groups
    jobstudent
  }
}
    `;
export const GetCandidateAppointmentsDocument = `
    query getCandidateAppointments($userKey: String!) {
  candidateAppointments(user_key: $userKey) {
    date
    name
    interview_reason
    interviewer
    company
    address {
      street
      number
      box
      locality
      postal_code
      country
    }
  }
}
    `;
export const GetCandidateAvailabilityDocument = `
    query getCandidateAvailability($userKey: String!) {
  candidateAvailability(user_key: $userKey) {
    available
    available_date
  }
}
    `;
export const GetCandidateApplicationsDocument = `
    query getCandidateApplications($userKey: String!) {
  candidateApplications(user_key: $userKey) {
    key
    name
    employment_city
    status
  }
}
    `;
export const GetCandidateIntentionDocument = `
    query getCandidateIntention($userKey: String!) {
  candidateIntention(user_key: $userKey) {
    user_key
    email
    first_name
    name
  }
}
    `;
export const GetCandidateProfileScoreDataDocument = `
    query getCandidateProfileScoreData($userKey: String!) {
  candidate(user_key: $userKey) {
    user_key
    email
    first_name
    name
    language
    birth_date
    telephone
    address {
      street
      number
      box
      locality
      postal_code
      country
    }
    office_key
    education_level
    transport_type
    transport_types
    contract_types
    profile_complete
    work_regimes
    drivers_licence
    language_skills
    work_experience {
      job_type
    }
  }
  candidateDocuments(user_key: $userKey) {
    document_type
  }
  candidateSearchPreferences(user_key: $userKey) {
    user_key
    opt_in_for_newsletter_preferences
    terms
    language
    location
    radius
    work_regimes
    types
    specialties
    sector_groups
  }
}
    `;
export const GetCandidateSavedJobsDocument = `
    query getCandidateSavedJobs($userKey: String!) {
  candidateSavedJobs(user_key: $userKey)
}
    `;
export const GetFagSearchOverviewDocument = `
    query getFagSearchOverview($page: Int, $langcode: String!, $search: String, $category_uuid: String, $field_target_audience_ref: String) {
  faqSearch(
    page: $page
    filter: {langcode: $langcode, search: $search, category_uuid: $category_uuid, field_target_audience_ref: $field_target_audience_ref}
  ) {
    description
    display
    id
    label
    langcode
    view
    results {
      ... on NodeFaq {
        title
        path
        langcode {
          id
        }
        faqCategory {
          ... on NodeFaqCategory {
            ...FaqCategoryMinimalFields
          }
        }
      }
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
    ${FaqCategoryMinimalFieldsFragmentDoc}`;
export const GetFaqCategoriesOverviewDocument = `
    query getFaqCategoriesOverview($langcode: String!, $target_audience: String) {
  faqCategory(filter: {langcode: $langcode, target_audience: $target_audience}) {
    description
    display
    id
    label
    langcode
    view
    results {
      ... on NodeFaqCategory {
        title
        description {
          processed
        }
        icon
        path
        langcode {
          id
        }
      }
    }
    pageInfo {
      page
      pageSize
      total
    }
  }
}
    `;
export const SearchJobsDocument = `
    query searchJobs($keyword: String, $city: String, $maxDistance: String, $lat: String, $lng: String, $contractTypes: [String], $regimes: [String], $niches: [String], $functionGroups: [String], $sectors: [String], $officeId: String, $languages: [String], $limit: Int, $offset: Int, $studentJob: Boolean, $relatedId: String) {
  jobsSearch(
    keyword: $keyword
    city: $city
    max_distance: $maxDistance
    lat: $lat
    lng: $lng
    contractTypes: $contractTypes
    regimes: $regimes
    niches: $niches
    functionGroups: $functionGroups
    sectors: $sectors
    officeId: $officeId
    languages: $languages
    limit: $limit
    offset: $offset
    studentJob: $studentJob
    relatedJobId: $relatedId
  ) {
    from
    to
    total_results
    jobs {
      id
      city
      created_at
      description
      label
      internal
      regime
      salary_min
      salary_max
      salary_period
      sector_group
      specialty
      title
      type
      urgent
    }
    facets {
      name
      values {
        count
        value
      }
    }
  }
}
    `;
export const SearchRelatedJobsDocument = `
    query searchRelatedJobs($relatedJobId: String!, $limit: Int, $offset: Int) {
  jobsSearch(relatedJobId: $relatedJobId, limit: $limit, offset: $offset) {
    from
    to
    total_results
    jobs {
      id
      city
      created_at
      description
      label
      internal
      regime
      salary_min
      salary_max
      salary_period
      sector_group
      specialty
      title
      type
      urgent
    }
  }
}
    `;
export const GetJobDocument = `
    query getJob($jobId: String!, $langcode: String!) {
  job(id: $jobId, langcode: $langcode) {
    id
    city
    created_at
    description
    end_date
    function
    function_group
    internal
    jobstudent
    label
    language
    offer
    office_id
    postal_code
    reference
    regime
    salary_max
    salary_min
    salary_period
    sector_group
    specialty
    status
    street_address
    title
    type
    urgent
    closed
  }
}
    `;
export const GetJobsForUrlsDocument = `
    query getJobsForUrls($limit: Int, $offset: Int, $languages: [String]) {
  jobsSearch(limit: $limit, offset: $offset, languages: $languages) {
    from
    to
    total_results
    jobs {
      id
      title
      reference
      updated_at
    }
  }
}
    `;
export const GetJobOptionsDocument = `
    query getJobOptions($langcode: String!) {
  jobOptionLists(langcode: $langcode) {
    work_regimes {
      ...JobOption
    }
    contract_types {
      ...JobOption
    }
    specialties {
      ...JobOption
    }
    sector_groups {
      ...JobOption
    }
    education_levels {
      ...JobOption
    }
    transport_types {
      ...JobOption
    }
    function_groups {
      ...JobOption
    }
    document_types {
      ...JobOption
    }
    language_skills {
      ...JobOption
    }
    drivers_licences {
      ...JobOption
    }
    certificates {
      ...JobOption
    }
  }
}
    ${JobOptionFragmentDoc}`;
export const GetMenusDocument = `
    query getMenus($langcode: String) {
  mainMenuEmployee: menu(name: MAIN_MENU_EMPLOYEE, langcode: $langcode) {
    ...MenuFields
  }
  secondaryMenuEmployee: menu(name: SECONDARY_MENU_EMPLOYEE, langcode: $langcode) {
    ...MenuFields
  }
  mainMenuEmployer: menu(name: MAIN_MENU_EMPLOYER, langcode: $langcode) {
    ...MenuFields
  }
  secondaryMenuEmployer: menu(name: SECONDARY_MENU_EMPLOYER, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuEmployee: menu(name: FOR_EMPLOYEES_MENU, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuEmployer: menu(name: FOR_EMPLOYERS_MENU, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuAbout: menu(name: MORE_ABOUT_VIVALDIS, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuJobsPerRegion: menu(name: JOBS_PER_REGION_MENU, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuJobsPerSector: menu(name: JOBS_PER_SECTOR_MENU, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuExpertises: menu(name: EXPERTISES_MENU, langcode: $langcode) {
    ...MenuFields
  }
  footerMenuServices: menu(name: SERVICES_MENU, langcode: $langcode) {
    ...MenuFields
  }
  legalMenu: menu(name: LEGAL, langcode: $langcode) {
    ...MenuFields
  }
}
    ${MenuFieldsFragmentDoc}`;
export const GetOfficesDocument = `
    query getOffices($langcode: String!) {
  offices(langcode: $langcode) {
    id
    title
    opening_hours {
      day
      hours {
        opening_hour
        closing_hour
      }
    }
    address {
      street
      number
      box
      locality
      postal_code
      country
    }
    speciality
    longitude
    latitude
    telephone
    email
    photo
  }
}
    `;
export const GetOfficesForUrlsDocument = `
    query getOfficesForUrls($langcode: String!) {
  offices(langcode: $langcode) {
    id
    title
  }
}
    `;
export const GetOfficePageDocument = `
    query getOfficePage($officeId: String!, $langcode: String!) {
  office(id: $officeId, langcode: $langcode) {
    id
    title
    description
    latitude
    longitude
    address {
      street
      number
      box
      locality
      postal_code
      country
    }
    staff {
      staff_key
      first_name
      photo_url
      photo_last_changed
    }
    opening_hours {
      day
      hours {
        opening_hour
        closing_hour
      }
    }
    speciality
    place_id
    language
    telephone
    chat
    linkedin
    instagram
    facebook
    twitter
    email
    photo
    description
  }
}
    `;
export const GetOfficeSummaryDocument = `
    query getOfficeSummary($officeId: String!, $langcode: String!) {
  office(id: $officeId, langcode: $langcode) {
    id
    title
    staff {
      staff_key
      first_name
      photo_url
      photo_last_changed
    }
    speciality
    place_id
    language
    telephone
    chat
    linkedin
    instagram
    facebook
    twitter
  }
}
    `;
export const GetStandardPageDocument = `
    query getStandardPage($nodePageId: ID!) {
  nodePage(id: $nodePageId) {
    ...StandardPageFields
  }
}
    ${StandardPageFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${CallToActionParagraphFieldsFragmentDoc}
${ParagraphFieldsFragmentDoc}
${LinkFieldsFragmentDoc}
${FaqOverviewParagraphFieldsFragmentDoc}
${HtmlParagraphFieldsFragmentDoc}
${ImageParagraphFieldsFragmentDoc}
${JobsListParagraphFieldsFragmentDoc}
${MediaTextParagraphFieldsFragmentDoc}
${MediaRemoteVideoFieldsFragmentDoc}
${MediaVideoFieldsFragmentDoc}
${NewsletterFormParagraphFieldsFragmentDoc}
${QuoteParagraphFieldsFragmentDoc}
${TeaserParagraphFieldsFragmentDoc}
${TestimonialsParagraphFieldsFragmentDoc}
${TextParagraphFieldsFragmentDoc}
${ThreeColumnBlockParagraphFieldsFragmentDoc}
${ThreeImagesParagraphFieldsFragmentDoc}
${TwoColumnBlockParagraphFieldsFragmentDoc}
${TwoTextColumnsParagraphFieldsFragmentDoc}
${VideoParagraphFieldsFragmentDoc}
${ChatbotSuggestionsParagraphFieldsFragmentDoc}
${FindJobsParagraphFieldsFragmentDoc}
${FiveBlocksParagraphFieldsFragmentDoc}
${RecentBlogsParagraphFieldsFragmentDoc}
${StatisticParagraphFieldsFragmentDoc}
${StatisticItemParagraphFieldsFragmentDoc}`;
export const GetStandardPageTeaserDocument = `
    query getStandardPageTeaser($nodePageId: ID!) {
  nodePage(id: $nodePageId) {
    title
    path
    teaserMedia {
      ...MediaImageFields
    }
    intro {
      processed
    }
    langcode {
      id
    }
  }
}
    ${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const GetStandardPageByUrlDocument = `
    query getStandardPageByUrl($langcode: String!, $path: String!, $secret: String) {
  route(langcode: $langcode, path: $path, secret: $secret) {
    ... on RouteInternal {
      entity {
        ... on NodePage {
          ...StandardPageFields
        }
        ... on NodeBlog {
          ...BlogPageFields
        }
        ... on NodeFaq {
          ...FaqPageFields
        }
        ... on NodeFaqCategory {
          ...FaqCategoryPageFields
        }
        ... on NodeBlogOverview {
          ...BlogOverviewPageFields
        }
        ... on NodeFaqOverview {
          ...FaqOverviewPageFields
        }
        ... on NodeFaqCategoryOverview {
          ...FaqCategoryOverviewPageFields
        }
      }
    }
    ... on RouteRedirect {
      __typename
      internal
      redirect
      status
      url
    }
    ... on Error {
      __typename
      status
    }
  }
}
    ${StandardPageFieldsFragmentDoc}
${MediaImageFieldsFragmentDoc}
${ImageFieldsFragmentDoc}
${CallToActionParagraphFieldsFragmentDoc}
${ParagraphFieldsFragmentDoc}
${LinkFieldsFragmentDoc}
${FaqOverviewParagraphFieldsFragmentDoc}
${HtmlParagraphFieldsFragmentDoc}
${ImageParagraphFieldsFragmentDoc}
${JobsListParagraphFieldsFragmentDoc}
${MediaTextParagraphFieldsFragmentDoc}
${MediaRemoteVideoFieldsFragmentDoc}
${MediaVideoFieldsFragmentDoc}
${NewsletterFormParagraphFieldsFragmentDoc}
${QuoteParagraphFieldsFragmentDoc}
${TeaserParagraphFieldsFragmentDoc}
${TestimonialsParagraphFieldsFragmentDoc}
${TextParagraphFieldsFragmentDoc}
${ThreeColumnBlockParagraphFieldsFragmentDoc}
${ThreeImagesParagraphFieldsFragmentDoc}
${TwoColumnBlockParagraphFieldsFragmentDoc}
${TwoTextColumnsParagraphFieldsFragmentDoc}
${VideoParagraphFieldsFragmentDoc}
${ChatbotSuggestionsParagraphFieldsFragmentDoc}
${FindJobsParagraphFieldsFragmentDoc}
${FiveBlocksParagraphFieldsFragmentDoc}
${RecentBlogsParagraphFieldsFragmentDoc}
${StatisticParagraphFieldsFragmentDoc}
${StatisticItemParagraphFieldsFragmentDoc}
${BlogPageFieldsFragmentDoc}
${DateTimeFieldsFragmentDoc}
${FaqPageFieldsFragmentDoc}
${FaqCategoryMinimalFieldsFragmentDoc}
${FaqCategoryPageFieldsFragmentDoc}
${BlogOverviewPageFieldsFragmentDoc}
${FaqOverviewPageFieldsFragmentDoc}
${FaqCategoryOverviewPageFieldsFragmentDoc}`;
export const GetStandardPagesForUrlsDocument = `
    query getStandardPagesForUrls($langcode: String, $first: Int, $after: Cursor) {
  nodePages(langcode: $langcode, first: $first, after: $after) {
    nodes {
      id
      title
      path
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export const GetUserByEmailDocument = `
    query getUserByEmail($email: String!) {
  userByEmail(email: $email) {
    approved
    email
    first_name
    language
    name
    user_key
    password
  }
}
    `;
export const GetUserBySocialsDocument = `
    query getUserBySocials($salesforce_id: String!) {
  userBySocials(salesforce_id: $salesforce_id) {
    approved
    email
    first_name
    language
    name
    user_key
  }
}
    `;
export type Requester<C = {}> = <R, V>(
  doc: string,
  vars?: V,
  options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    createApplication(
      variables?: CreateApplicationMutationVariables,
      options?: C,
    ): Promise<CreateApplicationMutation> {
      return requester<
        CreateApplicationMutation,
        CreateApplicationMutationVariables
      >(
        CreateApplicationDocument,
        variables,
        options,
      ) as Promise<CreateApplicationMutation>;
    },
    updateApplication(
      variables: UpdateApplicationMutationVariables,
      options?: C,
    ): Promise<UpdateApplicationMutation> {
      return requester<
        UpdateApplicationMutation,
        UpdateApplicationMutationVariables
      >(
        UpdateApplicationDocument,
        variables,
        options,
      ) as Promise<UpdateApplicationMutation>;
    },
    createUserApplication(
      variables: CreateUserApplicationMutationVariables,
      options?: C,
    ): Promise<CreateUserApplicationMutation> {
      return requester<
        CreateUserApplicationMutation,
        CreateUserApplicationMutationVariables
      >(
        CreateUserApplicationDocument,
        variables,
        options,
      ) as Promise<CreateUserApplicationMutation>;
    },
    invalidateCache(
      variables: InvalidateCacheMutationVariables,
      options?: C,
    ): Promise<InvalidateCacheMutation> {
      return requester<
        InvalidateCacheMutation,
        InvalidateCacheMutationVariables
      >(
        InvalidateCacheDocument,
        variables,
        options,
      ) as Promise<InvalidateCacheMutation>;
    },
    updateCandidateAvailability(
      variables: UpdateCandidateAvailabilityMutationVariables,
      options?: C,
    ): Promise<UpdateCandidateAvailabilityMutation> {
      return requester<
        UpdateCandidateAvailabilityMutation,
        UpdateCandidateAvailabilityMutationVariables
      >(
        UpdateCandidateAvailabilityDocument,
        variables,
        options,
      ) as Promise<UpdateCandidateAvailabilityMutation>;
    },
    updateCandidatePreferences(
      variables: UpdateCandidatePreferencesMutationVariables,
      options?: C,
    ): Promise<UpdateCandidatePreferencesMutation> {
      return requester<
        UpdateCandidatePreferencesMutation,
        UpdateCandidatePreferencesMutationVariables
      >(
        UpdateCandidatePreferencesDocument,
        variables,
        options,
      ) as Promise<UpdateCandidatePreferencesMutation>;
    },
    updateCandidate(
      variables: UpdateCandidateMutationVariables,
      options?: C,
    ): Promise<UpdateCandidateMutation> {
      return requester<
        UpdateCandidateMutation,
        UpdateCandidateMutationVariables
      >(
        UpdateCandidateDocument,
        variables,
        options,
      ) as Promise<UpdateCandidateMutation>;
    },
    addDocument(
      variables?: AddDocumentMutationVariables,
      options?: C,
    ): Promise<AddDocumentMutation> {
      return requester<AddDocumentMutation, AddDocumentMutationVariables>(
        AddDocumentDocument,
        variables,
        options,
      ) as Promise<AddDocumentMutation>;
    },
    createNewsletterSubscription(
      variables: CreateNewsletterSubscriptionMutationVariables,
      options?: C,
    ): Promise<CreateNewsletterSubscriptionMutation> {
      return requester<
        CreateNewsletterSubscriptionMutation,
        CreateNewsletterSubscriptionMutationVariables
      >(
        CreateNewsletterSubscriptionDocument,
        variables,
        options,
      ) as Promise<CreateNewsletterSubscriptionMutation>;
    },
    updateCandidateSavedJobs(
      variables: UpdateCandidateSavedJobsMutationVariables,
      options?: C,
    ): Promise<UpdateCandidateSavedJobsMutation> {
      return requester<
        UpdateCandidateSavedJobsMutation,
        UpdateCandidateSavedJobsMutationVariables
      >(
        UpdateCandidateSavedJobsDocument,
        variables,
        options,
      ) as Promise<UpdateCandidateSavedJobsMutation>;
    },
    createUser(
      variables: CreateUserMutationVariables,
      options?: C,
    ): Promise<CreateUserMutation> {
      return requester<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables,
        options,
      ) as Promise<CreateUserMutation>;
    },
    createUserToken(
      variables?: CreateUserTokenMutationVariables,
      options?: C,
    ): Promise<CreateUserTokenMutation> {
      return requester<
        CreateUserTokenMutation,
        CreateUserTokenMutationVariables
      >(
        CreateUserTokenDocument,
        variables,
        options,
      ) as Promise<CreateUserTokenMutation>;
    },
    resetUserPassword(
      variables?: ResetUserPasswordMutationVariables,
      options?: C,
    ): Promise<ResetUserPasswordMutation> {
      return requester<
        ResetUserPasswordMutation,
        ResetUserPasswordMutationVariables
      >(
        ResetUserPasswordDocument,
        variables,
        options,
      ) as Promise<ResetUserPasswordMutation>;
    },
    activateUser(
      variables?: ActivateUserMutationVariables,
      options?: C,
    ): Promise<ActivateUserMutation> {
      return requester<ActivateUserMutation, ActivateUserMutationVariables>(
        ActivateUserDocument,
        variables,
        options,
      ) as Promise<ActivateUserMutation>;
    },
    getBannerImage(
      variables: GetBannerImageQueryVariables,
      options?: C,
    ): Promise<GetBannerImageQuery> {
      return requester<GetBannerImageQuery, GetBannerImageQueryVariables>(
        GetBannerImageDocument,
        variables,
        options,
      ) as Promise<GetBannerImageQuery>;
    },
    getBlogPage(
      variables: GetBlogPageQueryVariables,
      options?: C,
    ): Promise<GetBlogPageQuery> {
      return requester<GetBlogPageQuery, GetBlogPageQueryVariables>(
        GetBlogPageDocument,
        variables,
        options,
      ) as Promise<GetBlogPageQuery>;
    },
    getBlogOverviewPage(
      variables: GetBlogOverviewPageQueryVariables,
      options?: C,
    ): Promise<GetBlogOverviewPageQuery> {
      return requester<
        GetBlogOverviewPageQuery,
        GetBlogOverviewPageQueryVariables
      >(
        GetBlogOverviewPageDocument,
        variables,
        options,
      ) as Promise<GetBlogOverviewPageQuery>;
    },
    getBlogPageTeaser(
      variables: GetBlogPageTeaserQueryVariables,
      options?: C,
    ): Promise<GetBlogPageTeaserQuery> {
      return requester<GetBlogPageTeaserQuery, GetBlogPageTeaserQueryVariables>(
        GetBlogPageTeaserDocument,
        variables,
        options,
      ) as Promise<GetBlogPageTeaserQuery>;
    },
    getBlogOverview(
      variables: GetBlogOverviewQueryVariables,
      options?: C,
    ): Promise<GetBlogOverviewQuery> {
      return requester<GetBlogOverviewQuery, GetBlogOverviewQueryVariables>(
        GetBlogOverviewDocument,
        variables,
        options,
      ) as Promise<GetBlogOverviewQuery>;
    },
    getBlogCategoriesOverview(
      variables: GetBlogCategoriesOverviewQueryVariables,
      options?: C,
    ): Promise<GetBlogCategoriesOverviewQuery> {
      return requester<
        GetBlogCategoriesOverviewQuery,
        GetBlogCategoriesOverviewQueryVariables
      >(
        GetBlogCategoriesOverviewDocument,
        variables,
        options,
      ) as Promise<GetBlogCategoriesOverviewQuery>;
    },
    getRecentBlogsOverview(
      variables: GetRecentBlogsOverviewQueryVariables,
      options?: C,
    ): Promise<GetRecentBlogsOverviewQuery> {
      return requester<
        GetRecentBlogsOverviewQuery,
        GetRecentBlogsOverviewQueryVariables
      >(
        GetRecentBlogsOverviewDocument,
        variables,
        options,
      ) as Promise<GetRecentBlogsOverviewQuery>;
    },
    getBlogPageByUrl(
      variables: GetBlogPageByUrlQueryVariables,
      options?: C,
    ): Promise<GetBlogPageByUrlQuery> {
      return requester<GetBlogPageByUrlQuery, GetBlogPageByUrlQueryVariables>(
        GetBlogPageByUrlDocument,
        variables,
        options,
      ) as Promise<GetBlogPageByUrlQuery>;
    },
    getBlogPagesForUrls(
      variables?: GetBlogPagesForUrlsQueryVariables,
      options?: C,
    ): Promise<GetBlogPagesForUrlsQuery> {
      return requester<
        GetBlogPagesForUrlsQuery,
        GetBlogPagesForUrlsQueryVariables
      >(
        GetBlogPagesForUrlsDocument,
        variables,
        options,
      ) as Promise<GetBlogPagesForUrlsQuery>;
    },
    getCandidate(
      variables: GetCandidateQueryVariables,
      options?: C,
    ): Promise<GetCandidateQuery> {
      return requester<GetCandidateQuery, GetCandidateQueryVariables>(
        GetCandidateDocument,
        variables,
        options,
      ) as Promise<GetCandidateQuery>;
    },
    getCandidateDocuments(
      variables: GetCandidateDocumentsQueryVariables,
      options?: C,
    ): Promise<GetCandidateDocumentsQuery> {
      return requester<
        GetCandidateDocumentsQuery,
        GetCandidateDocumentsQueryVariables
      >(
        GetCandidateDocumentsDocument,
        variables,
        options,
      ) as Promise<GetCandidateDocumentsQuery>;
    },
    getCandidateDocument(
      variables: GetCandidateDocumentQueryVariables,
      options?: C,
    ): Promise<GetCandidateDocumentQuery> {
      return requester<
        GetCandidateDocumentQuery,
        GetCandidateDocumentQueryVariables
      >(
        GetCandidateDocumentDocument,
        variables,
        options,
      ) as Promise<GetCandidateDocumentQuery>;
    },
    getCandidateSearchPreferences(
      variables: GetCandidateSearchPreferencesQueryVariables,
      options?: C,
    ): Promise<GetCandidateSearchPreferencesQuery> {
      return requester<
        GetCandidateSearchPreferencesQuery,
        GetCandidateSearchPreferencesQueryVariables
      >(
        GetCandidateSearchPreferencesDocument,
        variables,
        options,
      ) as Promise<GetCandidateSearchPreferencesQuery>;
    },
    getCandidateAppointments(
      variables: GetCandidateAppointmentsQueryVariables,
      options?: C,
    ): Promise<GetCandidateAppointmentsQuery> {
      return requester<
        GetCandidateAppointmentsQuery,
        GetCandidateAppointmentsQueryVariables
      >(
        GetCandidateAppointmentsDocument,
        variables,
        options,
      ) as Promise<GetCandidateAppointmentsQuery>;
    },
    getCandidateAvailability(
      variables: GetCandidateAvailabilityQueryVariables,
      options?: C,
    ): Promise<GetCandidateAvailabilityQuery> {
      return requester<
        GetCandidateAvailabilityQuery,
        GetCandidateAvailabilityQueryVariables
      >(
        GetCandidateAvailabilityDocument,
        variables,
        options,
      ) as Promise<GetCandidateAvailabilityQuery>;
    },
    getCandidateApplications(
      variables: GetCandidateApplicationsQueryVariables,
      options?: C,
    ): Promise<GetCandidateApplicationsQuery> {
      return requester<
        GetCandidateApplicationsQuery,
        GetCandidateApplicationsQueryVariables
      >(
        GetCandidateApplicationsDocument,
        variables,
        options,
      ) as Promise<GetCandidateApplicationsQuery>;
    },
    getCandidateIntention(
      variables: GetCandidateIntentionQueryVariables,
      options?: C,
    ): Promise<GetCandidateIntentionQuery> {
      return requester<
        GetCandidateIntentionQuery,
        GetCandidateIntentionQueryVariables
      >(
        GetCandidateIntentionDocument,
        variables,
        options,
      ) as Promise<GetCandidateIntentionQuery>;
    },
    getCandidateProfileScoreData(
      variables: GetCandidateProfileScoreDataQueryVariables,
      options?: C,
    ): Promise<GetCandidateProfileScoreDataQuery> {
      return requester<
        GetCandidateProfileScoreDataQuery,
        GetCandidateProfileScoreDataQueryVariables
      >(
        GetCandidateProfileScoreDataDocument,
        variables,
        options,
      ) as Promise<GetCandidateProfileScoreDataQuery>;
    },
    getCandidateSavedJobs(
      variables: GetCandidateSavedJobsQueryVariables,
      options?: C,
    ): Promise<GetCandidateSavedJobsQuery> {
      return requester<
        GetCandidateSavedJobsQuery,
        GetCandidateSavedJobsQueryVariables
      >(
        GetCandidateSavedJobsDocument,
        variables,
        options,
      ) as Promise<GetCandidateSavedJobsQuery>;
    },
    getFagSearchOverview(
      variables: GetFagSearchOverviewQueryVariables,
      options?: C,
    ): Promise<GetFagSearchOverviewQuery> {
      return requester<
        GetFagSearchOverviewQuery,
        GetFagSearchOverviewQueryVariables
      >(
        GetFagSearchOverviewDocument,
        variables,
        options,
      ) as Promise<GetFagSearchOverviewQuery>;
    },
    getFaqCategoriesOverview(
      variables: GetFaqCategoriesOverviewQueryVariables,
      options?: C,
    ): Promise<GetFaqCategoriesOverviewQuery> {
      return requester<
        GetFaqCategoriesOverviewQuery,
        GetFaqCategoriesOverviewQueryVariables
      >(
        GetFaqCategoriesOverviewDocument,
        variables,
        options,
      ) as Promise<GetFaqCategoriesOverviewQuery>;
    },
    searchJobs(
      variables?: SearchJobsQueryVariables,
      options?: C,
    ): Promise<SearchJobsQuery> {
      return requester<SearchJobsQuery, SearchJobsQueryVariables>(
        SearchJobsDocument,
        variables,
        options,
      ) as Promise<SearchJobsQuery>;
    },
    searchRelatedJobs(
      variables: SearchRelatedJobsQueryVariables,
      options?: C,
    ): Promise<SearchRelatedJobsQuery> {
      return requester<SearchRelatedJobsQuery, SearchRelatedJobsQueryVariables>(
        SearchRelatedJobsDocument,
        variables,
        options,
      ) as Promise<SearchRelatedJobsQuery>;
    },
    getJob(variables: GetJobQueryVariables, options?: C): Promise<GetJobQuery> {
      return requester<GetJobQuery, GetJobQueryVariables>(
        GetJobDocument,
        variables,
        options,
      ) as Promise<GetJobQuery>;
    },
    getJobsForUrls(
      variables?: GetJobsForUrlsQueryVariables,
      options?: C,
    ): Promise<GetJobsForUrlsQuery> {
      return requester<GetJobsForUrlsQuery, GetJobsForUrlsQueryVariables>(
        GetJobsForUrlsDocument,
        variables,
        options,
      ) as Promise<GetJobsForUrlsQuery>;
    },
    getJobOptions(
      variables: GetJobOptionsQueryVariables,
      options?: C,
    ): Promise<GetJobOptionsQuery> {
      return requester<GetJobOptionsQuery, GetJobOptionsQueryVariables>(
        GetJobOptionsDocument,
        variables,
        options,
      ) as Promise<GetJobOptionsQuery>;
    },
    getMenus(
      variables?: GetMenusQueryVariables,
      options?: C,
    ): Promise<GetMenusQuery> {
      return requester<GetMenusQuery, GetMenusQueryVariables>(
        GetMenusDocument,
        variables,
        options,
      ) as Promise<GetMenusQuery>;
    },
    getOffices(
      variables: GetOfficesQueryVariables,
      options?: C,
    ): Promise<GetOfficesQuery> {
      return requester<GetOfficesQuery, GetOfficesQueryVariables>(
        GetOfficesDocument,
        variables,
        options,
      ) as Promise<GetOfficesQuery>;
    },
    getOfficesForUrls(
      variables: GetOfficesForUrlsQueryVariables,
      options?: C,
    ): Promise<GetOfficesForUrlsQuery> {
      return requester<GetOfficesForUrlsQuery, GetOfficesForUrlsQueryVariables>(
        GetOfficesForUrlsDocument,
        variables,
        options,
      ) as Promise<GetOfficesForUrlsQuery>;
    },
    getOfficePage(
      variables: GetOfficePageQueryVariables,
      options?: C,
    ): Promise<GetOfficePageQuery> {
      return requester<GetOfficePageQuery, GetOfficePageQueryVariables>(
        GetOfficePageDocument,
        variables,
        options,
      ) as Promise<GetOfficePageQuery>;
    },
    getOfficeSummary(
      variables: GetOfficeSummaryQueryVariables,
      options?: C,
    ): Promise<GetOfficeSummaryQuery> {
      return requester<GetOfficeSummaryQuery, GetOfficeSummaryQueryVariables>(
        GetOfficeSummaryDocument,
        variables,
        options,
      ) as Promise<GetOfficeSummaryQuery>;
    },
    getStandardPage(
      variables: GetStandardPageQueryVariables,
      options?: C,
    ): Promise<GetStandardPageQuery> {
      return requester<GetStandardPageQuery, GetStandardPageQueryVariables>(
        GetStandardPageDocument,
        variables,
        options,
      ) as Promise<GetStandardPageQuery>;
    },
    getStandardPageTeaser(
      variables: GetStandardPageTeaserQueryVariables,
      options?: C,
    ): Promise<GetStandardPageTeaserQuery> {
      return requester<
        GetStandardPageTeaserQuery,
        GetStandardPageTeaserQueryVariables
      >(
        GetStandardPageTeaserDocument,
        variables,
        options,
      ) as Promise<GetStandardPageTeaserQuery>;
    },
    getStandardPageByUrl(
      variables: GetStandardPageByUrlQueryVariables,
      options?: C,
    ): Promise<GetStandardPageByUrlQuery> {
      return requester<
        GetStandardPageByUrlQuery,
        GetStandardPageByUrlQueryVariables
      >(
        GetStandardPageByUrlDocument,
        variables,
        options,
      ) as Promise<GetStandardPageByUrlQuery>;
    },
    getStandardPagesForUrls(
      variables?: GetStandardPagesForUrlsQueryVariables,
      options?: C,
    ): Promise<GetStandardPagesForUrlsQuery> {
      return requester<
        GetStandardPagesForUrlsQuery,
        GetStandardPagesForUrlsQueryVariables
      >(
        GetStandardPagesForUrlsDocument,
        variables,
        options,
      ) as Promise<GetStandardPagesForUrlsQuery>;
    },
    getUserByEmail(
      variables: GetUserByEmailQueryVariables,
      options?: C,
    ): Promise<GetUserByEmailQuery> {
      return requester<GetUserByEmailQuery, GetUserByEmailQueryVariables>(
        GetUserByEmailDocument,
        variables,
        options,
      ) as Promise<GetUserByEmailQuery>;
    },
    getUserBySocials(
      variables: GetUserBySocialsQueryVariables,
      options?: C,
    ): Promise<GetUserBySocialsQuery> {
      return requester<GetUserBySocialsQuery, GetUserBySocialsQueryVariables>(
        GetUserBySocialsDocument,
        variables,
        options,
      ) as Promise<GetUserBySocialsQuery>;
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
