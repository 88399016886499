import { cache } from "react";

import { SDK } from "@/graphql/sdk";
import { Job } from "@/graphql/sdk/__generated__";
import { AllowedLanguages } from "@/types/generic";

type Variables<TFn extends (...args) => unknown> = Parameters<TFn>[0];

const DEFAULT_CACHE_REVALIDATE = 3600; // 1 hour

export const getStandardPageTeaser = async (
  variables: Variables<typeof SDK.getStandardPageTeaser>,
) => {
  return await SDK.getStandardPageTeaser(variables, {
    next: {
      revalidate: DEFAULT_CACHE_REVALIDATE,
      tags: [`standard_page_teaser:${variables.nodePageId}`],
    },
  });
};

export const getStandardPageByUrl = cache(
  async (variables: { langcode: string; path: string; secret: string }) => {
    return await SDK.getStandardPageByUrl(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`standard_page:${variables.path}`],
      },
    });
  },
);

export const getBlogPage = cache(
  async (variables: Variables<typeof SDK.getBlogPage>) => {
    return await SDK.getBlogPage(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`standard_page:${variables.nodeBlogId}`],
      },
    });
  },
);

export const getBlogPageByUrl = cache(
  async (variables: Variables<typeof SDK.getBlogPageByUrl>) => {
    return await SDK.getBlogPageByUrl(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`standard_page:${variables.path}`],
      },
    });
  },
);

export const getStandardPagesForUrls = cache(
  async (variables: Variables<typeof SDK.getStandardPagesForUrls>) => {
    return await SDK.getStandardPagesForUrls(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`standard_pages_urls`],
      },
    });
  },
);

export const getBlogOverview = cache(
  async (variables: Variables<typeof SDK.getBlogOverview>) => {
    return await SDK.getBlogOverview(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`blog_overview`],
      },
    });
  },
);

export const getBlogCategoriesOverview = cache(
  async (variables: Variables<typeof SDK.getBlogCategoriesOverview>) => {
    return await SDK.getBlogCategoriesOverview(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`blog_overview`],
      },
    });
  },
);

export const getRecentBlogsOverview = cache(
  async (variables: Variables<typeof SDK.getRecentBlogsOverview>) => {
    return await SDK.getRecentBlogsOverview(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`paragraph_recent_jobs`],
      },
    });
  },
);

export const getFaqSearchOverview = cache(
  async (variables: Variables<typeof SDK.getFagSearchOverview>) => {
    return await SDK.getFagSearchOverview(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`fag_search_overview`],
      },
    });
  },
);

export const getFaqCategoriesOverview = cache(
  async (variables: Variables<typeof SDK.getFaqCategoriesOverview>) => {
    return await SDK.getFaqCategoriesOverview(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`fag_search_overview`],
      },
    });
  },
);

export const searchJobs = async (
  variables: Variables<typeof SDK.searchJobs>,
) => {
  return await SDK.searchJobs(variables, {
    next: {
      revalidate: 300, // 5 minutes
    },
  });
};

// Returns all job properties needed to build URLs
export const getJobsForUrls = async (
  variables: Variables<typeof SDK.getJobsForUrls>,
) => {
  const allJobs: Job[] = []; // Initialize an array to store all job results
  let offset = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // Create a new set of variables for each request
    const newVariables = {
      ...variables,
      offset,
    };

    const response = await SDK.getJobsForUrls(newVariables, {
      next: {
        revalidate: 300,
      },
    });

    if (!response.jobsSearch) {
      break;
    }

    const { to, total_results, jobs } = response.jobsSearch;

    // Append the jobs from the current page to the allJobs array
    allJobs.push(...jobs);

    // Check if there are more jobs to fetch
    if (to < total_results) {
      offset = to;
    } else {
      break; // No more jobs to fetch
    }
  }

  return {
    total_results: allJobs.length,
    jobs: allJobs,
  };
};

export const searchRelatedJobs = cache(
  async (variables: Variables<typeof SDK.searchRelatedJobs>) => {
    return await SDK.searchRelatedJobs(variables, {
      next: {
        revalidate: 300,
        tags: [`related_jobs:${variables.relatedJobId}`],
      },
    });
  },
);

export const searchOfficeJobs = async (variables: {
  limit: number;
  officeId: string;
}) => {
  return await SDK.searchJobs(variables, {
    next: {
      revalidate: 300,
      tags: [`office_jobs:${variables.officeId}`],
    },
  });
};

export const getJobPage = cache(
  async (variables: Variables<typeof SDK.getJob>) => {
    return await SDK.getJob(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`job_page:${variables.jobId}`],
      },
    });
  },
);

export const getAllOffices = cache(
  async (variables: Variables<typeof SDK.getOffices>) => {
    return await SDK.getOffices(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`offices`],
      },
    });
  },
);

export const getOfficesForUrls = cache(
  async (variables: Variables<typeof SDK.getOffices>) => {
    return await SDK.getOfficesForUrls(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`offices_urls`],
      },
    });
  },
);

export const getOfficePage = cache(
  async (variables: Variables<typeof SDK.getOfficePage>) => {
    return await SDK.getOfficePage(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`office_page:${variables.officeId}:${variables.langcode}`],
      },
    });
  },
);

export const getOfficeSummary = cache(
  async (variables: Variables<typeof SDK.getOfficeSummary>) => {
    return await SDK.getOfficePage(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`office_summary:${variables.officeId}`],
      },
    });
  },
);

export const getMenus = cache(async (locale: AllowedLanguages) => {
  return await SDK.getMenus(
    {
      langcode: locale,
    },
    {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`menus`],
      },
    },
  );
});

export const getUserByEmail = cache(
  async (variables: Variables<typeof SDK.getUserByEmail>) => {
    return await SDK.getUserByEmail(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`user_by_email:${variables.email}`],
      },
    });
  },
);

export const getCandidate = cache(
  async (variables: Variables<typeof SDK.getCandidate>) => {
    return await SDK.getCandidate(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`candidate:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateDocuments = cache(
  async (variables: Variables<typeof SDK.getCandidateDocuments>) => {
    return await SDK.getCandidateDocuments(variables, {
      next: {
        tags: [`user_documents:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateDocument = cache(
  async (variables: Variables<typeof SDK.getCandidateDocument>) => {
    return await SDK.getCandidateDocument(variables, {
      next: {
        tags: [`user_document:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateSearchPreferences = cache(
  async (variables: Variables<typeof SDK.getCandidateSearchPreferences>) => {
    return await SDK.getCandidateSearchPreferences(variables, {
      next: {
        tags: [`user_search_preferences:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateAvailability = cache(
  async (variables: Variables<typeof SDK.getCandidateAvailability>) => {
    return await SDK.getCandidateAvailability(variables, {
      next: {
        tags: [`user_availability:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateApplications = cache(
  async (variables: Variables<typeof SDK.getCandidateApplications>) => {
    return await SDK.getCandidateApplications(variables, {
      next: {
        tags: [`user_applications:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateAppointments = cache(
  async (variables: Variables<typeof SDK.getCandidateAppointments>) => {
    return await SDK.getCandidateAppointments(variables, {
      next: {
        revalidate: 300,
        tags: [`user_appointments:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateIntention = cache(
  async (variables: Variables<typeof SDK.getCandidateIntention>) => {
    return await SDK.getCandidateIntention(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`candidate_intention:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateSavedJobs = cache(
  async (variables: Variables<typeof SDK.getCandidateSavedJobs>) => {
    return await SDK.getCandidateSavedJobs(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`user_saved_jobs:${variables.userKey}`],
      },
    });
  },
);

export const getCandidateProfileScoreData = cache(
  async (variables: Variables<typeof SDK.getCandidateProfileScoreData>) => {
    return await SDK.getCandidateProfileScoreData(variables, {
      next: {
        revalidate: 300,
        tags: [
          `candidate:${variables.userKey}`,
          `user_documents:${variables.userKey}`,
          `user_search_preferences:${variables.userKey}`,
        ],
      },
    });
  },
);

export const getBannerImage = cache(
  async (variables: Variables<typeof SDK.getBannerImage>) => {
    return await SDK.getBannerImage(variables, {
      next: {
        revalidate: DEFAULT_CACHE_REVALIDATE,
        tags: [`banner_image:${variables.name}`],
      },
    });
  },
);

export const getJobOptions = cache(
  async (variables: Variables<typeof SDK.getJobOptions>) => {
    return await SDK.getJobOptions(variables, {
      next: {
        revalidate: 60 * 60 * 24 * 30, // cache for 30 days
        tags: [`job_options`],
      },
    });
  },
);
